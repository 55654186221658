import React from "react";
import GoogleReviewWidget from "./GoogleReviewWidget";
import SalesReportWidget from "./SalesReportWidget";
import CustomerFeelingWidget from "./CustomerFeelingWidget";
import SocialReviewList from "./SocialReviewList";
import images from "../../../../../helper/images";

const WidgetContainer = () => {
  const render = () => {
    return (
      <section className="py-3 py-md-5 overflohidden">
        <div className="container">
          <div className="row">
            <GoogleReviewWidget />
            <SalesReportWidget />
            <CustomerFeelingWidget />
          </div>
          <div className="row mt-3 mb-5">
            <div className="col-md-7 col-lg-4 mt-3 fadeUp">
              <h3 className="mb-4">Recent Advertisements</h3>
              <div className="Advertisementslide">
                <div>
                  <img
                    src={images.socialMediaResult.advertise}
                    className="w-100"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-8 mt-4 pt-5 pt-md-0 fadeLeft">
              <div>
                <SocialReviewList />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return render();
};

export default WidgetContainer;
