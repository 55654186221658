import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import { getSocialMediaSentiment } from "../../../../../service/ai-model.service";
import CustomLoader from "../../../../common/CustomLoader";
import { useAISocialMediaContext } from "../../../../../context/ai-social-media.context";

const SocialKeywords = ({ isNegative }) => {
  const { selectedPredictionModel } = useAIModelContext();
  const { reviewKeywords, getReviewKeywords } = useAISocialMediaContext();
  const params = useParams();

  useEffect(() => {
    if (params.id && !reviewKeywords) {
      const templateId = params.id;
      const taskId = selectedPredictionModel.taskId;
      getReviewKeywords(templateId, taskId);
    }
  }, [params.id, selectedPredictionModel, reviewKeywords]);

  const renderKeywords = (list) => {
    if (list && list.length)
      return list.map((item, key) => {
        return (
          <li key={`${key}${item}`} className="lighorangebg">
            {item}
          </li>
        );
      });
    return (
      <div className="d-flex justify-content-center">
        {(!list || list.length === 0) && <CustomLoader masked />}
      </div>
    );
  };
  const render = () => {
    const list = isNegative
      ? reviewKeywords?.negative
      : reviewKeywords?.positive;
    return (
      <>
        <h3 className="mb-3">Keywords</h3>
        <div className="bg-white box-shadow rounded p-3">
          <ul className="Keywordslist">{renderKeywords(list)}</ul>
        </div>
      </>
    );
  };
  return render();
};

export default SocialKeywords;
