import React, { useEffect, useState } from "react";
import CustomLoader from "../../../../common/CustomLoader";
import { useAISocialMediaContext } from "../../../../../context/ai-social-media.context";
import AdImageItem from "./components/AdImageItem";

const SocialAdImageContentWidget = () => {
  const { adContent } = useAISocialMediaContext();

  const renderAdContentList = () => {
    if (adContent && adContent.length) {
      return adContent.map((item, index) => (
        <AdImageItem key={index} item={item} rowIndex={index} />
      ));
    }
  };

  const renderAdImageContent = () => {
    if (adContent && adContent.length) {
      return (
        <div className="bg-white box-shadow rounded p-5">
          {renderAdContentList()}
        </div>
      );
    }
    return (
      <div className="d-flex justify-content-center">
        <CustomLoader masked />
      </div>
    );
  };

  const render = () => {
    return (
      <>
        <h3 className="mb-3">Images for Ad</h3>
        {renderAdImageContent()}
      </>
    );
  };
  return render();
};

export default SocialAdImageContentWidget;
