import React, { useState } from "react";
import { useAIModelContext } from "../../../../context/ai-model.context";
import ImageUploadForm from "../../../common/ImageUploadForm";
import { FileType, ModelType } from "../../../../helper/constant";
import DocumentAIModalContent from "./documentAI/DocumentAIModalContent";
import ICModalContent from "./ImageClassification/ICModalContent";

const MultiUploadLayout = (props) => {
  const { onCompleted } = props;
  const { aiDataSelectedForm } = useAIModelContext();
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [fileType, setFileType] = useState(FileType.IMAGES);

  const onSubmit = (file) => {
    let files = [...file].reverse();
    if (selectedFiles && selectedFiles.length)
      files = [...selectedFiles, ...files].reverse();
    setSelectedFiles(files);
  };

  const onImageRemove = (imgIndex) => {
    const list = selectedFiles.slice();
    list.splice(imgIndex, 1);
    setSelectedFiles(list);
  };

  const onImageProcessed = (image) => {
    console.log(image);
    const list = [...selectedFiles];
    list[image.imageIndex] = image;
    setSelectedFiles(list);
  };

  const renderContent = () => {
    if (selectedFiles.length) {
      const { modelId } = aiDataSelectedForm;
      switch (modelId) {
        case ModelType.DocumentAI:
        case ModelType.MultiLabelImageClassification:
        case ModelType.ObjectDetection:
          return (
            <DocumentAIModalContent
              fileType={fileType}
              selectedFiles={selectedFiles}
              onImageRemove={onImageRemove}
              onImageProcessed={onImageProcessed}
              onCompleted={onCompleted}
            />
          );
        case ModelType.ImageClassification:
          return (
            <ICModalContent
              selectedFiles={selectedFiles}
              onImageRemove={onImageRemove}
              onCompleted={onCompleted}
            />
          );
        default:
          return <></>;
      }
    }
  };

  const renderFileType = () => {
    const { modelId } = aiDataSelectedForm;
    if (modelId === ModelType.DocumentAI)
      return (
        <div className="col-xs-12 col-md-3">
          <div className="form-check-inline d-flex align-items-center justify-content-around">
            <label className="text-blue form-check-label">
              File type <span className="text-danger">*</span>
            </label>
            <label className="form-check-label">
              <input
                type="radio"
                className="form-check-input"
                name="revenueradio"
                value={FileType.IMAGES}
                checked={fileType === FileType.IMAGES}
                onChange={(e) => setFileType(e.target.value)}
              />
              Images
            </label>
            <label className="form-check-label">
              <input
                type="radio"
                className="form-check-input"
                name="revenueradio"
                value={FileType.PDF}
                checked={fileType === FileType.PDF}
                onChange={(e) => setFileType(e.target.value)}
              />
              PDF
            </label>
          </div>
        </div>
      );
  };

  const render = () => {
    if (aiDataSelectedForm) {
      const { minFileCount } = aiDataSelectedForm;
      return (
        <div>
          <div className="d-flex justify-content-center align-items-center">
            {renderFileType()}
            <div className="col-xs-12 col-md-4">
              <ImageUploadForm
                onSubmit={onSubmit}
                multiple
                minFileCount={minFileCount}
                fileType={fileType}
              />
            </div>
          </div>
          {selectedFiles && renderContent()}
        </div>
      );
    }
  };

  return render();
};

export default MultiUploadLayout;
