import React, { Fragment } from "react";

import { BasePageContainer } from "../../components/common";
import CreateOrgForm from "../../components/auth/CreateOrgForm";

const CreateOrgPage = () => {
  return (
    <div className="row mx-0 loginwrap">
      <BasePageContainer>
        <CreateOrgForm />
      </BasePageContainer>
    </div>
  );
};

export default CreateOrgPage;
