import React, { createContext, useContext } from "react";
import useAppReducer from "../reducers/app.reducer";
import * as ACTION from "./../reducers/types";
import { useToasts, AppearanceTypes } from "react-toast-notifications";
import { ToastType } from "../helper/constant";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const { state, dispatch } = useAppReducer();
  const { addToast } = useToasts();

  const updateLeftMenu = (flag) =>
    dispatch({ type: ACTION.UPDATE_LEFT_MENU, payload: flag });

  const updateRightMenu = (flag) =>
    dispatch({ type: ACTION.UPDATE_RIGHT_MENU, payload: flag });

  const updateLoader = (flag) =>
    dispatch({ type: ACTION.UPDATE_LOADER, payload: flag });

  const showAlert = (
    message,
    appearance = ToastType.INFO,
    autoDismiss = true,
  ) => {
    if (message) addToast(message, { appearance, autoDismiss });
  };
  return (
    <AppContext.Provider
      value={{
        ...state,
        updateLeftMenu,
        updateRightMenu,
        updateLoader,
        showAlert,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

export { AppProvider, AppContext };
