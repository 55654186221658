import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAIModelContext } from "../../context/ai-model.context";
import constant from "../../helper/constant";
import Pipe from "./Pipe";
import AIAutoGuide from "./ai-workflow/AIAutoGuide";

const ModelCreator = (props) => {
  const { models } = useAIModelContext();
  const renderModelList = () => {
    if (models)
      return models.map((model, index) => {
        return <Pipe key={model.id} index={index} model={model} />;
      });
  };

  const render = () => {
    return (
      <>
        <section className="ai-model-creator pl-2" id="ai-model-img">
          <AIAutoGuide />
          <div className="py-md-4 px-md-4">
            <div
              className={`funelwrap d-flex h-100 ${
                models.length > 2
                  ? "justify-content-start"
                  : "justify-content-center"
              }`}
            >
              {renderModelList()}
            </div>
          </div>
        </section>
      </>
    );
  };
  return render();
};

export default ModelCreator;
