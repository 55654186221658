import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { useAuthContext } from "../../../context/auth.context";
import {
  createBusinessOverview,
  createDynamicWorkflow,
  getBusinessOverview,
  getOtherCompanyOverview,
} from "../../../service/ai-model.service";
import { useAppContext } from "../../../context/app.context";
import { useAIModelContext } from "../../../context/ai-model.context";
import CustomLoader from "../../common/CustomLoader";
import { getSolutionList } from "../../../service/auth.service";
import images from "./../../../helper/images.js";
import UnsignedReportChart from "./../../auth/UnsignedReportChart";
import {
  formatDate,
  formatToCurrency,
  generateOverviewWord,
} from "../../../helper/helper";
import constant, { LanguageList, ToastType } from "../../../helper/constant";
import OverviewPrint from "./OverviewPrint.js";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useScreenSizeContext } from "../../../context/mobile-layout.context.js";
import PredPieChart from "../../common/charts/PredPieChart.js";
import AIRecommendations from "./AIRecommendation.js";

const BusinessOverview = () => {
  const navigate = useNavigate();
  const { userInfo, calculateUnsignedRevenue, revenueReport } =
    useAuthContext();
  const { updateLoader, showAlert, isLoading } = useAppContext();
  const {
    disableAutoCreate,
    updateAutoCreate,
    updateIsOverviewFirstTime,
    isOverviewFirstTime,
  } = useAIModelContext();
  const [selectedCompany, setSelectedCompany] = useState({ name: "", id: "" });
  const [category, setCategory] = useState();
  const [OtherViewList, setOtherViewList] = useState([]);
  const [lang, setLang] = useState("en");
  const [bo, setBo] = useState({
    businessOverview: null,
    internalAnalysis: null,
    externalLandscape: null,
    aiRecommendation: null,
  });
  const [tourGuide, setTourGuide] = useState({
    showAlert: isOverviewFirstTime,
    showGuide: false,
    step1: false,
    step2: false,
    step3: false,
  });
  const reportRef = useRef(null);
  const [solutionList, setSolutionList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const { isMobileScreen } = useScreenSizeContext();

  useEffect(() => {
    if (userInfo.org.industry) {
      getSolutionList(userInfo.org.industry)
        .then((list) => setSolutionList(list))
        .finally(() => {});
    }
  }, [userInfo.org.industry]);

  useEffect(() => {
    const { org } = userInfo;
    if (org.org_config) {
      const { industry, revenue, empCount, inventory } = org.org_config;
      const payload = {
        industryId: industry,
        revenue,
        count: empCount,
        inventory: inventory,
        capital_expenditure: 1000,
      };
      calculateUnsignedRevenue(payload);
    }
  }, []);

  useEffect(() => {
    if (userInfo.org.org_config) {
      updateLoader(true);
      let payload = {
        url: userInfo.org.org_config.companyUrl,
        revenue: userInfo.org.org_config.revenue,
        employees: userInfo.org.org_config.empCount,
      };
      setSelectedCompany({ name: "", id: "" });
      setCategory("");
      setCompanyList([]);
      getBusinessOverview({ type: `info-${lang}` })
        .then((result) => handleBoResponse(result, false, payload))
        .catch(() => generateBusinessOverview(payload));
    }
  }, [userInfo.org.org_config, lang]);

  useEffect(() => {
    getBusinessOverview({ type: `competitorsList-${lang}` })
      .then((result) => {
        setOtherViewList(result);
      })
      .catch((ex) => setOtherViewList(null));
  }, [lang]);

  useEffect(() => {
    if (selectedCompany) {
      const otherInfo = OtherViewList.filter(
        (item) => item.id === selectedCompany.name
      );
      if (otherInfo.length) handleBoResponse(otherInfo);
    }
  }, [selectedCompany]);

  const generateBusinessOverview = (payload) => {
    createBusinessOverview(payload)
      .then((result) => handleBoResponse(result, true, payload))
      .catch((ex) => showAlert(ex?.message, ToastType.ERROR))
      .finally(() => updateLoader(false));
  };

  const generatePDF = () => {
    updateLoader(true);
    const { org } = userInfo;
    generateOverviewWord(org.org_config?.companyName).finally(() =>
      updateLoader(false)
    );
  };
  const renderPrintOption = () => {
    return (
      <>
        {isLoading && <CustomLoader full />}
        <div className="d-flex gap-2 align-items-center w-10">
          <select
            onChange={(e) => setLang(e.target.value)}
            className="form-control px-2"
            value={lang}
          >
            {LanguageList.map((item) => (
              <option key={item.id} value={item.id}>
                {item.value}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };

  const renderCompetitorList = () => {
    const { org: { org_config } = {} } = userInfo;
    if (org_config && OtherViewList.length) {
      let optionList = [];
      if (org_config.competitorList && org_config.competitorList.length) {
        optionList.push({ id: "competitorList", value: "competitor" });
      }
      if (org_config.supplierList && org_config.supplierList.length)
        optionList.push({ id: "supplierList", value: "supplier" });
      if (org_config.customerList && org_config.customerList.length)
        optionList.push({ id: "customerList", value: "customer" });
      return (
        <div className="d-flex w-25 gap-2">
          <select
            className="form-control radious-5"
            onChange={(e) => {
              setCategory(e.target.value);
              setCompanyList(org_config[e.target.value]);
            }}
            value={category}
          >
            <option value="">Select Category</option>
            {optionList.map((item) => (
              <option key={item.id} value={item.id}>
                {item.value}
              </option>
            ))}
          </select>
          <select
            className="form-control radious-5"
            disabled={!companyList}
            onChange={(e) => {
              const selected = companyList.filter(
                (item) => item.name === e.target.value
              );
              if (selected.length) setSelectedCompany(selected[0]);
            }}
          >
            <option value="">Select Company</option>
            {companyList &&
              companyList.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
      );
    }
  };

  const handleBoResponse = (result, isFirstTime, payload) => {
    try {
      if (result && result.length === 0) {
        generateBusinessOverview(payload);
        showAlert(
          "Your overview is getting generated. Once its available we will let you know.",
          ToastType.WARNING,
          false
        );
        navigate(`/`);
      } else {
        const businessOverview = result[0].data.business_overview;
        const internalAnalysis = result[0].data.internal_analysis;
        const externalLandscape = result[0].data.external_landscape;
        const aiRecommendation = result[0].data.recommendations;
        setBo({
          businessOverview,
          internalAnalysis,
          externalLandscape,
          aiRecommendation,
        });
        updateIsOverviewFirstTime(isFirstTime);
        updateLoader(false);
      }
    } catch (ex) {
      console.log(ex);
      updateLoader(false);
      showAlert("Something wrong. Please try again later", ToastType.ERROR);
    }
  };

  const renderBOHeader = () => {
    return (
      <div className="popuptabs mb-3 bo">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              id="BusinessOverview-tab"
              data-toggle="tab"
              href="#BusinessOverview"
              role="tab"
              aria-controls="BusinessOverview"
              aria-selected="true"
            >
              Business Overview
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="InternalAnalysis-tab"
              data-toggle="tab"
              href="#InternalAnalysis"
              role="tab"
              aria-controls="InternalAnalysis"
              aria-selected="false"
            >
              Internal Analysis
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="ExternalLandscape-tab"
              data-toggle="tab"
              href="#ExternalLandscape"
              role="tab"
              aria-controls="ExternalLandscape"
              aria-selected="false"
            >
              External Landscape
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="AIRecommendations-tab"
              data-toggle="tab"
              href="#AIRecommendations"
              role="tab"
              aria-controls="AI Recommendations"
              aria-selected="false"
            >
              AI Recommendations
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="AIOpportunity-tab"
              data-toggle="tab"
              href="#AIOpportunity"
              role="tab"
              aria-controls="AI Opportunity"
              aria-selected="false"
            >
              AI Opportunity
            </a>
          </li>
        </ul>
      </div>
    );
  };

  const onCreateWorkflow = () => {
    updateLoader(true);
    const payload = {
      template_name: `${userInfo.org.org_name}-Auto`,
      industryId: userInfo.org.org_config.industry,
    };
    createDynamicWorkflow(payload)
      .then((result) => {
        if (result) {
          updateAutoCreate(true);
          navigate(
            `/${constant.PAGES.HOME.AI_WORKFLOW}/${result.id}?mode=auto`
          );
        }
      })
      .catch((ex) => showAlert(ex?.message, ToastType.ERROR))
      .finally(() => updateLoader(false));
  };

  const renderAiSolutions = () => {
    if (solutionList && solutionList.length) {
      return solutionList.map((item) => {
        const { id, feature, icon_url } = item;
        return (
          <li
            key={`feature-${id}`}
            className="capitalize"
            style={{ "--img": `url(${icon_url})` }}
          >
            {feature}
          </li>
        );
      });
    }
  };

  const renderAiTimeline = () => {
    return (
      <section className="resultweeks">
        <div className="container position-relative">
          <h3 className="text-center mb-5 pb-5">Get results in weeks</h3>
          <div className="position-relative mt-5">
            <img src={images.unsigned.imgTimeline} alt="" className="w-100" />
            <div className="timelinebox timelinebox1">
              <div className="bold h4 text-blue">AI Training</div>
              <p>
                Empower your team to succeed with AI. Our training programs
                provide the knowledge and skills your team needs to demystify AI
                and start using it to drive growth.
              </p>
              <div className="bold text-secondary h4">2 Hours</div>
            </div>
            <div className="timelinebox timelinebox2">
              <div className="bold text-secondary h4">4 Hours</div>
              <p>
                Transform your business with AI. Our AI platform helps you to
                develop a custom AI strategy that aligns with your business
                goals and drives results.
              </p>
              <div className="bold h4 text-blue">AI Strategy</div>
            </div>
            <div className="timelinebox timelinebox3">
              <div className="bold h4 text-blue">AI Transformation</div>
              <p>
                Maximize the impact of AI on your business. Our team of AI
                experts works with you to implement your strategy and transform
                your operations for optimal results.
              </p>
              <div className="bold text-secondary h4">1-3 Days</div>
            </div>
            <div className="timelinebox timelinebox4">
              <div className="bold text-secondary h4">As Requested</div>
              <p>
                Integrate AI seamlessly into your business operations. AI
                Integration ensures that the AI models are connected across all
                functions, delivering powerful insights and results.
              </p>
              <div className="bold h4 text-blue">AI Integration</div>
            </div>
          </div>
          <small className="usr-timeline-note-text">
            Note: Timeline for sample industry with sample data. Actual timeline
            may vary depending on the data availability.
          </small>
        </div>
      </section>
    );
  };

  const renderOpportunities = () => {
    if (revenueReport)
      return (
        <>
          <div id="companyDetailedReport" ref={reportRef}>
            <header className="header2 bg-blue border-bottom px-3 py-4 ">
              <div className="container">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-white">
                    <h3 className=" text-white ">
                      Additional Profit Report for{" "}
                      <b className="capitalize">{userInfo.org.org_name}</b>.{" "}
                    </h3>
                    <div>{formatDate(null, "DD.MM.YYYY")}</div>
                  </div>
                  <img src={images.unsigned.imgLogo2} alt="" />
                </div>
              </div>
            </header>

            <section className="position-relative">
              <div>
                <img
                  src={images.unsigned.imgBanner2}
                  alt=""
                  className="w-100"
                />
              </div>
              <div className="bannertext text-white bg-blue text-center px-5 py-4">
                <div className="h5">Additional Profit</div>
                <div className="h1 mb-0 text-white bold">
                  {formatToCurrency(revenueReport.afterTotalExpense)}
                </div>
              </div>
            </section>

            <section className="py-5 mb-5">
              <div className="container">
                <div className="resultbox mx-auto d-flex align-items-center text-center">
                  <div className="py-4">
                    <div>3-Year ROI</div>
                    <h4 className="h2 mt-2 mb-0">{revenueReport.roi}%</h4>
                  </div>
                  <div className="py-4">
                    <div>3-Month Delay Cost</div>
                    <h4 className="h2 mt-2 mb-0">
                      {formatToCurrency(revenueReport.delay_cost)}
                    </h4>
                  </div>
                  <div className="py-4">
                    <div>Payback Period</div>
                    <h4 className="h2 mt-2 mb-0">
                      {revenueReport.payback_period} Mos
                    </h4>
                  </div>
                </div>
                <h3 className="pt-5 pb-4 text-center mt-4 lineheight20">
                  $62.1K investment over 3 years yields over $11.9M in benefit
                  over that same period.
                </h3>
                <UnsignedReportChart revenueReport={revenueReport} isResult />
              </div>
            </section>
            {renderAiTimeline()}
            {solutionList && solutionList.length > 0 && (
              <section className="py-5 text-center">
                <div className="container">
                  <h3 className="text-center mb-4">
                    AI Solutions for{" "}
                    <span className="text-blue">Agriculture</span>
                  </h3>
                  <div className="row">
                    <div className="col-md-8 mx-auto">
                      <ul className="resullist">{renderAiSolutions()}</ul>
                    </div>
                  </div>
                  <div className="pt-5 mt-4">
                    <h2 className="bold mb-4 text-blue">Avtar AI</h2>
                    <p className="h4 lineheight10">
                      {" "}
                      is our AI driven application to address Industry-specific
                      problems and make proper predictions or recommendations to
                      solve them
                    </p>
                  </div>
                </div>
              </section>
            )}

            <section className="py-5 mb-5 border bg-light">
              <div className="container d-flex flex-column">
                <h3 className="text-center text-blue mb-4">
                  Preview of <span className="bold">Avtar AI</span>
                </h3>
                <div>
                  <img
                    src={images.unsigned.imgDashboard}
                    alt=""
                    className="w-100"
                  />
                </div>
                {renderAutoCreateButton()}
              </div>
            </section>
          </div>
        </>
      );
  };

  const renderAutoCreateButton = () => {
    if (!disableAutoCreate)
      return (
        <Link
          onClick={() => onCreateWorkflow()}
          className="btn1 m-3 arrowhover d-flex align-items-center align-self-center justify-content-between font-weight-bold w-40"
        >
          Create New Workflow
          <span>
            <svg
              width="25"
              height="24"
              viewBox="0 0 45 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M44.0607 13.0607C44.6464 12.4749 44.6464 11.5251 44.0607 10.9393L34.5147 1.3934C33.9289 0.807612 32.9792 0.807612 32.3934 1.3934C31.8076 1.97918 31.8076 2.92893 32.3934 3.51472L40.8787 12L32.3934 20.4853C31.8076 21.0711 31.8076 22.0208 32.3934 22.6066C32.9792 23.1924 33.9289 23.1924 34.5147 22.6066L44.0607 13.0607ZM0 13.5H43V10.5H0V13.5Z"
                fill="white"
              />
            </svg>
          </span>
        </Link>
      );
  };

  const renderBO = () => {
    if (bo.businessOverview)
      return (
        <div className="py-3 d-flex flex-column gap-2 text-justify">
          <h6>Web Analysis</h6>
          <p className="text-b">{bo.businessOverview.website_analysis}</p>

          <h6>Business Analysis</h6>
          <p className="text-b">{bo.businessOverview.business_analysis}</p>
        </div>
      );
  };

  const renderInternalAnalysys = () => {
    if (bo.internalAnalysis)
      return (
        <div className="d-flex flex-column gap-2 text-justify">
          <h6 className="py-3">
            SWOT Analysis of{" "}
            <span className="font-weight-bold">
              {selectedCompany.name || userInfo.org.org_config.companyName}
            </span>
          </h6>
          <div className="d-flex flex-wrap text-left">
            <div
              className={`swot-container ${isMobileScreen ? "flex-basis-100" : "flex-basis-50"}`}
            >
              <div className="swot-header">Strengths</div>
              <div className="swot-content">{bo.internalAnalysis.strength}</div>
            </div>
            <div
              className={`swot-container ${isMobileScreen ? "flex-basis-100" : "flex-basis-50"}`}
            >
              <div className="swot-header">Weaknesses</div>
              <div className="swot-content">{bo.internalAnalysis.weakness}</div>
            </div>
            <div
              className={`swot-container ${isMobileScreen ? "flex-basis-100" : "flex-basis-50"}`}
            >
              <div className="swot-header">Opportunities</div>
              <div className="swot-content">
                {bo.internalAnalysis.opportunities}
              </div>
            </div>
            <div
              className={`swot-container ${isMobileScreen ? "flex-basis-100" : "flex-basis-50"}`}
            >
              <div className="swot-header">Threats</div>
              <div className="swot-content">{bo.internalAnalysis.threats}</div>
            </div>
          </div>
        </div>
      );
  };

  const renderExternlaLandscape = () => {
    if (bo.externalLandscape)
      return (
        <div className="d-flex flex-column gap-2 text-justify">
          <h6 className="py-3">
            PESTLE External environment Assessment for{" "}
            <span className="font-weight-bold">
              {selectedCompany.name || userInfo.org.org_config.companyName}
            </span>
          </h6>
          <div className="d-flex flex-wrap text-left">
            <div
              className={`swot-container ${isMobileScreen ? "flex-basis-100" : "flex-basis-16"}`}
            >
              <div className="swot-header">Political</div>
              <div className="swot-content">
                {bo.externalLandscape.political}
              </div>
            </div>
            <div
              className={`swot-container ${isMobileScreen ? "flex-basis-100" : "flex-basis-16"}`}
            >
              <div className="swot-header">Economic</div>
              <div className="swot-content">
                {bo.externalLandscape.economic}
              </div>
            </div>
            <div
              className={`swot-container ${isMobileScreen ? "flex-basis-100" : "flex-basis-16"}`}
            >
              <div className="swot-header">Social</div>
              <div className="swot-content">{bo.externalLandscape.social}</div>
            </div>
            <div
              className={`swot-container ${isMobileScreen ? "flex-basis-100" : "flex-basis-16"}`}
            >
              <div className="swot-header">Technological</div>
              <div className="swot-content">
                {bo.externalLandscape.technological}
              </div>
            </div>
            <div
              className={`swot-container ${isMobileScreen ? "flex-basis-100" : "flex-basis-16"}`}
            >
              <div className="swot-header">Legal</div>
              <div className="swot-content">{bo.externalLandscape.legal}</div>
            </div>
            <div
              className={`swot-container ${isMobileScreen ? "flex-basis-100" : "flex-basis-16"}`}
            >
              <div className="swot-header">Environment</div>
              <div className="swot-content">
                {bo.externalLandscape.environment}
              </div>
            </div>
          </div>
        </div>
      );
  };

  const renderAIRecommendation = () => {
    return <AIRecommendations recommendations={bo.aiRecommendation}/>
  };

  const renderBOContent = () => {
    if (bo.businessOverview)
      return (
        <div className="tab-content mb-5" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="BusinessOverview"
            role="tabpanel"
            aria-labelledby="BusinessOverview-tab"
          >
            {renderBO()}
          </div>
          <div
            className="tab-pane fade"
            id="InternalAnalysis"
            role="tabpanel"
            aria-labelledby="InternalAnalysis-tab"
          >
            {renderInternalAnalysys()}
          </div>
          <div
            className="tab-pane fade"
            id="ExternalLandscape"
            role="tabpanel"
            aria-labelledby="ExternalLandscape-tab"
          >
            {renderExternlaLandscape()}
          </div>
          <div
            className="tab-pane fade"
            id="AIRecommendations"
            role="tabpanel"
            aria-labelledby="AIRecommendations-tab"
          >
            {renderAIRecommendation()}
          </div>
          <div
            className="tab-pane fade"
            id="AIOpportunity"
            role="tabpanel"
            aria-labelledby="AIOpportunity-tab"
          >
            <div className="d-flex flex-column gap-2 justify-content-center">
              {renderAutoCreateButton()}
              {renderOpportunities()}
            </div>
          </div>
        </div>
      );
    return <p>Loading...</p>;
  };

  const renderTourGuide = () => {
    return (
      <div className="quickguidesec d-flex align-items-center justify-conent-center">
        <div className="quickguidebox p-5">
          <h3>Good Afternoon,</h3>
          <div className="py-3">Your Profile is created successfully</div>
          <h2 className="h3 text-blue">Let’s have Quick Guide Tour</h2>

          <div className="d-flex align-items-center mt-4 ">
            <div
              className="btn1 TakeGuide text-uppercase font-weight-bold py-2 px-5 cursor-pointer"
              onClick={() =>
                setTourGuide({
                  ...tourGuide,
                  showAlert: false,
                  showGuide: true,
                  step1: true,
                })
              }
            >
              Take Guide
            </div>
            <div
              className="btn1 nothanks ml-3 text-uppercase font-weight-bold py-2 px-5 cursor-pointer"
              onClick={() => closeTourGuide()}
            >
              no, thanks{" "}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const closeTourGuide = () => {
    setTourGuide({
      showAlert: false,
      showGuide: false,
      step1: false,
      step2: false,
      step3: false,
    });
  };

  const renderTourSteps = () => {
    if (tourGuide.showGuide)
      return (
        <div className="quickguidesec2">
          {tourGuide.step1 && (
            <div className="quickguidesmbox quickboxposition1 p-3 bg-white">
              <div className="d-flex align-items-center mb-2 justify-content-between">
                <span className="qckgdnum">1</span>
                <div
                  className="text-right disableguide cursor-pointer"
                  onClick={() => closeTourGuide()}
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.61074 2.8752L9.50008 7.76454L14.3641 2.90054C14.4715 2.78618 14.6009 2.6947 14.7446 2.63158C14.8883 2.56845 15.0432 2.53499 15.2001 2.5332C15.536 2.5332 15.8582 2.66666 16.0957 2.9042C16.3333 3.14175 16.4667 3.46393 16.4667 3.79987C16.4697 3.95517 16.4409 4.10943 16.3821 4.25319C16.3233 4.39695 16.2357 4.52717 16.1247 4.63587L11.1974 9.49987L16.1247 14.4272C16.3335 14.6314 16.4559 14.908 16.4667 15.1999C16.4667 15.5358 16.3333 15.858 16.0957 16.0955C15.8582 16.3331 15.536 16.4665 15.2001 16.4665C15.0386 16.4732 14.8776 16.4463 14.7271 16.3874C14.5767 16.3285 14.4401 16.239 14.3261 16.1245L9.50008 11.2352L4.62341 16.1119C4.51638 16.2224 4.38852 16.3107 4.24721 16.3715C4.1059 16.4324 3.95393 16.4647 3.80008 16.4665C3.46413 16.4665 3.14195 16.3331 2.90441 16.0955C2.66686 15.858 2.53341 15.5358 2.53341 15.1999C2.53046 15.0446 2.55927 14.8903 2.61808 14.7466C2.67689 14.6028 2.76446 14.4726 2.87541 14.3639L7.80274 9.49987L2.87541 4.57254C2.66664 4.3683 2.54423 4.09173 2.53341 3.79987C2.53341 3.46393 2.66686 3.14175 2.90441 2.9042C3.14195 2.66666 3.46413 2.5332 3.80008 2.5332C4.10408 2.537 4.39541 2.65987 4.61074 2.8752Z"
                      fill="#B4B0B0"
                    />
                  </svg>
                </div>
              </div>
              <div>
                A detailed analysis of your company business, Current &
                Predictive valuation of company along with AI Recommendations
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <div
                  className="nextqgstep1 cursor-pointer"
                  onClick={() =>
                    setTourGuide({
                      ...tourGuide,
                      step1: false,
                      step2: true,
                      step3: false,
                    })
                  }
                >
                  <svg
                    width="10"
                    height="20"
                    viewBox="0 0 14 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.51378 1.39246L13.0597 10.9384C13.6455 11.5242 13.6455 12.4739 13.0597 13.0597L3.51379 22.6057C2.928 23.1915 1.97825 23.1915 1.39247 22.6057C0.80668 22.0199 0.80668 21.0701 1.39247 20.4843L8.37775 13.4991L6.99907 13.4991L6.99907 10.4991L8.37775 10.4991L1.39246 3.51379C0.806679 2.928 0.806679 1.97825 1.39246 1.39247C1.97825 0.806678 2.928 0.806678 3.51378 1.39246Z"
                      fill="#007BFF"
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}
          {tourGuide.step2 && (
            <div className="quickguidesmbox quickboxposition2 p-3 bg-white">
              <div className="d-flex align-items-center mb-2 justify-content-between">
                <span className="qckgdnum">2</span>
                <div
                  className="text-right disableguide cursor-pointer"
                  onClick={() => closeTourGuide()}
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.61074 2.8752L9.50008 7.76454L14.3641 2.90054C14.4715 2.78618 14.6009 2.6947 14.7446 2.63158C14.8883 2.56845 15.0432 2.53499 15.2001 2.5332C15.536 2.5332 15.8582 2.66666 16.0957 2.9042C16.3333 3.14175 16.4667 3.46393 16.4667 3.79987C16.4697 3.95517 16.4409 4.10943 16.3821 4.25319C16.3233 4.39695 16.2357 4.52717 16.1247 4.63587L11.1974 9.49987L16.1247 14.4272C16.3335 14.6314 16.4559 14.908 16.4667 15.1999C16.4667 15.5358 16.3333 15.858 16.0957 16.0955C15.8582 16.3331 15.536 16.4665 15.2001 16.4665C15.0386 16.4732 14.8776 16.4463 14.7271 16.3874C14.5767 16.3285 14.4401 16.239 14.3261 16.1245L9.50008 11.2352L4.62341 16.1119C4.51638 16.2224 4.38852 16.3107 4.24721 16.3715C4.1059 16.4324 3.95393 16.4647 3.80008 16.4665C3.46413 16.4665 3.14195 16.3331 2.90441 16.0955C2.66686 15.858 2.53341 15.5358 2.53341 15.1999C2.53046 15.0446 2.55927 14.8903 2.61808 14.7466C2.67689 14.6028 2.76446 14.4726 2.87541 14.3639L7.80274 9.49987L2.87541 4.57254C2.66664 4.3683 2.54423 4.09173 2.53341 3.79987C2.53341 3.46393 2.66686 3.14175 2.90441 2.9042C3.14195 2.66666 3.46413 2.5332 3.80008 2.5332C4.10408 2.537 4.39541 2.65987 4.61074 2.8752Z"
                      fill="#B4B0B0"
                    />
                  </svg>
                </div>
              </div>
              <div>
                {" "}
                AI Recommended modules & their impact in your future business
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <div
                  className="prevqgstep1 cursor-pointer"
                  onClick={() =>
                    setTourGuide({
                      ...tourGuide,
                      step1: true,
                      step2: false,
                      step3: false,
                    })
                  }
                >
                  <svg
                    width="10"
                    height="20"
                    viewBox="0 0 14 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.4862 1.39246L0.940272 10.9384C0.354486 11.5242 0.354486 12.4739 0.940272 13.0597L10.4862 22.6057C11.072 23.1915 12.0217 23.1915 12.6075 22.6057C13.1933 22.0199 13.1933 21.0701 12.6075 20.4843L5.62225 13.4991L7.00093 13.4991L7.00093 10.4991L5.62225 10.4991L12.6075 3.51379C13.1933 2.928 13.1933 1.97825 12.6075 1.39247C12.0217 0.806678 11.072 0.806678 10.4862 1.39246Z"
                      fill="#007BFF"
                    />
                  </svg>
                </div>
                <div
                  className="nextqgstep2 ml-3 cursor-pointer"
                  onClick={() =>
                    setTourGuide({
                      ...tourGuide,
                      step1: false,
                      step2: false,
                      step3: true,
                    })
                  }
                >
                  <svg
                    width="10"
                    height="20"
                    viewBox="0 0 14 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.51378 1.39246L13.0597 10.9384C13.6455 11.5242 13.6455 12.4739 13.0597 13.0597L3.51379 22.6057C2.928 23.1915 1.97825 23.1915 1.39247 22.6057C0.80668 22.0199 0.80668 21.0701 1.39247 20.4843L8.37775 13.4991L6.99907 13.4991L6.99907 10.4991L8.37775 10.4991L1.39246 3.51379C0.806679 2.928 0.806679 1.97825 1.39246 1.39247C1.97825 0.806678 2.928 0.806678 3.51378 1.39246Z"
                      fill="#007BFF"
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}

          {tourGuide.step3 && (
            <div className="quickguidesmbox quickboxposition3 p-3 bg-white">
              <div className="d-flex align-items-center mb-2 justify-content-between">
                <span className="qckgdnum">3</span>
                <div
                  className="text-right disableguide cursor-pointer"
                  onClick={() => closeTourGuide()}
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.61074 2.8752L9.50008 7.76454L14.3641 2.90054C14.4715 2.78618 14.6009 2.6947 14.7446 2.63158C14.8883 2.56845 15.0432 2.53499 15.2001 2.5332C15.536 2.5332 15.8582 2.66666 16.0957 2.9042C16.3333 3.14175 16.4667 3.46393 16.4667 3.79987C16.4697 3.95517 16.4409 4.10943 16.3821 4.25319C16.3233 4.39695 16.2357 4.52717 16.1247 4.63587L11.1974 9.49987L16.1247 14.4272C16.3335 14.6314 16.4559 14.908 16.4667 15.1999C16.4667 15.5358 16.3333 15.858 16.0957 16.0955C15.8582 16.3331 15.536 16.4665 15.2001 16.4665C15.0386 16.4732 14.8776 16.4463 14.7271 16.3874C14.5767 16.3285 14.4401 16.239 14.3261 16.1245L9.50008 11.2352L4.62341 16.1119C4.51638 16.2224 4.38852 16.3107 4.24721 16.3715C4.1059 16.4324 3.95393 16.4647 3.80008 16.4665C3.46413 16.4665 3.14195 16.3331 2.90441 16.0955C2.66686 15.858 2.53341 15.5358 2.53341 15.1999C2.53046 15.0446 2.55927 14.8903 2.61808 14.7466C2.67689 14.6028 2.76446 14.4726 2.87541 14.3639L7.80274 9.49987L2.87541 4.57254C2.66664 4.3683 2.54423 4.09173 2.53341 3.79987C2.53341 3.46393 2.66686 3.14175 2.90441 2.9042C3.14195 2.66666 3.46413 2.5332 3.80008 2.5332C4.10408 2.537 4.39541 2.65987 4.61074 2.8752Z"
                      fill="#B4B0B0"
                    />
                  </svg>
                </div>
              </div>
              <div>
                AI calculated comparative valuation of your company’s current &
                future state
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <div
                  className="prevqgstep2 cursor-pointer"
                  onClick={() =>
                    setTourGuide({
                      ...tourGuide,
                      step1: false,
                      step2: true,
                      step3: false,
                    })
                  }
                >
                  <svg
                    width="10"
                    height="20"
                    viewBox="0 0 14 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.4862 1.39246L0.940272 10.9384C0.354486 11.5242 0.354486 12.4739 0.940272 13.0597L10.4862 22.6057C11.072 23.1915 12.0217 23.1915 12.6075 22.6057C13.1933 22.0199 13.1933 21.0701 12.6075 20.4843L5.62225 13.4991L7.00093 13.4991L7.00093 10.4991L5.62225 10.4991L12.6075 3.51379C13.1933 2.928 13.1933 1.97825 12.6075 1.39247C12.0217 0.806678 11.072 0.806678 10.4862 1.39246Z"
                      fill="#007BFF"
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}
        </div>
      );
  };

  const render = () => {
    return (
      <>
        {isLoading && <CustomLoader full />}
        <div className="px-5 py-3">
          <div className="d-flex justify-content-end">
            {renderPrintOption()}
          </div>
          <div className="d-flex justify-content-between my-3 align-items-center">
            <h2 className="h5 font-weight-bold">
              {selectedCompany.name || userInfo.org.org_config.companyName}
            </h2>
            {renderCompetitorList()}
          </div>
          <div className="d-flex justify-content-end">
            <Link onClick={() => generatePDF()}>
              Download Company's Business Overview
              <FontAwesomeIcon icon={faDownload} className="text-blue mx-1" />
            </Link>
          </div>
          <div className="aicompanysec mt-4">
            {renderBOHeader()}
            {renderBOContent()}
            {tourGuide.showAlert && renderTourGuide()}
            {renderTourSteps()}
          </div>
        </div>
      </>
    );
  };

  return render();
};

export default BusinessOverview;
