import React from "react";
import { useAIModelContext } from "../../context/ai-model.context";

const Timeline = () => {
  const {} = useAIModelContext();

  const render = () => {
    return (
      <>
        <h2 className="h5 mb-3 text-center font-weight-bold">
          3 Simple easy steps to follow
        </h2>
        <ul className="timeline mt-2">
          <li>
            <div>Choose Department</div>
            <div className="nmbr">1</div>
          </li>
          <li>
            <div>Add Tasks</div>
            <div className="nmbr">2</div>
          </li>
          <li>
            <div>SAVE</div>
            <div className="nmbr">3</div>
          </li>
        </ul>
      </>
    );
  };
  return render();
};

export default Timeline;
