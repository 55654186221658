import React, { useState } from "react";
import useInput from "../../../../../hooks/useInput";
import MultipleUploadForm from "../MultipleUploadForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useICContext } from "../../../../../context/ai-ic.context";
import { Link } from "react-router-dom";

const NewLabelForm = (props) => {
  const { featureItem, read, featureIndex } = props;
  const { icList, setICList, setSelectedICIndex } = useICContext();
  const onRemove = (e) => {
    e.preventDefault();
    let newData = [...icList];
    newData.splice(featureIndex, 1);
    setICList(newData);
  };

  const showPreview = () => setSelectedICIndex(featureIndex);

  const renderConditionalForm = () => {
    if (featureItem.imageList?.length)
      return (
        <>
          <Link className="task-remove" onClick={() => showPreview()}>
            <span>{featureItem.imageList.length} Images selected</span>
            <FontAwesomeIcon icon={faEye} className="mx-2 text-white" />
          </Link>
        </>
      );
    return <MultipleUploadForm hideClose buttonIndex={featureIndex} />;
  };

  const handleChange = (value) => {
    const list = [...icList];
    const item = list[featureIndex];
    item.feature = value;
    list.splice(featureIndex, 1, item);
    setICList(list);
  };

  const render = () => {
    return (
      <div className="p-3 separator separator-top ">
        {!read && (
          <div className="text-right">
            <Link className="task-remove" onClick={(e) => onRemove(e)}>
              <FontAwesomeIcon icon={faTrashCan} className="text-danger" />
            </Link>
          </div>
        )}
        <div className="form-group">
          {read && (
            <div className="h6 text-white f-13">
              <b>Feature</b> : {featureItem.feature}
            </div>
          )}
          {!read && (
            <>
              <div className="h6 text-white f-13">
                <b>Feature</b>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Please enter label"
                value={featureItem.feature || ""}
                onChange={(e) => handleChange(e.target.value)}
                name="feature"
              />
            </>
          )}
        </div>
        {renderConditionalForm()}
      </div>
    );
  };
  return render();
};

export default NewLabelForm;
