import axios from "axios";
import ErrorInterceptor from "./interceptors/error.interceptor";
import HttpRequestInterceptor from "./interceptors/http.request.interceptor";

const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/`,
});

HttpRequestInterceptor(http);
ErrorInterceptor(http);

export default http;
