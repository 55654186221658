import React from "react";
import { useAIModelContext } from "../../../context/ai-model.context";
import SocialMediaForm from "./training-forms/SocialMediaForm";
import { ModelType } from "../../../helper/constant";
import DefaultForm from "./training-forms/DefaultForm";
import MultipleUploadForm from "./training-forms/MultipleUploadForm";
import ImageClassificationForm from "./training-forms/ImageClassification/ImageClassificationForm";
import AIFilledFrom from "./training-forms/AIFilledForm";
import KnowledgeAIForm from "./training-forms/knowledge-ai/KnowlegeAIForm";
import TimeSeriesForm from "./training-forms/time-series/TimeSerialForm";

const RecommandedFeature = () => {
  const { aiDataSelectedForm } = useAIModelContext();

  const renderTrainingForm = () => {
    const { modelId } = aiDataSelectedForm;
    switch (modelId) {
      case ModelType.SocialMedia:
        return <SocialMediaForm onSubmit={onFormSubmit} />;
      case ModelType.DocumentAI:
      case ModelType.MultiLabelImageClassification:
      case ModelType.ObjectDetection:
        return <MultipleUploadForm onSubmit={onFormSubmit} />;
      case ModelType.ImageClassification:
        return <ImageClassificationForm onSubmit={onFormSubmit} />;
      case ModelType.KnowledgeAI:
        return <KnowledgeAIForm onSubmit={onFormSubmit} />;
      case ModelType.TimeSerialTabular:
        return <TimeSeriesForm onSubmit={onFormSubmit} />;
      default:
        return <DefaultForm onSubmit={onFormSubmit} />;
    }
  };

  const onFormSubmit = (e) => {
    console.log(e);
  };

  const renderTrainingForms = () => {
    if (aiDataSelectedForm && aiDataSelectedForm.status)
      return <AIFilledFrom />;
    return <div className="">{renderTrainingForm()}</div>;
  };

  const render = () => {
    if (aiDataSelectedForm) {
      return (
        <>
          <div className="">
            <div className="border-bottom mb-3">
              <h5 className="text-dark pb-3">Training Data</h5>
              <div className="h6 text-dark f-13">
                AI Workflow: <b>{aiDataSelectedForm.model_name}</b>
              </div>
            </div>
            {renderTrainingForms()}
          </div>
        </>
      );
    }
  };
  return render();
};
export default RecommandedFeature;
