import React, { useState, useEffect } from "react";
import images from "../../../../../helper/images";
import { Link, useParams } from "react-router-dom";
import SocialKeywords from "../widgets/SocialKewords";
import SocialReviewList from "../widgets/SocialReviewList";
import { triggerEmail } from "../../../../../service/ai-model.service";
import { useAppContext } from "../../../../../context/app.context";
import constant, { ToastType } from "../../../../../helper/constant";
import { validateEmail } from "../../../../../helper/helper";
import { useAISocialMediaContext } from "../../../../../context/ai-social-media.context";

const SocialNegativeReview = () => {
  const { showAlert } = useAppContext();
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState(constant.EMAIL.SUBJECT);
  const [emailBody, setEmailBody] = useState("");
  const { reviewKeywords } = useAISocialMediaContext();
  const [form, setForm] = useState({ error: {} });
  const params = useParams();

  useEffect(() => {
    if (reviewKeywords && reviewKeywords.negative)
      setEmailBody(
        constant.EMAIL.EMAIL_BODY_TEMPLATE.replace(
          "{keywords}",
          reviewKeywords.negative.join(","),
        ),
      );
  }, [reviewKeywords]);

  const handleValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!email) {
      formIsValid = false;
      error["email"] = "Email is required.";
    }
    if (email) {
      const emailList = email.split(",");
      emailList.forEach((e) => {
        if (!validateEmail(e)) {
          formIsValid = false;
          error["email"] = "Email is invalid.";
        }
      });
    }

    if (!subject) {
      formIsValid = false;
      error["subject"] = "Subject is required.";
    }

    if (!emailBody) {
      formIsValid = false;
      error["emailBody"] = "Email Body is required.";
    }

    setForm({ error });
    return formIsValid;
  };

  const sendEmail = () => {
    if (handleValidation()) {
      const sendgridMessage = emailBody.replaceAll("\n\n", "<br><br>");
      const payload = {
        sendgridEmail: email.split(","),
        sendgridMessage,
        subject,
      };
      triggerEmail(params.id, payload)
        .then(() => showAlert("Mail sent successfully", ToastType.SUCCESS))
        .catch((ex) => showAlert(`Error sending email ${ex}`, ToastType.ERROR));
    }
  };

  const render = () => {
    return (
      <div className="container">
        <h3>
          <Link to=".." className="pr-2">
            <img
              className="pr-2"
              src={images.socialMediaResult.arrowLeft}
              alt="Negative Reviews"
            />
            Back
          </Link>
        </h3>
        <section className="py-3 py-lg-3">
          <SocialReviewList isNegative />
        </section>
        <section className="py-3">
          <div className="stepbox stepboxnegative mb-3 p-2">
            Step <span className="step-number">1</span>
          </div>
          <SocialKeywords isNegative />
        </section>
        <section className="py-5">
          <div className="stepbox stepboxnegative mb-3 p-2">
            Step <span className="step-number">2</span>
          </div>
          <h3 className="mb-3">Send Email</h3>
          <div className="bg-white box-shadow rounded p3 p-md-5">
            <form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                sendEmail();
              }}
            >
              <div className="form-group">
                <div className="d-flex align-items-center form-control">
                  <span>To</span>
                  <input
                    type="text"
                    className={`form-control py-0 border-0 `}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {form.error["email"] && (
                  <small className="text-danger">{form.error["email"]}</small>
                )}
              </div>
              <div className="form-group">
                <div className="d-flex align-items-center form-control">
                  <span>Subject:</span>
                  <input
                    type="text"
                    className="form-control py-0 border-0"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
                {form.error["subject"] && (
                  <small className="text-danger">{form.error["subject"]}</small>
                )}
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  rows="10"
                  value={emailBody}
                  onChange={(e) => setEmailBody(e.target.value)}
                />
                {form.error["emailBody"] && (
                  <small className="text-danger">
                    {form.error["emailBody"]}
                  </small>
                )}
              </div>
              <div className="form-group">
                <div className="mt-5 text-center">
                  <button type="submit" className="btn1">
                    SEND
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    );
  };

  return render();
};

export default SocialNegativeReview;
