import React, { useEffect, useState } from "react";
import ImageButton from "../../../../common/ImageButton";
import { useICContext } from "../../../../../context/ai-ic.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";

const ICModalContent = (props) => {
  const { selectedFiles, onImageRemove, onCompleted } = props;
  const { icList, setICList, selectedICIndex } = useICContext();

  const onSubmitChanges = () => {
    const list = icList.slice();
    if (list.length) {
      console.log("Update list");
      list[selectedICIndex].imageList = selectedFiles.slice();
      setICList(list);
      if (onCompleted) onCompleted();
    }
  };

  const renderPreview = () => {
    if (selectedFiles && selectedFiles.length)
      return selectedFiles.map((image, key) => (
        <div key={key} className="col-xs-12 col-md-2 m-0">
          <div className="d-flex py-2">
            <ImageButton
              onRemove={() => onImageRemove(key)}
              image={image}
              alt={image.file.fileName}
            />
          </div>
        </div>
      ));
  };

  const renderContent = () => {
    let selectedLabel = "";
    if (icList.length && selectedICIndex !== null)
      selectedLabel = icList[selectedICIndex].feature;
    return (
      <div className="d-flex flex-column gap-2">
        {selectedLabel && (
          <p className="p-0">
            <span>Selected Label : </span>
            <span className="font-weight-bold">{selectedLabel}</span>
          </p>
        )}
        <p className="align-self-start">Images({selectedFiles.length})</p>
        <div className="ai-ic-modal-content">
          <div className="row mx-0 my-2">{renderPreview()}</div>
        </div>
        <button
          className="btn1 rounded align-self-end"
          type="button"
          disabled={selectedFiles.length === 0}
          onClick={() => onSubmitChanges()}
        >
          {" "}
          <FontAwesomeIcon icon={faCircleCheck} /> Submit changes
        </button>
      </div>
    );
  };

  return renderContent();
};

export default ICModalContent;
