import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAIModelContext } from "../../../context/ai-model.context";
import ModelCreator from "../ModelCreator";
import AIPredictionResult from "./PredictionResults/AIPredictionResult";
import {
  getSocialMediaModel,
  getTrainingModel,
} from "../../../service/ai-model.service";
import constant from "../../../helper/constant";

const AIPrediction = () => {
  const params = useParams();
  const { models, getAIPredictData, showRightMenu, setAllAIModelFormData } =
    useAIModelContext();

  useEffect(() => {
    if (params.id) getAIPredictData(params.id);
  }, [params.id]);

  useEffect(() => {
    if (models.length) {
      const taskList = models.flatMap((model) => {
        return [...model.tasks];
      });
      const allPromises = taskList.map((task) => getAllTaskPromises(task));
      Promise.all(allPromises)
        .then((result) => {
          const list = result.map((item) => item[0] || {});
          setAllAIModelFormData(list);
        })
        .catch(console.log);
    }
  }, [models]);

  const getAllTaskPromises = (task) => {
    if (constant.LLMModels.includes(task.modelId))
      return getSocialMediaModel(task.taskId);
    else return getTrainingModel(task.taskId);
  };

  return (
    <div className="row mx-0">
      <div className={` px-md-0 ${showRightMenu ? "col-md-10" : "col-md-12"}`}>
        <div className="p-5 mp-5">
          <div className="dhbbox py-md-4 px-md-5">
            <ModelCreator />
            <AIPredictionResult />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIPrediction;
