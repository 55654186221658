import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { validateRecapcha } from "../../service/auth.service";

const Recapcha = ({ onVerification }) => {
  const recaptchaRef = React.createRef();
  const [expired, setExpired] = useState(false);

  const onChange = () => {
    const token = recaptchaRef.current.getValue();
    if (token) {
      validateRecapcha({ token })
        .then((r) => {
          onVerification(r.valid);
        })
        .catch((ex) => {
          recaptchaRef.current.reset();
          onVerification(false);
        });
    } else {
      setExpired(true);
      recaptchaRef.current.reset();
    }
  };
  return (
    <div className="recaptcha">
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_SITE_KEY}
        onChange={onChange}
      ></ReCAPTCHA>
      {/* {expired && <small className="text-danger">ReCAPTCHA Exipred.</small>} */}
    </div>
  );
};

export default Recapcha;
