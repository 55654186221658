import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import constant from "../../../../../helper/constant";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import { getSocialMediaSentiment } from "../../../../../service/ai-model.service";
import { useToasts } from "react-toast-notifications";
import { useAISocialMediaContext } from "../../../../../context/ai-social-media.context";
import CustomLoader from "../../../../common/CustomLoader";

const SentimentBox = () => {
  const { selectedPredictionModel } = useAIModelContext();
  const { reviewKeywords, getReviewKeywords } = useAISocialMediaContext();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      const templateId = params.id;
      const { taskId } = selectedPredictionModel;
      getReviewKeywords(templateId, taskId);
    }
  }, [params.id, selectedPredictionModel]);

  const renderPostiveBox = () => {
    if (reviewKeywords.positive.length)
      return (
        <div className="col-md-6 mt-3 fadeUp">
          <div className="reviewbox">
            <h4>Positive Reviews</h4>
            <div className="mt-3 bg-white px-3 py-5 p-lg-5">
              <h4 className="mb-2 text-dark">Keywords</h4>
              <p>{reviewKeywords.positive?.slice().join(",")}</p>
              <Link
                to={`${constant.PAGES.SOCIAL_MEDIA.POSITIVE_REVIEW_1}`}
                className="btn1 mt-4"
              >
                CREATE AD
              </Link>
            </div>
          </div>
        </div>
      );
  };

  const renderNegativeBox = () => {
    if (reviewKeywords.negative.length)
      return (
        <div className="col-md-6 mt-3 fadeUp">
          <div className="reviewbox h-100">
            <h4>Negative Reviews</h4>
            <div className="mt-3 bg-white px-3 py-5 p-lg-5">
              <h4 className="mb-2 text-dark">Keywords</h4>
              <p>{reviewKeywords.negative?.slice().join(",")}</p>
              <Link
                to={`${constant.PAGES.SOCIAL_MEDIA.NEGATIVE_REVIEW}`}
                className="btn1 mt-4"
              >
                EMAIL
              </Link>
            </div>
          </div>
        </div>
      );
  };

  const render = () => {
    if (reviewKeywords)
      return (
        <section
          className={`reviewsec overflohidden ${reviewKeywords.positive.length || reviewKeywords.negative.length ? "py-2 py-lg-3" : ""}`}
        >
          <div className="container">
            <div className="row">
              {renderPostiveBox()}
              {renderNegativeBox()}
            </div>
          </div>
        </section>
      );
    return (
      <div className="d-flex justify-content-center">
        <CustomLoader masked />
      </div>
    );
  };
  return render();
};

export default SentimentBox;
