import React, { useEffect, useState } from "react";
import { getModelDetailForId } from "../../../service/ai-model.service";
import CustomLoader from "../../common/CustomLoader";

const AIModelDescription = (props) => {
  const { modelId } = props;
  const [modelDetail, setModelDetail] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getModelDetailForId(modelId)
      .then((r) => {
        if (r) setModelDetail(r);
      })
      .catch((ex) => console.log(ex))
      .finally(() => setLoading(false));
  }, [modelId]);

  const render = () => {
    if (loading)
      return (
        <div className="d-flex justify-content-center">
          <CustomLoader />
        </div>
      );
    if (modelDetail)
      return (
        <div
          className="ai-model-box mt-0 mb-2"
          dangerouslySetInnerHTML={{ __html: modelDetail.model_html }}
        ></div>
      );
  };
  return render();
};

export default AIModelDescription;
