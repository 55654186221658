import React, { createContext, useContext } from "react";
import constant from "../helper/constant";
import * as ACTION from "../reducers/types";
import useAISocialMediaReducer from "../reducers/ai-social-reducer";
import {
  createSocialAds,
  createSocialImages,
  getSocialMediaSentiment,
} from "../service/ai-model.service";
import { useAppContext } from "./app.context";

const AISocialMediaContext = createContext();

const AISocailMediaProvider = ({ children }) => {
  const { state, dispatch } = useAISocialMediaReducer();
  const { showAlert } = useAppContext();

  const createSocialAdContent = (prompt = "", taskId) => {
    dispatch({ type: ACTION.AI_SM_CREATE_AD_CONTENT, payload: null });
    createSocialAds({ prompt }, taskId)
      .then((data) => {
        handleAdResponse(data);
      })
      .catch((ex) => {
        createSocialAdContent();
        handleError(ex);
      });
  };

  const handleAdResponse = (data) => {
    try {
      const adList = JSON.parse(data.content);
      if (adList && adList.length) {
        dispatch({ type: ACTION.AI_SM_CREATE_AD_CONTENT, payload: adList });
      } else createSocialAdContent();
    } catch (ex) {
      createSocialAdContent();
    }
  };

  const createSocialAdImageContent = (prompt = "", taskId) => {
    dispatch({ type: ACTION.AI_SM_CREATE_AD_IMAGES, payload: null });
    createSocialImages({ prompt }, taskId)
      .then((result) => {
        handleAdImageResponse(result.data);
      })
      .catch((ex) => {
        createSocialAdImageContent();
        handleError(ex);
      });
  };

  const handleAdImageResponse = (data) => {
    if (data && data.length) {
      dispatch({ type: ACTION.AI_SM_CREATE_AD_IMAGES, payload: data });
    } else createSocialAdImageContent();
  };

  const updateAdContent = (list) => {
    dispatch({ type: ACTION.AI_SM_CREATE_AD_CONTENT, payload: list });
  };

  const getReviewKeywords = (templateId, taskId) => {
    try {
      getSocialMediaSentiment({ templateId, taskId })
        .then((data) => {
          if (data && data.length) {
            const feedback = JSON.parse(data[0].feedback);
            dispatch({
              type: ACTION.AI_SM_GET_REVIEW_KEYWORDS,
              payload: feedback,
            });
          }
        })
        .catch((ex) => console.log(ex));
    } catch (ex) {
      console.log(`Error in add generation`);
      handleError(ex);
    }
  };

  /** Common methods */
  const handleError = (e, ignore) => {
    console.log(e);
    if (!ignore) showAlert(e, { appearance: "error" });
  };

  return (
    <AISocialMediaContext.Provider
      value={{
        ...state,
        createSocialAdContent,
        createSocialAdImageContent,
        getReviewKeywords,
        updateAdContent,
      }}
    >
      {children}
    </AISocialMediaContext.Provider>
  );
};

export const useAISocialMediaContext = () => useContext(AISocialMediaContext);
export { AISocailMediaProvider, AISocialMediaContext };
