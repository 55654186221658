import React, { createContext, useContext, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  appLogout,
  auth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "./../../firebase";
import useAuthReducer from "../reducers/auth.reducer";
import * as ACTION from "../reducers/types";
import {
  createOrganization,
  getIndustryList,
  getOrganization,
} from "../service/auth.service";
import { clearSharedInfo, setSharedInfo } from "../helper/shared-info";
import constant from "../helper/constant";
import { useToasts } from "react-toast-notifications";
import { orgCreationBootstrap } from "../helper/helper";
const AuthContext = createContext();
const industries = require("./../../industries.json");

const AuthProvider = ({ children }) => {
  const { state, dispatch } = useAuthReducer();
  const [user, loading, error] = useAuthState(auth);
  const { addToast } = useToasts();

  useEffect(() => {
    dispatch({ type: ACTION.UPDATE_LOADER, payload: loading });
    if (error) {
      dispatch({ type: ACTION.UPDATE_LOADER, payload: false });
      dispatch({ type: ACTION.USER_LOGIN_FAILURE, payload: error });
      // showError(error);
    }
    if (user) {
      dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
      const org_tenant = user.tenantId;
      setSharedInfo(constant.SHARED.BEARER_TOKEN, user.accessToken);
      getOrganization({ org_tenant })
        .then((orgInfo) => {
          let role = "";
          try {
            if (
              user &&
              user.reloadUserInfo &&
              user.reloadUserInfo.customAttributes
            ) {
              const loggedInUser = JSON.parse(
                user.reloadUserInfo.customAttributes,
              );
              role = loggedInUser.role;
            }
          } catch (ex) {
            console.log(ex);
          }
          if (orgInfo && orgInfo.length) {
            if (!orgInfo[0].active) {
              orgCreationBootstrap(orgInfo[0], () => {
                dispatch({ type: ACTION.UPDATE_LOADER, payload: false });
                dispatch({
                  type: ACTION.USER_LOGIN_SUCCESS,
                  payload: { user, org: { ...orgInfo[0], active: true, role } },
                });
              });
            } else {
              dispatch({ type: ACTION.UPDATE_LOADER, payload: false });
              dispatch({
                type: ACTION.USER_LOGIN_SUCCESS,
                payload: { user, org: { ...orgInfo[0], role } },
              });
            }
          }
        })
        .catch((ex) => {
          dispatch({ type: ACTION.UPDATE_LOADER, payload: false });
          dispatch({ type: ACTION.USER_LOGIN_FAILURE, payload: error });
          logout();
        });
    }
  }, [user, error, loading]);

  const login = (user, cb) => {
    const { username, password, company } = user;
    auth.tenantId = company;
    dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
    signInWithEmailAndPassword(auth, username, password)
      .then((r) => console.log(r))
      .catch((ex) => {
        dispatch({ type: ACTION.UPDATE_LOADER, payload: false });
        dispatch({ type: ACTION.USER_LOGIN_FAILURE, payload: ex });
      });
  };

  const requestPasswordReset = (
    username,
    company,
    cb,
    isStateUpdate,
    error,
  ) => {
    if (isStateUpdate)
      dispatch({ type: ACTION.USER_LOGIN_FAILURE, payload: null });
    auth.tenantId = company;
    sendPasswordResetEmail(auth, username)
      .then(() => {
        cb && cb();
      })
      .catch((ex) => error && error(ex));
  };

  const logout = () => {
    appLogout(() => {
      clearStorge();
      window.location.href = "/";
    });
  };

  const clearStorge = () => {
    clearSharedInfo();
    window.localStorage.clear();
    window.sessionStorage.clear();
  };
  const setSelectedIndustry = (payload) =>
    dispatch({ type: ACTION.SET_SELECTED_INDUSTRY, payload });

  const getIndustries = () => {
    getIndustryList()
      .then((r) => dispatch({ type: ACTION.GET_INDUSTRIES, payload: r }))
      .catch((ex) => console.log(ex))
      .finally(() => dispatch({ type: ACTION.UPDATE_LOADER, payload: false }));
  };

  const calculateUnsignedRevenue = (data) => {
    const { industryId, revenue, inventory, count } = data;
    const industry = industries.rows.filter((ind) => ind.id == industryId);
    if (industry.length) {
      const revenueIncrease =
        0.1 *
        revenue *
        constant.Million *
        (industry[0].revenue_to_profit_multiplier / 100);
      const manPowerEff = 0.15 * 82000 * count;
      const matCostReduction = 0.25 * inventory * constant.Million;
      const macCostReduction =
        (industry[0].machine_reduction / 100) * revenue * constant.Million;
      const payload = {
        additionalProfit: revenueIncrease,
        revenue: revenue * constant.Million,
        inventory: inventory * constant.Million,
        report: {
          revenueIncrease,
          matCostReduction,
          manPowerEff,
          macCostReduction,
        },
      };
      updateRevenueReport(payload);
    }
  };

  const createOrgFirstTime = (data, isLogin) => {
    dispatch({ type: ACTION.UPDATE_LOADER, payload: true });
    createOrganization(data)
      .then((r) => {
        dispatch({ type: ACTION.CREATE_ORG_SUCCESS, payload: { ...r } });
        if (isLogin) {
          login(
            { username: r.user, company: r.tenant, password: data.password },
            () => {
              orgCreationBootstrap();
            },
          );
        }
      })
      .catch((ex) => {
        dispatch({ type: ACTION.UPDATE_LOADER, payload: false });
        if (ex?.message.message) showError(ex?.message.message);
        else showError(ex?.message);
      });
  };

  const updateUnsignedUserInfo = (org) => {
    dispatch({
      type: ACTION.GET_UNSIGNED_REPORT_BY_MAIL_SUCCESS,
      payload: { ...org },
    });
  };
  const showError = (message) => {
    if (message) addToast(message, { appearance: "error", autoDismiss: true });
  };

  const updateOrganizationInfo = (org) =>
    dispatch({
      type: ACTION.UPDATE_ORG_INFO,
      payload: { ...state.userInfo, org },
    });

  const updateRevenueReport = (payload) =>
    dispatch({ type: ACTION.UPDATE_REVENUE_REPORT, payload });

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        requestPasswordReset,
        getIndustries,
        setSelectedIndustry,
        calculateUnsignedRevenue,
        updateUnsignedUserInfo,
        createOrgFirstTime,
        updateOrganizationInfo,
        updateRevenueReport,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export { AuthProvider, AuthContext };
