import React, { useState } from "react";
import { useAIModelContext } from "../../../context/ai-model.context";
import constant, { TaskType, ToastType } from "../../../helper/constant";
import { getCalculateExpense, getUUID } from "./../../../helper/helper";
import { Link } from "react-router-dom";
import CustomDropdown from "../../common/CustomDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { useAppContext } from "../../../context/app.context";

const TaskForm = (props) => {
  const { read, tasks, setTasks } = props;
  const { taskList, activeTemplate, selectedModel } = useAIModelContext();
  const { showAlert } = useAppContext();

  const handleInputChange = (value, name, index, type) => {
    const { revenue } = activeTemplate;
    const newData = [...tasks];
    const parentId = selectedModel.id;
    if (name === "task_name") {
      const isTaskExisting = newData.filter((zz) => zz?.id === value);
      if (isTaskExisting.length === 0) {
        let task = taskList.filter((zz) => zz.id == value)[0];
        let {
          task_name,
          modelId,
          model_name,
          task_color,
          task_model,
          id,
          dropdown,
        } = task;
        newData[index] = {
          ...newData[index],
          [name]: value,
          name: task_name,
          modelId,
          model_name,
          task_color,
          task_model,
          id,
          dropdown,
          hasError: false,
        };
      } else showAlert(`Task already exists`, ToastType.WARNING);
    } else {
      if (value === "" || /^[0-9\b]+$/.test(value)) {
        newData[index] = { ...newData[index], parentId, [name]: value };
      }
    }
    const expense = getCalculateExpense(type, newData[index], revenue);
    newData[index].expense = expense;
    newData[index].taskId = newData[index].taskId
      ? newData[index].taskId
      : getUUID();
    setTasks(newData);
  };

  const onRemove = (e, index) => {
    e.preventDefault();
    let newData = [...tasks];
    newData.splice(index, 1);
    setTasks(newData);
  };
  const renderEmployeeNoForm = (noOfEmpForTask, index) => {
    return (
      <div className="form-group">
        {read && (
          <div className="h6  f-13">
            <b>No. of Employees</b> : {noOfEmpForTask}
          </div>
        )}
        {!read && (
          <>
            <div className="h6  f-13">
              <b>No. of Employees</b> <span className="text-danger">*</span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="No. of Employees"
              value={noOfEmpForTask || ""}
              pattern="[0-9]*"
              onChange={(e) =>
                handleInputChange(
                  e.target.value,
                  e.target.name,
                  index,
                  TaskType.EMPLOYEE
                )
              }
              name="noOfEmpForTask"
            />
            {renderHint()}
          </>
        )}
      </div>
    );
  };

  const renderHint = () => <small>*Only numeric value allowed</small>;

  const renderAvgSalary = (avgSalary, index) => {
    return (
      <div className="form-group">
        {read && (
          <div className="h6  f-13">
            <b>Average Salary</b> : {avgSalary}
          </div>
        )}
        {!read && (
          <>
            <div className="h6  f-13">
              <b>Average Salary</b> <span className="text-danger">*</span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Average Salary"
              value={avgSalary || ""}
              onChange={(e) =>
                handleInputChange(
                  e.target.value,
                  e.target.name,
                  index,
                  TaskType.EMPLOYEE
                )
              }
              name="avgSalary"
            />
            {renderHint()}
          </>
        )}
      </div>
    );
  };

  const renderEmployeeSection = ({ noOfEmpForTask, avgSalary }, index) => {
    return (
      <>
        {renderEmployeeNoForm(noOfEmpForTask, index)}
        {renderAvgSalary(avgSalary, index)}
      </>
    );
  };

  const renderInventorySection = ({ inventory }, index) => {
    return (
      <div className="form-group">
        {read && (
          <div className="h6  f-13">
            <b>Inventory</b> : {inventory}
          </div>
        )}
        {!read && (
          <>
            <div className="h6  f-13">
              <b>Inventory</b> <span className="text-danger">*</span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Inventory"
              value={inventory || ""}
              onChange={(e) =>
                handleInputChange(
                  e.target.value,
                  e.target.name,
                  index,
                  TaskType.INVENTORY
                )
              }
              name="inventory"
            />
          </>
        )}
      </div>
    );
  };

  const renderChurnSection = ({ churnrate }, index) => {
    return (
      <div className="form-group row">
        {read && (
          <div className="h6  f-13 mx-3">
            <b>Customer Attrition Prediction(%)</b> : {churnrate}
          </div>
        )}
        {!read && (
          <CustomDropdown
            name="Customer Attrition Prediction"
            options={constant.churnList}
            field="value"
            value={churnrate}
            onChange={(e) =>
              handleInputChange(e, "churnrate", index, TaskType.CHURNRATE)
            }
          />
        )}
      </div>
    );
  };

  const renderDowntimeSection = ({ downtime }, index) => {
    return (
      <div className="form-group row">
        {read && (
          <div className="h6  f-13 mx-3">
            <b>Unplanned Machine Downtime(%)</b> : {downtime}
          </div>
        )}
        {!read && (
          <CustomDropdown
            name="Downtime"
            options={constant.machineDowntime}
            field="value"
            value={downtime}
            onChange={(e) =>
              handleInputChange(e, "downtime", index, TaskType.DOWNTIME)
            }
          />
        )}
      </div>
    );
  };

  const renderTaskBasedForm = (task, index) => {
    const { dropdown } = task;
    switch (dropdown) {
      case TaskType.EMPLOYEE:
        return renderEmployeeSection(task, index);
      case TaskType.INVENTORY:
        return renderInventorySection(task, index);
      case TaskType.CHURNRATE:
        return renderChurnSection(task, index);
      case TaskType.DOWNTIME:
        return renderDowntimeSection(task, index);
      default:
        return;
    }
  };
  const renderTaskList = () => {
    if (tasks && tasks.length)
      return tasks.map((task, index) => {
        const { task_name, name } = task;
        return (
          <div className="mb-3" key={`task-form-${index}`}>
            <div className="task-section">
              <div className="form-group">
                {read && (
                  <div className="h6 f-13">
                    <b className="text-blue">Task</b> : {name}
                  </div>
                )}
                {!read && (
                  <>
                    <div className="d-flex align-items-center justify-content-between py-2">
                      <p className="m-0 p-0 h6 f-13 text-blue">
                        <b>Task</b>
                      </p>
                      <div className="text-right">
                        <Link
                          className="task-remove"
                          onClick={(e) => onRemove(e, index)}
                        >
                          <FontAwesomeIcon icon={faTrashCan} />
                        </Link>
                      </div>
                    </div>
                    <CustomDropdown
                      hasError={task.hasError}
                      name=""
                      options={taskList}
                      field="task_name"
                      value={task_name}
                      onChange={(e) => handleInputChange(e, "task_name", index)}
                    />
                  </>
                )}
              </div>
              <>{renderTaskBasedForm(task, index)}</>
            </div>
          </div>
        );
      });
    return <h6 className="text-center"></h6>;
  };

  const render = () => {
    return <>{renderTaskList()}</>;
  };
  return render();
};

export default TaskForm;
