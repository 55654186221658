import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useInput from "../../hooks/useInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import constant, { CurrencyType, ToastType } from "../../helper/constant";
import { useAuthContext } from "../../context/auth.context";
import { useAppContext } from "../../context/app.context";
import { updateOrganization } from "../../service/auth.service";
import CustomLoader from "../common/CustomLoader";
import { triggerOtherOrgInfo } from "../../service/ai-model.service";

const CreateOrgForm = () => {
  const { industries, getIndustries, userInfo, updateOrganizationInfo } =
    useAuthContext();
  const { updateLoader, showAlert, isLoading } = useAppContext();
  const navigate = useNavigate();
  const [companyName, bindCompanyName, resetCompanyName] = useInput("");
  const [companyUrl, bindCompanyUrl, resetCompanyUrl] = useInput("");
  const [industry, bindIndustry, resetIndustry] = useInput("");
  const [currency, setCurrency] = useState(CurrencyType.dollar);
  const [inventory, bindInventory, resetInventory] = useInput("");
  const [revenue, bindRevenue, resetRevenue] = useInput("");
  const [empCount, bindEmpCount, resetEmpCount] = useInput("");
  const [attritionRate, bindAttritionRate, resetAttritionRate] = useInput("");
  const [downtime, bindDowntime, resetDowntime] = useInput("");
  const [supplierList, setSupplierList] = useState([{ name: "", url: "" }]);
  const [competitorList, setCompetitorList] = useState([{ name: "", url: "" }]);
  const [customerList, setCustomerList] = useState([{ name: "", url: "" }]);
  const [form, setForm] = useState({ error: {} });

  useEffect(() => {
    getIndustries();
  }, []);

  const handleValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!companyName) {
      formIsValid = false;
      error["companyName"] = "CompanyName is required.";
    }

    if (!companyUrl) {
      formIsValid = false;
      error["companyUrl"] = "Company website is required.";
    }

    if (!industry) {
      formIsValid = false;
      error["industry"] = "Industry is required.";
    }
    if (!revenue) {
      formIsValid = false;
      error["revenue"] = "Revenue is required.";
    }
    if (!empCount) {
      formIsValid = false;
      error["empCount"] = "Employee Count is required.";
    }

    if (10000 < empCount < 0) {
      formIsValid = false;
      error["empCount"] = "Employee Count should be less than 10,000.";
    }
    setForm({ error });
    return formIsValid;
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const org_config = {
      companyName,
      companyUrl,
      industry,
      empCount,
      currency,
      inventory: inventory * constant.Million,
      revenue: revenue * constant.Million,
      competitorList,
      supplierList,
      customerList,
      downtime,
      attritionRate,
    };
    const payload = { ...userInfo.org, org_config };
    if (handleValidation()) {
      updateLoader(true);
      updateOrganization(userInfo.org.id, payload)
        .then((result) => {
          updateOrganizationInfo(payload);
          triggerOtherOrgInfo()
            .then((res) => res)
            .catch((ex) => console.log(ex));
          navigate(`/${constant.PAGES.HOME.BUSINESS_OVERVIEW}`);
        })
        .catch((e) => showAlert(e, ToastType.ERROR))
        .finally(() => updateLoader(false));
    }
  };

  const onCompetitorChange = (field, value, addIndex) => {
    const list = [...competitorList];
    list[addIndex][field] = value;
    setCompetitorList(list);
  };

  const removeCompetitor = (index) => {
    if (competitorList.length === 1) return;
    const list = [...competitorList];
    list.splice(index, 1);
    setCompetitorList(list);
  };

  const renderCompetitorList = () => {
    if (competitorList.length)
      return competitorList.map((competitor, key) => (
        <Fragment key={key}>
          <div className="col-md-5 mt-3">
            <div className="d-flex align-items-center">
              <div className="mw-170">Competitor</div>
              <input
                type="text"
                className="form-control"
                value={competitor.name}
                onChange={(e) =>
                  onCompetitorChange("name", e.target.value, key)
                }
              />
            </div>
          </div>

          <div className="col-md-5 mt-3">
            <div className="d-flex align-items-center">
              <div className="mw-170">Competitor Website </div>
              <input
                type="text"
                className="form-control"
                value={competitor.url}
                onChange={(e) => onCompetitorChange("url", e.target.value, key)}
              />
            </div>
          </div>
          <div className="col-md-2 mt-3 justify-content-end">
            {competitorList.length > 1 && (
              <Link onClick={() => removeCompetitor(key)}>
                <FontAwesomeIcon icon={faTrashCan} color="text-danger" />
              </Link>
            )}
          </div>
        </Fragment>
      ));
  };

  const addCompetitor = () => {
    const list = [...competitorList];
    list.push({ name: "", url: "" });
    setCompetitorList(list);
  };

  const onCustomerChange = (field, value, addIndex) => {
    const list = [...customerList];
    list[addIndex][field] = value;
    setCustomerList(list);
  };

  const removeCustomer = (index) => {
    if (customerList.length === 1) return;
    const list = [...customerList];
    list.splice(index, 1);
    setCustomerList(list);
  };

  const renderCustomerList = () => {
    if (customerList.length)
      return customerList.map((customer, key) => (
        <Fragment key={key}>
          <div className="col-md-5 mt-3">
            <div className="d-flex align-items-center">
              <div className="mw-170">Customer</div>
              <input
                type="text"
                className="form-control"
                value={customer.name}
                onChange={(e) => onCustomerChange("name", e.target.value, key)}
              />
            </div>
          </div>

          <div className="col-md-5 mt-3">
            <div className="d-flex align-items-center">
              <div className="mw-170">Customer Website </div>
              <input
                type="text"
                className="form-control"
                value={customer.url}
                onChange={(e) => onCustomerChange("url", e.target.value, key)}
              />
            </div>
          </div>
          <div className="col-md-2 mt-3 justify-content-end">
            {customerList.length > 1 && (
              <Link onClick={() => removeCustomer(key)}>
                <FontAwesomeIcon icon={faTrashCan} color="text-danger" />
              </Link>
            )}
          </div>
        </Fragment>
      ));
  };

  const addCustomer = () => {
    const list = [...customerList];
    list.push({ name: "", url: "" });
    setCustomerList(list);
  };

  const onSupplierChange = (field, value, addIndex) => {
    const list = [...supplierList];
    list[addIndex][field] = value;
    setSupplierList(list);
  };

  const removeSupplier = (index) => {
    if (supplierList.length === 1) return;
    const list = [...supplierList];
    list.splice(index, 1);
    setSupplierList(list);
  };

  const renderSupplierList = () => {
    if (supplierList.length)
      return supplierList.map((supplier, key) => (
        <Fragment key={key}>
          <div className="col-md-5 mt-3">
            <div className="d-flex align-items-center">
              <div className="mw-170">Supplier</div>
              <input
                type="text"
                className="form-control"
                value={supplier.name}
                onChange={(e) => onSupplierChange("name", e.target.value, key)}
              />
            </div>
          </div>
          <div className="col-md-5 mt-3">
            <div className="d-flex align-items-center">
              <div className="mw-170">Supplier Website </div>
              <input
                type="text"
                className="form-control"
                value={supplier.url}
                onChange={(e) => onSupplierChange("url", e.target.value, key)}
              />
            </div>
          </div>
          <div className="col-md-2 mt-3">
            {supplierList.length > 1 && (
              <Link onClick={() => removeSupplier(key)}>
                <FontAwesomeIcon icon={faTrashCan} color="text-danger" />
              </Link>
            )}
          </div>
        </Fragment>
      ));
  };

  const addSupplier = () => {
    const list = [...supplierList];
    list.push({ name: "", url: "" });
    setSupplierList(list);
  };

  const render = () => {
    return (
      <>
        {isLoading && <CustomLoader full />}
        <section className="d-flex align-items-center justify-content-center pb-3">
          <div className="container">
            <div className="welcomebox p-3">
              <h5 className="font-weight-bold mb-2">Welcome to Avtar AI</h5>
              <p className="pb-0">
                Share some basic info. about your company with us & we will
                assist you all the automation possibilities to leverage your
                business to the next step.
              </p>
            </div>
            <h4 className="text-blue pb-2 mb-3 mt-5 font-weight-bold">
              Company Information
            </h4>
            <div className="formsectionbox">
              <form onSubmit={onFormSubmit} noValidate>
                <div className="row align-items-center justify-content-between mt-3">
                  <div className="col-md-12 mt-3">
                    <div className="d-flex align-items-center">
                      <div className="mw-170">
                        <b>
                          Company Name <span className="text-danger">*</span>
                        </b>
                      </div>
                      <input
                        type="text"
                        className={`form-control ${form.error["companyName"] ? "error-br" : ""}`}
                        {...bindCompanyName}
                      />
                    </div>
                    {form.error["companyName"] && (
                      <small className="text-danger ml-170">
                        {form.error["companyName"]}
                      </small>
                    )}
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="d-flex align-items-center">
                      <div className="mw-170">
                        Company Website <span className="text-danger">*</span>
                      </div>
                      <input
                        type="text"
                        className={`form-control ${form.error["companyUrl"] ? "error-br" : ""}`}
                        {...bindCompanyUrl}
                      />
                    </div>
                    {form.error["companyUrl"] && (
                      <small className="text-danger ml-170">
                        {form.error["companyUrl"]}
                      </small>
                    )}
                  </div>

                  <div className="col-md-6 mt-3">
                    <div className="d-flex align-items-center">
                      <div className="mw-170">
                        Industry<span className="text-danger">*</span>
                      </div>
                      <select
                        className={`form-control ${form.error["industry"] ? "error-br" : ""}`}
                        {...bindIndustry}
                      >
                        <option>Select Industry</option>
                        {industries.map((item, key) => (
                          <option key={key} value={item.id}>
                            {item.industry_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {form.error["industry"] && (
                      <small className="text-danger ml-170">
                        {form.error["companyUrl"]}
                      </small>
                    )}
                  </div>

                  <div className="col-md-12"></div>
                  <div className="col-md-6 mt-3">
                    <div className="d-flex align-items-center">
                      <div className="mw-170">
                        Employee Count <span className="text-danger">*</span>
                      </div>
                      <input
                        type="tel"
                        className="form-control"
                        {...bindEmpCount}
                      />
                    </div>
                    {form.error["empCount"] && (
                      <small className="text-danger ml-170">
                        {form.error["empCount"]}
                      </small>
                    )}
                  </div>
                  <div className="col-md-12"></div>
                  <div className="col-sm-12 col-md-6 mt-3">
                    <div className="d-flex">
                      <div className="mw-170">Currency</div>
                      <div className="form-check form-check-inline text-nowrap">
                        <label className="mb-0 mr-1">
                          <input
                            className="form-check-input mr-0"
                            type="radio"
                            value={CurrencyType.dollar}
                            checked={currency === CurrencyType.dollar}
                            onChange={(e) => setCurrency(e.target.value)}
                          />{" "}
                          ${" "}
                        </label>
                      </div>
                      <div className="form-check form-check-inline text-nowrap">
                        <label className="mb-0 mr-1">
                          <input
                            className="form-check-input mr-0"
                            type="radio"
                            value={CurrencyType.pound}
                            checked={currency === CurrencyType.pound}
                            onChange={(e) => setCurrency(e.target.value)}
                          />{" "}
                          £
                        </label>
                      </div>
                      <div className="form-check form-check-inline text-nowrap">
                        <label className="mb-0 mr-1">
                          <input
                            className="form-check-input mr-0"
                            type="radio"
                            value={CurrencyType.euro}
                            checked={currency === CurrencyType.euro}
                            onChange={(e) => setCurrency(e.target.value)}
                          />{" "}
                          €
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12"></div>
                  <div className="col-sm-12 col-md-6 mt-3">
                    <div className="d-flex align-items-center">
                      <div className="mw-170">
                        Revenue (in Millions)
                        <span className="text-danger">*</span>
                      </div>
                      <input
                        type="tel"
                        className={`form-control ${form.error["revenue"] ? "error-br" : ""}`}
                        {...bindRevenue}
                      />
                    </div>
                    {form.error["revenue"] && (
                      <small className="text-danger ml-170">
                        {form.error["revenue"]}
                      </small>
                    )}
                  </div>

                  <div className="col-md-12"></div>
                  <div className="col-md-6 mt-3">
                    <div className="d-flex align-items-center">
                      <div className="mw-170">Inventory (in Millions)</div>
                      <input
                        type="tel"
                        className="form-control"
                        {...bindInventory}
                      />
                    </div>
                  </div>

                  <div className="col-md-12"></div>
                  <div className="col-md-6 mt-3">
                    <div className="d-flex align-items-center">
                      <div className="mw-170">Customer Attrition Rate</div>
                      <select className="form-control" {...bindAttritionRate}>
                        <option>Select Attrition Rate</option>
                        {constant.churnList.map((opt, key) => (
                          <option key={key} value={opt.id}>
                            {opt.value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12"></div>
                  <div className="col-md-6 mt-3">
                    <div className="d-flex align-items-center">
                      <div className="mw-170">Unplanned Machine Downtime</div>
                      <select className="form-control" {...bindDowntime}>
                        <option>Select Downtime</option>
                        {constant.machineDowntime.map((opt, key) => (
                          <option key={key} value={opt.id}>
                            {opt.value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12 border-top pt-3 mt-5"></div>
                  {renderCompetitorList()}

                  <Link
                    onClick={() => addCompetitor()}
                    className="px-2 py-4 d-flex align-items-center text-blue cursor-pointer"
                  >
                    <span className="mr-2">
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5 7V10M10.5 10V13M10.5 10H13.5M10.5 10H7.5M19.5 10C19.5 14.9706 15.4706 19 10.5 19C5.52944 19 1.5 14.9706 1.5 10C1.5 5.02944 5.52944 1 10.5 1C15.4706 1 19.5 5.02944 19.5 10Z"
                          stroke="#007BFF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>{" "}
                    add more Competitor
                  </Link>
                  <div className="col-md-12 border-bottom mt-3"></div>
                  {renderSupplierList()}

                  <Link
                    onClick={() => addSupplier()}
                    className="px-2 py-4 d-flex align-items-center text-blue cursor-pointer"
                  >
                    <span className="mr-2">
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5 7V10M10.5 10V13M10.5 10H13.5M10.5 10H7.5M19.5 10C19.5 14.9706 15.4706 19 10.5 19C5.52944 19 1.5 14.9706 1.5 10C1.5 5.02944 5.52944 1 10.5 1C15.4706 1 19.5 5.02944 19.5 10Z"
                          stroke="#007BFF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    add more Supplier
                  </Link>
                  <div className="col-md-12 border-bottom mt-3"></div>
                  {renderCustomerList()}

                  <Link
                    onClick={() => addCustomer()}
                    className="px-2 py-4 d-flex align-items-center text-blue cursor-pointer"
                  >
                    <span className="mr-2">
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5 7V10M10.5 10V13M10.5 10H13.5M10.5 10H7.5M19.5 10C19.5 14.9706 15.4706 19 10.5 19C5.52944 19 1.5 14.9706 1.5 10C1.5 5.02944 5.52944 1 10.5 1C15.4706 1 19.5 5.02944 19.5 10Z"
                          stroke="#007BFF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    add more Customer
                  </Link>

                  <div className="col-12"></div>
                  <div className="col-5">
                    <button
                      type="submit"
                      className="btn1 mt-3 font-weight-bold w-100"
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </>
    );
  };
  return render();
};

export default CreateOrgForm;
