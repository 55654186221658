import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../context/auth.context";
import useInput from "../../../hooks/useInput";
import constant from "../../../helper/constant";
import Recapcha from "../../auth/Recapcha";
import images from "../../../helper/images";
import { useAIModelContext } from "../../../context/ai-model.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faDownload,
  faExclamationCircle,
  faUpload,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { getUUID } from "../../../helper/helper";
import { checkForTemplateName } from "../../../service/ai-model.service";
import CustomLoader from "../../common/CustomLoader";

const TemplateForm = () => {
  const { getIndustries } = useAuthContext();
  const { createTemplateWithoutData, createTemplateWithData } =
    useAIModelContext();
  const [template_name, bindTemplateName, resetTemplateName] = useInput("");
  const [apiStatus, setApiStatus] = useState({
    api: false,
    loading: false,
    success: false,
  });
  const [csv, setCSV] = useState("");
  const [form, setForm] = useState({ error: { valid: false } });
  const [tempId, setTempId] = useState();
  const fileRef = useRef();

  useEffect(() => {
    getIndustries();
    clearForm();
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (template_name) {
        setApiStatus({ api: true, loading: true, success: false });
        checkForTemplateName(template_name)
          .then((result) => {
            if (result && result.length) {
              setApiStatus({
                api: true,
                loading: false,
                success: result[0]["count(*)"] === 0,
              });
            } else {
              setApiStatus({ api: true, loading: false, success: true });
            }
          })
          .catch(() => setApiStatus({ loading: false, success: true }));
      } else setApiStatus({ api: false, loading: false, success: false });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [template_name]);

  const clearForm = () => {
    setCSV(null);
    resetTemplateName();
  };

  const handleValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!template_name) {
      formIsValid = false;
      error["template_name"] = "Workflow required.";
    }

    setForm({ error });
    return formIsValid;
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      const id = getUUID();
      const data = {
        id,
        csv,
        template_name,
        template_JSON: [],
      };

      if (csv) {
        const payload = new FormData();
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            const element = data[key];
            payload.set(key, element);
          }
        }
        createTemplateWithData(payload, (tempId) => setTempId(tempId));
      } else {
        createTemplateWithoutData(data, (tempId) => setTempId(tempId));
      }
    }
  };

  const onVerification = (status) => {
    let error = { ...form.error };
    error["valid"] = status;
    setForm({ error });
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length) setCSV(e.target.files[0]);
  };

  const renderWorkflowSuccess = () => {
    return (
      <div className="col-md-5 mx-auto my-5">
        <h1 className="h2 font-weight-bold">Welcome to Avtar AI.</h1>
        <h5 className="h6 font-weight-bold">
          Your new workflow successfully created.
        </h5>
        <Link
          to={`/${constant.PAGES.HOME.AI_WORKFLOW}/${tempId}`}
          className="btn1 mt-3 arrowhover d-flex align-items-cent justify-content-between font-weight-bold w-100"
        >
          Let's Start
          <span>
            <svg
              width="25"
              height="24"
              viewBox="0 0 45 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M44.0607 13.0607C44.6464 12.4749 44.6464 11.5251 44.0607 10.9393L34.5147 1.3934C33.9289 0.807612 32.9792 0.807612 32.3934 1.3934C31.8076 1.97918 31.8076 2.92893 32.3934 3.51472L40.8787 12L32.3934 20.4853C31.8076 21.0711 31.8076 22.0208 32.3934 22.6066C32.9792 23.1924 33.9289 23.1924 34.5147 22.6066L44.0607 13.0607ZM0 13.5H43V10.5H0V13.5Z"
                fill="white"
              />
            </svg>
          </span>
        </Link>
        <div className="mt-5 text-center">
          <div>
            <img
              src={images.workflow}
              className="mx-auto"
              width="250px"
              alt=""
            />
          </div>
        </div>
      </div>
    );
  };

  const renderTemplateLoader = () => {
    if (apiStatus.loading) return <CustomLoader size="xs" />;
    if (apiStatus.api)
      return (
        <FontAwesomeIcon
          icon={apiStatus.success ? faCheckCircle : faExclamationCircle}
          className={`${apiStatus.success ? "text-success" : "text-danger"}`}
          size="2x"
        />
      );
  };

  const render = () => {
    if (tempId) return renderWorkflowSuccess();
    return (
      <div className="row mx-0">
        <div className="col-md-12 px-md-0 py-5 m-auto">
          <form onSubmit={onFormSubmit} noValidate>
            <div className="px-5 h-100">
              <div className="row">
                <div className="col-12 mx-auto">
                  <div className="col-md-8 mx-auto mt-3">
                    <div className="d-flex align-items-center gap-2">
                      <div className="bold" style={{ minWidth: 170 }}>
                        Workflow Title <span className="text-danger">*</span>
                      </div>
                      <input
                        type="text"
                        className={`form-control ${form.error["template_name"] ? "error-br" : ""}`}
                        {...bindTemplateName}
                      />
                      {renderTemplateLoader()}
                    </div>
                  </div>
                  <div className="col-md-8 mx-auto mt-5">
                    <div className="d-flex align-items-center gap-2">
                      <div className="bold" style={{ minWidth: 170 }}>
                        Upload template
                      </div>
                      <div className="upload-btn-wrapper cursor-pointer w-20">
                        <button type="button" className="btnupld-blu w-100">
                          Upload <FontAwesomeIcon icon={faUpload} />
                        </button>
                        <input
                          ref={fileRef}
                          type="file"
                          name="myfile"
                          onChange={handleFileChange}
                        />
                      </div>
                      <Link className="align-items-center cursor-pointer mx-3">
                        Download Template
                        <FontAwesomeIcon icon={faDownload} className="ml-2" />
                      </Link>
                      <div className="sampleinfo d-flex p-3 mx-2 align-items-center">
                        <div>
                          <svg
                            width="39"
                            height="39"
                            viewBox="0 0 69 69"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="34.5"
                              cy="34.5"
                              r="33.5"
                              fill="#E6E6E6"
                              stroke="#878585"
                              strokeWidth="2"
                            />
                            <path
                              d="M31.9167 54.8333V50.6667H34V54.8333H31.9167ZM31.9167 45.4583C31.9167 36.0833 42.3333 32.9583 42.3333 26.7083C42.3333 21.5 38.1667 17.3333 32.9583 17.3333C28.125 17.3333 24.1667 20.9792 23.6458 25.6667H21.5C22.0833 19.8333 26.9792 15.25 32.9583 15.25C35.9973 15.25 38.9117 16.4572 41.0606 18.6061C43.2095 20.7549 44.4167 23.6694 44.4167 26.7083C44.4167 34 34 36.0833 34 45.4583V46.5H31.9167V45.4583Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                        <div className="ml-2">
                          Download the sample template & upload the same after
                          inserting data into it
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 mx-auto mt-1">
                    <div className="d-flex align-items-center">
                      <div className="bold" style={{ minWidth: 170 }}></div>
                      {csv && (
                        <div className="h6 f-13">
                          {csv.name}{" "}
                          <span>
                            <Link
                              onClick={() => {
                                setCSV(null);
                                if (fileRef.current) fileRef.current.value = "";
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faXmarkCircle}
                                className="text-danger"
                              />
                            </Link>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-5">
                    <Recapcha onVerification={onVerification} />
                  </div>
                  <div className="col-md-4 mx-auto mt-1">
                    <button
                      className="btn1 mt-5 arrowhover d-flex align-items-center justify-content-between font-weight-bold w-100"
                      type="submit"
                    >
                      Create New Workflow
                      <span>
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 45 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M44.0607 13.0607C44.6464 12.4749 44.6464 11.5251 44.0607 10.9393L34.5147 1.3934C33.9289 0.807612 32.9792 0.807612 32.3934 1.3934C31.8076 1.97918 31.8076 2.92893 32.3934 3.51472L40.8787 12L32.3934 20.4853C31.8076 21.0711 31.8076 22.0208 32.3934 22.6066C32.9792 23.1924 33.9289 23.1924 34.5147 22.6066L44.0607 13.0607ZM0 13.5H43V10.5H0V13.5Z"
                            fill="white"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  return render();
};

export default TemplateForm;
