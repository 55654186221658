import React from "react";
import images from "../../../helper/images";

const AITraining = () => {
  
  const renderUltimateContent = () => {
    return (
      <>
        <h1 className="h2 mb-4">
          AI Ultimate for AI Translators and Citizen Data Scientists
        </h1>
        <h3 className="font-weight-bold h5 mb-2 mb-0">Introduction:</h3>
        <p className="mb-3">
          Step into the realm of applied AI with a course designed not for data
          scientists, but for the doers: AI translators, business analysts, web
          developers, and data engineers. Dive into practical AI/ML modelling
          techniques and tools, empowering you to contribute effectively to your
          organization's AI initiatives.
        </p>
        <div className="mb-4 mt-2">
          <div className="d-flex align-items-center mb-2">
            <img src={images.aiTrainingProfile} alt="profile" />
            <h3 className="font-weight-bold h5 ml-2 mb-0">
              Who is this for ? :
            </h3>
          </div>
          <ul className="listdots">
            <li>
              {" "}
              AI Translators, Business Analysts, Web Developers, Data Engineers
            </li>
            <li>AI/ML enthusiasts looking for practical, applied skills</li>
          </ul>
        </div>
        <div className="mb-4 mt-2">
          <div className="d-flex align-items-center mb-2">
            <div>
              <svg width="25" height="25" viewBox="0 0 44 44" fill="none">
                <path
                  d="M22 0.0625C17.6612 0.0625 13.4198 1.34911 9.81219 3.75964C6.20458 6.17016 3.3928 9.59633 1.7324 13.6049C0.0720052 17.6134 -0.362431 22.0243 0.484033 26.2798C1.3305 30.5353 3.41984 34.4441 6.48786 37.5122C9.55587 40.5802 13.4648 42.6695 17.7202 43.516C21.9757 44.3624 26.3866 43.928 30.3951 42.2676C34.4037 40.6072 37.8299 37.7954 40.2404 34.1878C42.6509 30.5802 43.9375 26.3388 43.9375 22C43.9314 16.1837 41.6181 10.6074 37.5054 6.49463C33.3926 2.38188 27.8163 0.0686421 22 0.0625ZM22 40.5625C18.3287 40.5625 14.7398 39.4738 11.6872 37.4342C8.63465 35.3945 6.25545 32.4954 4.8505 29.1036C3.44554 25.7117 3.07794 21.9794 3.79418 18.3786C4.51042 14.7779 6.27833 11.4703 8.87434 8.87433C11.4704 6.27832 14.7779 4.51041 18.3786 3.79417C21.9794 3.07794 25.7117 3.44553 29.1036 4.85049C32.4954 6.25544 35.3945 8.63464 37.4342 11.6872C39.4738 14.7398 40.5625 18.3287 40.5625 22C40.5569 26.9214 38.5994 31.6396 35.1195 35.1195C31.6396 38.5994 26.9214 40.5569 22 40.5625ZM35.5 22C35.5 22.4476 35.3222 22.8768 35.0058 23.1932C34.6893 23.5097 34.2601 23.6875 33.8125 23.6875H22C21.5525 23.6875 21.1232 23.5097 20.8068 23.1932C20.4903 22.8768 20.3125 22.4476 20.3125 22V10.1875C20.3125 9.73995 20.4903 9.31073 20.8068 8.99426C21.1232 8.67779 21.5525 8.5 22 8.5C22.4476 8.5 22.8768 8.67779 23.1933 8.99426C23.5097 9.31073 23.6875 9.73995 23.6875 10.1875V20.3125H33.8125C34.2601 20.3125 34.6893 20.4903 35.0058 20.8068C35.3222 21.1232 35.5 21.5524 35.5 22Z"
                  fill="#007BFF"
                />
              </svg>
            </div>
            <h3 className="font-weight-bold h5 ml-2 mb-0">
              Training Duration :
            </h3>
          </div>
          <div> 1 Week (4 hours each day)</div>
        </div>
        <div className="mb-4 mt-2">
          <div className="d-flex align-items-center mb-2">
            <div>
              <svg width="25" height="25" viewBox="0 0 42 42" fill="none">
                <path
                  d="M35.5827 4.33464H31.416V2.2513C31.416 1.69877 31.1965 1.16886 30.8058 0.778163C30.4151 0.387462 29.8852 0.167969 29.3327 0.167969C28.7801 0.167969 28.2502 0.387462 27.8595 0.778163C27.4688 1.16886 27.2494 1.69877 27.2494 2.2513V4.33464H14.7493V2.2513C14.7493 1.69877 14.5299 1.16886 14.1392 0.778163C13.7485 0.387462 13.2186 0.167969 12.666 0.167969C12.1135 0.167969 11.5836 0.387462 11.1929 0.778163C10.8022 1.16886 10.5827 1.69877 10.5827 2.2513V4.33464H6.41602C4.75841 4.33464 3.1687 4.99312 1.9966 6.16522C0.824496 7.33732 0.166016 8.92703 0.166016 10.5846V35.5846C0.166016 37.2422 0.824496 38.832 1.9966 40.0041C3.1687 41.1762 4.75841 41.8346 6.41602 41.8346H35.5827C37.2403 41.8346 38.83 41.1762 40.0021 40.0041C41.1742 38.832 41.8327 37.2422 41.8327 35.5846V10.5846C41.8327 8.92703 41.1742 7.33732 40.0021 6.16522C38.83 4.99312 37.2403 4.33464 35.5827 4.33464ZM37.666 35.5846C37.666 36.1372 37.4465 36.6671 37.0558 37.0578C36.6651 37.4485 36.1352 37.668 35.5827 37.668H6.41602C5.86348 37.668 5.33358 37.4485 4.94288 37.0578C4.55218 36.6671 4.33268 36.1372 4.33268 35.5846V21.0013H37.666V35.5846ZM37.666 16.8346H4.33268V10.5846C4.33268 10.0321 4.55218 9.5022 4.94288 9.1115C5.33358 8.7208 5.86348 8.5013 6.41602 8.5013H10.5827V10.5846C10.5827 11.1372 10.8022 11.6671 11.1929 12.0578C11.5836 12.4485 12.1135 12.668 12.666 12.668C13.2186 12.668 13.7485 12.4485 14.1392 12.0578C14.5299 11.6671 14.7493 11.1372 14.7493 10.5846V8.5013H27.2494V10.5846C27.2494 11.1372 27.4688 11.6671 27.8595 12.0578C28.2502 12.4485 28.7801 12.668 29.3327 12.668C29.8852 12.668 30.4151 12.4485 30.8058 12.0578C31.1965 11.6671 31.416 11.1372 31.416 10.5846V8.5013H35.5827C36.1352 8.5013 36.6651 8.7208 37.0558 9.1115C37.4465 9.5022 37.666 10.0321 37.666 10.5846V16.8346Z"
                  fill="#007BFF"
                />
              </svg>
            </div>
            <h3 className="font-weight-bold h5 ml-2 mb-0">
              Training Outline :
            </h3>
          </div>
          <div className="row justify-content-between mt-4">
            <div className="col-md-6">
              <div className="pr-md-5 mb-5">
                <div className="mb-2 text-blue">Day</div>
                <div className="border p-3 px-md-4 py-md-4 h-100 position-relative">
                  <div className="daynumber">1</div>
                  <h5 className="my-3 font-weight-bold">
                    Introduction to Applied AI/ML
                  </h5>
                  <ul className="listdots">
                    <li>AI/ML Concepts and Terminology</li>
                    <li>Understanding the AI/ML Lifecycle</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="pr-md-5 mb-5">
                <div className="mb-2 text-blue">Day</div>
                <div className="border p-3 px-md-4 py-md-4 h-100 position-relative">
                  <div className="daynumber">2</div>
                  <h5 className="my-3 font-weight-bold">
                    Data Handling and Preparation
                  </h5>
                  <ul className="listdots">
                    <li>Data Collection and Cleaning</li>
                    <li>Feature Engineering</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="pr-md-5 mb-5">
                <div className="mb-2 text-blue">Day</div>
                <div className="border p-3 px-md-4 py-md-4 h-100 position-relative">
                  <div className="daynumber">3</div>
                  <h5 className="my-3 font-weight-bold">Building AI Models</h5>
                  <ul className="listdots">
                    <li>Selecting and Training Models</li>
                    <li>Evaluation and Tuning</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="pr-md-5 mb-5">
                <div className="mb-2 text-blue">Day</div>
                <div className="border p-3 px-md-4 py-md-4 h-100 position-relative">
                  <div className="daynumber">4</div>
                  <h5 className="my-3 font-weight-bold">
                    Deployment and Monitoring
                  </h5>
                  <ul className="listdots">
                    <li>Deploying Models into Production</li>
                    <li>Monitoring and Maintaining Model Performance</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="pr-md-5 mb-5">
                <div className="mb-2 text-blue">Day</div>
                <div className="border p-3 px-md-4 py-md-4 h-100 position-relative">
                  <div className="daynumber">5</div>
                  <h5 className="my-3 font-weight-bold">Project Workshop</h5>
                  <ul className="listdots">
                    <li>Hands-on Project: From Concept to Deployment</li>
                    <li>Group Presentations and Feedback Session</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <p>
                These outlines aim to provide a clear, engaging, and
                comprehensive learning journey for each target persona, ensuring
                they are well-equipped to harness AI's potential within their
                roles.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderPrimerContent = () => {
    return (
      <>
        <h1 className="h2 mb-4">AI Primer for CEOs and Executives</h1>
        <h3 className="font-weight-bold h5 mb-2 mb-0">Introduction:</h3>
        <p className="mb-3">
          Unlock the potential of AI in driving your business forward. This
          training is crafted for CEOs and executives looking to harness the
          power of artificial intelligence to secure a competitive edge,
          streamline operations, and enhance revenue generation. No technical
          expertise required, just a vision to lead in the digital era.
        </p>
        <div className="mb-4 mt-2">
          <div className="d-flex align-items-center mb-2">
            <img src={images.aiTrainingProfile} alt="profile" />
            <h3 className="font-weight-bold h5 ml-2 mb-0">
              Who is this for ? :
            </h3>
          </div>
          <ul className="listdots">
            <li> CEOs and top-level executives </li>
            <li>
              Business leaders seeking to integrate AI into their strategies
            </li>
          </ul>
        </div>
        <div className="mb-4 mt-2">
          <div className="d-flex align-items-center mb-2">
            <div>
              <svg width="25" height="25" viewBox="0 0 44 44" fill="none">
                <path
                  d="M22 0.0625C17.6612 0.0625 13.4198 1.34911 9.81219 3.75964C6.20458 6.17016 3.3928 9.59633 1.7324 13.6049C0.0720052 17.6134 -0.362431 22.0243 0.484033 26.2798C1.3305 30.5353 3.41984 34.4441 6.48786 37.5122C9.55587 40.5802 13.4648 42.6695 17.7202 43.516C21.9757 44.3624 26.3866 43.928 30.3951 42.2676C34.4037 40.6072 37.8299 37.7954 40.2404 34.1878C42.6509 30.5802 43.9375 26.3388 43.9375 22C43.9314 16.1837 41.6181 10.6074 37.5054 6.49463C33.3926 2.38188 27.8163 0.0686421 22 0.0625ZM22 40.5625C18.3287 40.5625 14.7398 39.4738 11.6872 37.4342C8.63465 35.3945 6.25545 32.4954 4.8505 29.1036C3.44554 25.7117 3.07794 21.9794 3.79418 18.3786C4.51042 14.7779 6.27833 11.4703 8.87434 8.87433C11.4704 6.27832 14.7779 4.51041 18.3786 3.79417C21.9794 3.07794 25.7117 3.44553 29.1036 4.85049C32.4954 6.25544 35.3945 8.63464 37.4342 11.6872C39.4738 14.7398 40.5625 18.3287 40.5625 22C40.5569 26.9214 38.5994 31.6396 35.1195 35.1195C31.6396 38.5994 26.9214 40.5569 22 40.5625ZM35.5 22C35.5 22.4476 35.3222 22.8768 35.0058 23.1932C34.6893 23.5097 34.2601 23.6875 33.8125 23.6875H22C21.5525 23.6875 21.1232 23.5097 20.8068 23.1932C20.4903 22.8768 20.3125 22.4476 20.3125 22V10.1875C20.3125 9.73995 20.4903 9.31073 20.8068 8.99426C21.1232 8.67779 21.5525 8.5 22 8.5C22.4476 8.5 22.8768 8.67779 23.1933 8.99426C23.5097 9.31073 23.6875 9.73995 23.6875 10.1875V20.3125H33.8125C34.2601 20.3125 34.6893 20.4903 35.0058 20.8068C35.3222 21.1232 35.5 21.5524 35.5 22Z"
                  fill="#007BFF"
                />
              </svg>
            </div>
            <h3 className="font-weight-bold h5 ml-2 mb-0">
              Training Duration :
            </h3>
          </div>
          <div> 2 Days (4 hours each day)</div>
        </div>
        <div className="mb-4 mt-2">
          <div className="d-flex align-items-center mb-2">
            <div>
              <svg width="25" height="25" viewBox="0 0 42 42" fill="none">
                <path
                  d="M35.5827 4.33464H31.416V2.2513C31.416 1.69877 31.1965 1.16886 30.8058 0.778163C30.4151 0.387462 29.8852 0.167969 29.3327 0.167969C28.7801 0.167969 28.2502 0.387462 27.8595 0.778163C27.4688 1.16886 27.2494 1.69877 27.2494 2.2513V4.33464H14.7493V2.2513C14.7493 1.69877 14.5299 1.16886 14.1392 0.778163C13.7485 0.387462 13.2186 0.167969 12.666 0.167969C12.1135 0.167969 11.5836 0.387462 11.1929 0.778163C10.8022 1.16886 10.5827 1.69877 10.5827 2.2513V4.33464H6.41602C4.75841 4.33464 3.1687 4.99312 1.9966 6.16522C0.824496 7.33732 0.166016 8.92703 0.166016 10.5846V35.5846C0.166016 37.2422 0.824496 38.832 1.9966 40.0041C3.1687 41.1762 4.75841 41.8346 6.41602 41.8346H35.5827C37.2403 41.8346 38.83 41.1762 40.0021 40.0041C41.1742 38.832 41.8327 37.2422 41.8327 35.5846V10.5846C41.8327 8.92703 41.1742 7.33732 40.0021 6.16522C38.83 4.99312 37.2403 4.33464 35.5827 4.33464ZM37.666 35.5846C37.666 36.1372 37.4465 36.6671 37.0558 37.0578C36.6651 37.4485 36.1352 37.668 35.5827 37.668H6.41602C5.86348 37.668 5.33358 37.4485 4.94288 37.0578C4.55218 36.6671 4.33268 36.1372 4.33268 35.5846V21.0013H37.666V35.5846ZM37.666 16.8346H4.33268V10.5846C4.33268 10.0321 4.55218 9.5022 4.94288 9.1115C5.33358 8.7208 5.86348 8.5013 6.41602 8.5013H10.5827V10.5846C10.5827 11.1372 10.8022 11.6671 11.1929 12.0578C11.5836 12.4485 12.1135 12.668 12.666 12.668C13.2186 12.668 13.7485 12.4485 14.1392 12.0578C14.5299 11.6671 14.7493 11.1372 14.7493 10.5846V8.5013H27.2494V10.5846C27.2494 11.1372 27.4688 11.6671 27.8595 12.0578C28.2502 12.4485 28.7801 12.668 29.3327 12.668C29.8852 12.668 30.4151 12.4485 30.8058 12.0578C31.1965 11.6671 31.416 11.1372 31.416 10.5846V8.5013H35.5827C36.1352 8.5013 36.6651 8.7208 37.0558 9.1115C37.4465 9.5022 37.666 10.0321 37.666 10.5846V16.8346Z"
                  fill="#007BFF"
                />
              </svg>
            </div>
            <h3 className="font-weight-bold h5 ml-2 mb-0">
              Training Outline :
            </h3>
          </div>
          <div className="row justify-content-between mt-4">
            <div className="col-md-6">
              <div className="pr-md-5 mb-5">
                <div className="mb-2 text-blue">Day</div>
                <div className="border p-3 px-md-4 py-md-4 h-100 position-relative">
                  <div className="daynumber">1</div>
                  <h5 className="my-3 font-weight-bold">
                    Understanding AI's Impact on Business
                  </h5>
                  <ul className="listdots">
                    <li>Introduction to Artificial Intelligence</li>
                    <li>
                      Case Studies : Success Stories of AI in Different
                      Industries
                    </li>
                    <li>Competitive Advantage through AI</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="pr-md-5 mb-5">
                <div className="mb-2 text-blue">Day</div>
                <div className="border p-3 px-md-4 py-md-4 h-100 position-relative">
                  <div className="daynumber">2</div>
                  <h5 className="my-3 font-weight-bold">
                    Strategic Implementation of AI
                  </h5>
                  <ul className="listdots">
                    <li>Identifying AI Opportunities in Your Business</li>
                    <li>Building an AI Strategy: First Steps</li>
                    <li>Risk Mitigation and Ethical Considerations</li>
                    <li>Q&A and Roadmap Planning Session</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderDeliveyContent = () => {
    return (
      <>
        <h1 className="h2 mb-4">AI Delivery for Middle Managers</h1>
        <h3 className="font-weight-bold h5 mb-2 mb-0">Introduction:</h3>
        <p className="mb-3">
          Designed for middle managers tasked with overseeing AI/ML projects,
          this course demystifies the AI delivery process. From
          conceptualization to deployment, learn how to navigate the
          Data2Insights Process, ensuring your projects are delivered on time,
          within scope, and beyond expectations.
        </p>
        <div className="mb-4 mt-2">
          <div className="d-flex align-items-center mb-2">
            <img src={images.aiTrainingProfile} alt="profile" />
            <h3 className="font-weight-bold h5 ml-2 mb-0">
              Who is this for ? :
            </h3>
          </div>
          <ul className="listdots">
            <li>Middle managers in project management roles</li>
            <li>Professionals overseeing AI/ML solution delivery</li>
          </ul>
        </div>
        <div className="mb-4 mt-2">
          <div className="d-flex align-items-center mb-2">
            <div>
              <svg width="25" height="25" viewBox="0 0 44 44" fill="none">
                <path
                  d="M22 0.0625C17.6612 0.0625 13.4198 1.34911 9.81219 3.75964C6.20458 6.17016 3.3928 9.59633 1.7324 13.6049C0.0720052 17.6134 -0.362431 22.0243 0.484033 26.2798C1.3305 30.5353 3.41984 34.4441 6.48786 37.5122C9.55587 40.5802 13.4648 42.6695 17.7202 43.516C21.9757 44.3624 26.3866 43.928 30.3951 42.2676C34.4037 40.6072 37.8299 37.7954 40.2404 34.1878C42.6509 30.5802 43.9375 26.3388 43.9375 22C43.9314 16.1837 41.6181 10.6074 37.5054 6.49463C33.3926 2.38188 27.8163 0.0686421 22 0.0625ZM22 40.5625C18.3287 40.5625 14.7398 39.4738 11.6872 37.4342C8.63465 35.3945 6.25545 32.4954 4.8505 29.1036C3.44554 25.7117 3.07794 21.9794 3.79418 18.3786C4.51042 14.7779 6.27833 11.4703 8.87434 8.87433C11.4704 6.27832 14.7779 4.51041 18.3786 3.79417C21.9794 3.07794 25.7117 3.44553 29.1036 4.85049C32.4954 6.25544 35.3945 8.63464 37.4342 11.6872C39.4738 14.7398 40.5625 18.3287 40.5625 22C40.5569 26.9214 38.5994 31.6396 35.1195 35.1195C31.6396 38.5994 26.9214 40.5569 22 40.5625ZM35.5 22C35.5 22.4476 35.3222 22.8768 35.0058 23.1932C34.6893 23.5097 34.2601 23.6875 33.8125 23.6875H22C21.5525 23.6875 21.1232 23.5097 20.8068 23.1932C20.4903 22.8768 20.3125 22.4476 20.3125 22V10.1875C20.3125 9.73995 20.4903 9.31073 20.8068 8.99426C21.1232 8.67779 21.5525 8.5 22 8.5C22.4476 8.5 22.8768 8.67779 23.1933 8.99426C23.5097 9.31073 23.6875 9.73995 23.6875 10.1875V20.3125H33.8125C34.2601 20.3125 34.6893 20.4903 35.0058 20.8068C35.3222 21.1232 35.5 21.5524 35.5 22Z"
                  fill="#007BFF"
                />
              </svg>
            </div>
            <h3 className="font-weight-bold h5 ml-2 mb-0">
              Training Duration :
            </h3>
          </div>
          <div> 5 Days (3 hours each day)</div>
        </div>
        <div className="mb-4 mt-2">
          <div className="d-flex align-items-center mb-2">
            <div>
              <svg width="25" height="25" viewBox="0 0 42 42" fill="none">
                <path
                  d="M35.5827 4.33464H31.416V2.2513C31.416 1.69877 31.1965 1.16886 30.8058 0.778163C30.4151 0.387462 29.8852 0.167969 29.3327 0.167969C28.7801 0.167969 28.2502 0.387462 27.8595 0.778163C27.4688 1.16886 27.2494 1.69877 27.2494 2.2513V4.33464H14.7493V2.2513C14.7493 1.69877 14.5299 1.16886 14.1392 0.778163C13.7485 0.387462 13.2186 0.167969 12.666 0.167969C12.1135 0.167969 11.5836 0.387462 11.1929 0.778163C10.8022 1.16886 10.5827 1.69877 10.5827 2.2513V4.33464H6.41602C4.75841 4.33464 3.1687 4.99312 1.9966 6.16522C0.824496 7.33732 0.166016 8.92703 0.166016 10.5846V35.5846C0.166016 37.2422 0.824496 38.832 1.9966 40.0041C3.1687 41.1762 4.75841 41.8346 6.41602 41.8346H35.5827C37.2403 41.8346 38.83 41.1762 40.0021 40.0041C41.1742 38.832 41.8327 37.2422 41.8327 35.5846V10.5846C41.8327 8.92703 41.1742 7.33732 40.0021 6.16522C38.83 4.99312 37.2403 4.33464 35.5827 4.33464ZM37.666 35.5846C37.666 36.1372 37.4465 36.6671 37.0558 37.0578C36.6651 37.4485 36.1352 37.668 35.5827 37.668H6.41602C5.86348 37.668 5.33358 37.4485 4.94288 37.0578C4.55218 36.6671 4.33268 36.1372 4.33268 35.5846V21.0013H37.666V35.5846ZM37.666 16.8346H4.33268V10.5846C4.33268 10.0321 4.55218 9.5022 4.94288 9.1115C5.33358 8.7208 5.86348 8.5013 6.41602 8.5013H10.5827V10.5846C10.5827 11.1372 10.8022 11.6671 11.1929 12.0578C11.5836 12.4485 12.1135 12.668 12.666 12.668C13.2186 12.668 13.7485 12.4485 14.1392 12.0578C14.5299 11.6671 14.7493 11.1372 14.7493 10.5846V8.5013H27.2494V10.5846C27.2494 11.1372 27.4688 11.6671 27.8595 12.0578C28.2502 12.4485 28.7801 12.668 29.3327 12.668C29.8852 12.668 30.4151 12.4485 30.8058 12.0578C31.1965 11.6671 31.416 11.1372 31.416 10.5846V8.5013H35.5827C36.1352 8.5013 36.6651 8.7208 37.0558 9.1115C37.4465 9.5022 37.666 10.0321 37.666 10.5846V16.8346Z"
                  fill="#007BFF"
                />
              </svg>
            </div>
            <h3 className="font-weight-bold h5 ml-2 mb-0">
              Training Outline :
            </h3>
          </div>
          <div className="row justify-content-between mt-4">
            <div className="col-md-6">
              <div className="pr-md-5 mb-5">
                <div className="mb-2 text-blue">Day</div>
                <div className="border p-3 px-md-4 py-md-4 h-100 position-relative">
                  <div className="daynumber">1</div>
                  <h5 className="my-3 font-weight-bold">
                    AI/ML Project Foundations
                  </h5>
                  <ul className="listdots">
                    <li>Agile Methodologies for AI/ML</li>
                    <li>Managing Teams and Resources</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="pr-md-5 mb-5">
                <div className="mb-2 text-blue">Day</div>
                <div className="border p-3 px-md-4 py-md-4 h-100 position-relative">
                  <div className="daynumber">2</div>
                  <h5 className="my-3 font-weight-bold">Planning and Design</h5>
                  <ul className="listdots">
                    <li>Setting Objectives and KPIs</li>
                    <li>Design Thinking in AI/ML Projects</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="pr-md-5 mb-5">
                <div className="mb-2 text-blue">Day</div>
                <div className="border p-3 px-md-4 py-md-4 h-100 position-relative">
                  <div className="daynumber">3</div>
                  <h5 className="my-3 font-weight-bold">
                    Strategic Implementation of AI
                  </h5>
                  <ul className="listdots">
                    <li>Agile Methodologies for AI/ML</li>
                    <li>Managing Teams and Resources</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="pr-md-5 mb-5">
                <div className="mb-2 text-blue">Day</div>
                <div className="border p-3 px-md-4 py-md-4 h-100 position-relative">
                  <div className="daynumber">4</div>
                  <h5 className="my-3 font-weight-bold">
                    Monitoring and Control
                  </h5>
                  <ul className="listdots">
                    <li>Tracking Progress and Performance</li>
                    <li>Risk Management Strategiess</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="pr-md-5 mb-5">
                <div className="mb-2 text-blue">Day</div>
                <div className="border p-3 px-md-4 py-md-4 h-100 position-relative">
                  <div className="daynumber">5</div>
                  <h5 className="my-3 font-weight-bold">Closure and Beyond</h5>
                  <ul className="listdots">
                    <li>Evaluating Project Success</li>
                    <li>Lessons Learned and Knowledge Transfer</li>
                    <li>Interactive Q&A Session</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderTrainingContent = () => {
    return (
      <div className="tab-content mb-5">
        <div
          className="tab-pane fade show active"
          id="AIPrimer"
          role="tabpanel"
          aria-labelledby="AIPrimer-tab"
        >
          {renderPrimerContent()}
        </div>

        <div
          className="tab-pane fade show"
          id="AIDelivery"
          role="tabpanel"
          aria-labelledby="AIDelivery-tab"
        >
          {renderDeliveyContent()}
        </div>

        <div
          className="tab-pane fade show"
          id="AIUltimate"
          role="tabpanel"
          aria-labelledby="AIUltimate-tab"
        >
          {renderUltimateContent()}
        </div>
      </div>
    );
  };
  
  const renderTraining = () => {
    return (
      <div className="popuptabs mb-3 bo">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              id="AIPrimer-tab"
              data-toggle="tab"
              href="#AIPrimer"
              role="tab"
              aria-controls="AIPrimer"
              aria-selected="true"
            >
              AI Primer
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="AIDelivery-tab"
              data-toggle="tab"
              href="#AIDelivery"
              role="tab"
              aria-controls="AIDelivery"
              aria-selected="false"
            >
              AI Delivery
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="AIUltimate-tab"
              data-toggle="tab"
              href="#AIUltimate"
              role="tab"
              aria-controls="AIUltimate"
              aria-selected="false"
            >
              AI Ultimate
            </a>
          </li>
        </ul>
      </div>
    );
  };
  const render = () => {
    return (
      <div className="px-5 py-3 mt-5">
        <h3 className="mb-3">AI Training</h3>
        {renderTraining()}
        {renderTrainingContent()}
      </div>
    );
  };
  return render();
};

export default AITraining;
