import React from "react";
import constant from "../../helper/constant";
import { useAuthContext } from "../../context/auth.context";
import { useAppContext } from "../../context/app.context";
import { useScreenSizeContext } from "../../context/mobile-layout.context";

const Footer = () => {
  const year = new Date().getFullYear();
  const { isLoggedIn } = useAuthContext();
  const { showLeftMenu } = useAppContext();
  const { isMobileScreen } = useScreenSizeContext();

  const renderContact =()=>{
    return (
      <div
          className={`${isLoggedIn ? (showLeftMenu ? "p-l-35" : "p-l-190") : ""} ${isMobileScreen ? "px-1 py-1 d-flex justify-content-between" : ""}`}
        >
          {!isMobileScreen && <>Contact us:{" "}</>}
          <a href={`tel:${constant.footerConfig.contactUs}`}>
            {constant.footerConfig.contactUs}
          </a>{" "}
          |{" "}
          <a
            href={`mailto:${constant.footerConfig.contactEmail}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {constant.footerConfig.contactEmail}
          </a>
        </div>
    )
  }
  return (
    <footer className={`copyright ${isMobileScreen ? "px-1" : "px-5"}`}>
      <div className={`${isMobileScreen? '':'d-flex justify-content-between align-items-center'}`}>
        {renderContact()}
        {!isMobileScreen && (
          <div className="">
            <a href={constant.footerConfig.incorpAi} target="_blank">
              Incorporation.AI
            </a>
            <span className="px-2">|</span>
            <a href={constant.footerConfig.privacyPolicy} target="_blank">
              Privacy Policy
            </a>
            <span className="px-2">|</span>
            <a href={constant.footerConfig.termsAndConditions} target="_blank">
              Terms & Conditions
            </a>
            <span className="px-2">|</span>
            <a href={constant.footerConfig.copyWrite} target="_blank">
              {" "}
              &copy; {year} All rights reserved
            </a>
          </div>
        )}
      </div>
    </footer>
  );
};
export default Footer;
