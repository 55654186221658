import React, { createContext, useContext } from "react";
import * as ACTION from "./../reducers/types";
import useAIAskMeReducer from "../reducers/ai-ask-me.reducer";
import {
  getChatboxQuestion,
  getTaskForQuery,
  getWorkflowForQuery,
} from "../service/ai-model.service";
import { useAppContext } from "./app.context";
import { useAuthContext } from "./auth.context";
import constant, { ToastType } from "../helper/constant";

const AIAskMeContext = createContext();

const AIAskMeProvider = ({ children }) => {
  const { state, dispatch } = useAIAskMeReducer();
  const { updateLoader, showAlert } = useAppContext();
  const { userInfo } = useAuthContext();

  const getQueryHistory = () => {
    updateLoader(true);
    getChatboxQuestion()
      .then((response) => {
        if (response && response.result)
          dispatch({
            type: ACTION.UPDATE_QUERY_HISTORY,
            payload: response.result.slice(0, 10),
          });
      })
      .finally(() => updateLoader(false));
  };

  const getAnswerForQuery = (chatGpt, cb) => {
    const {
      org: { org_config },
    } = userInfo;
    let payload = {
      employees: org_config.empCount,
      revenue: org_config.revenue,
      query: state.currentQuery,
      chatGpt,
    };
    dispatch({ type: ACTION.UPDATE_CURRENT_QUERY_TASKS, payload: [] });
    dispatch({ type: ACTION.UPDATE_CURRENT_QUERY_WORKFLOWS, payload: [] });
    getAskMeTaskForQuery(payload, cb);
    getAskMeWorkflowForQuery(payload, cb);
  };

  const getAskMeTaskForQuery = (data, cb) => {
    getTaskForQuery(data)
      .then((payload) => {
        dispatch({ type: ACTION.UPDATE_CURRENT_QUERY_TASKS, payload });
      })
      .catch((ex) => handleError(ex))
      .finally(() => cb());
  };

  const getAskMeWorkflowForQuery = (data, cb) => {
    getWorkflowForQuery(data)
      .then((payload) => {
        dispatch({
          type: ACTION.UPDATE_CURRENT_QUERY_WORKFLOWS,
          payload,
        });
      })
      .catch((ex) => handleError(ex))
      .finally(() => cb());
  };

  const updateCurrentQuery = (payload) =>
    dispatch({ type: ACTION.UPDATE_CURRENT_QUERY, payload });

  const updateShowModal = (payload) =>
    dispatch({ type: ACTION.UPDATE_SHOW_MODAL, payload });

  const updateAskMeSelectedModels = (payload) =>
    dispatch({ type: ACTION.UPDATE_ASK_ME_SELECTED_MODEL, payload });

  const updateQueryTasks = (payload) =>
    dispatch({ type: ACTION.UPDATE_CURRENT_QUERY_TASKS, payload });

  const clearAskMe = () => dispatch({ type: ACTION.CLEAR_ASK_ME });

  const handleError = (ex) => {
    showAlert(
      ex?.message || constant.MESSAGES.COMMON_ERROR_MESSAGE,
      ToastType.ERROR,
    );
  };
  return (
    <AIAskMeContext.Provider
      value={{
        ...state,
        getQueryHistory,
        updateCurrentQuery,
        getAnswerForQuery,
        updateShowModal,
        updateQueryTasks,
        updateAskMeSelectedModels,
        clearAskMe,
      }}
    >
      {children}
    </AIAskMeContext.Provider>
  );
};

export const useAIAskMeContext = () => useContext(AIAskMeContext);

export { AIAskMeProvider, AIAskMeContext };
