import React from "react";
import {
  Brush,
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { stringToHslColor } from "../../../helper/helper";

const PredLineChart = (props) => {
  const { config } = props;
  const COLORS = ["#8884d8", "#82ca9d", "#0088FE", "#FF8042"];
  const renderXAxis = () => <XAxis dataKey={config.datakeys[0]} />;

  const renderYAxis = () => {
    return <YAxis />;
  };

  const renderLegend = () => {
    return <Legend verticalAlign="bottom" height={36} />;
  };

  const renderChartData = () => {
    if (config.datakeys && config.datakeys.length) {
      return config.datakeys.map((item, index) => {
        const backgroundColor = COLORS[index];
        if (index > 0)
          return (
            <Line
              key={index}
              type="basis"
              dataKey={item}
              stroke={backgroundColor}
              dot={false}
            />
          );
      });
    }
  };

  const render = () => {
    if (config && config.data && config.data.length)
      return (
        <div className="d-flex flex-column gap-1 text-center">
          <h4 className="capitalize">{config.title}</h4>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              width={500}
              height={300}
              data={config.data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              {renderXAxis()}
              {renderYAxis()}
              {config.tooltip ? (
                <Tooltip content={config.tooltip} />
              ) : (
                <Tooltip />
              )}
              {renderLegend()}
              {renderChartData()}
              <Brush />
            </LineChart>
          </ResponsiveContainer>
        </div>
      );
    // return <p>No data found</p>;
  };
  return render();
};

export default PredLineChart;
