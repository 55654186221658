import React from "react";
import AISearchBox from "../widgets/AISearchBox";
import SentimentBox from "../widgets/SentimentBox";
import WidgetContainer from "../widgets/WidegtContainer";

const SocialHome = () => {
  const render = () => {
    return (
      <div className="social-media-prediction">
        <AISearchBox />
        <SentimentBox />
        <WidgetContainer />
      </div>
    );
  };
  return render();
};
export default SocialHome;
