import React, { useEffect, useRef, useState } from "react";
import { Rnd } from "react-rnd";
import constant from "../../helper/constant";
import { Link } from "react-router-dom";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const ImageSelector = (props) => {
  const colors = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#FF00FF",
    "#FF0000",
  ];
  const minWidth = 50,
    minHeight = 50;
  const defaultCords = {
    width: minWidth,
    height: minHeight,
    x: 10,
    y: 10,
  };
  const { src, alt, onDone } = props;
  const imgRef = useRef(null);
  const [properties, setProperties] = useState({
    aspectRation: 0,
    width: 0,
    height: 0,
    newWidth: 0,
    newHeight: 0,
  });
  const [coOrdinates, setCoOrdinates] = useState([defaultCords]);

  useEffect(() => {
    if (!props.cords) setCoOrdinates([defaultCords]);
  }, [src]);

  useEffect(() => {
    if (props.cords) setCoOrdinates(props.cords);
  }, [props.cords]);

  const renderImageSelectorBox = () => {
    if (coOrdinates.length) {
      return coOrdinates.map((coordinate, index) => {
        const { x, y, width, height } = coordinate;
        const border = `1px solid ${colors[index]}`;
        const backgroundColor = `${colors[index]}44`;
        return (
          <Rnd
            key={`rnd-${index}`}
            bounds="parent"
            className={`selection-box`}
            style={{ backgroundColor, border }}
            size={{ width, height }}
            position={{ x, y }}
            onDragStop={(e, d) => {
              const list = coOrdinates.slice();
              list[index] = {
                ...list[index],
                x: d.x.toFixed(0),
                y: d.y.toFixed(0),
              };
              setCoOrdinates(list);
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              const list = coOrdinates.slice();
              list[index] = {
                width: ref.style.width,
                height: ref.style.height,
                x: position.x.toFixed(0),
                y: position.y.toFixed(0),
              };
              setCoOrdinates(list);
            }}
          ></Rnd>
        );
      });
    }
  };

  useEffect(() => {
    if (imgRef.current) {
      const width = imgRef.current.naturalWidth;
      const height = imgRef.current.naturalHeight;
      const aspectRatio = Number(width / height);
      const maxWidth = constant.imageNormalization.width;
      const maxHeight = constant.imageNormalization.height;

      // Calculate width and height based on the aspect ratio
      let newWidth, newHeight;
      // If both maxWidth and maxHeight are provided, fit the image within the bounding box
      if (aspectRatio > 1) {
        newWidth = maxWidth;
        newHeight = maxWidth / aspectRatio;
      } else {
        newWidth = maxHeight * aspectRatio;
        newHeight = maxHeight;
      }

      setProperties({ aspectRatio, newWidth, newHeight, width, height });
    }
  }, [imgRef.current]);

  const renderSelectedCordinates = () => {
    if (coOrdinates.length) {
      return coOrdinates.map((coordinate, index) => {
        const border = `1px solid ${colors[index]}`;
        const backgroundColor = `${colors[index]}44`;
        return (
          <div
            className="coordinates d-flex gap-1 align-items-center justify-content-between"
            key={`rnd-${index}`}
          >
            <div className="coordinates d-flex gap-1">
              <div
                className="image-select-indicator"
                style={{ border, backgroundColor }}
              ></div>
              <div className="d-flex gap-1">
                <p className="p-0">X: {coordinate.x}</p>
                <p className="p-0">Y: {coordinate.y}</p>
                <p className="p-0">Width: {coordinate.width}</p>
                <p className="p-0">Height: {coordinate.height}</p>
              </div>
            </div>
            <Link
              onClick={() => {
                const list = coOrdinates.slice();
                list.splice(index, 1);
                setCoOrdinates(list);
              }}
            >
              <FontAwesomeIcon className="text-danger" icon={faTrashCan} />
            </Link>
          </div>
        );
      });
    }
  };

  const render = () => {
    return (
      <div className="d-flex gap-2">
        <div className="object-container">
          <img
            ref={imgRef}
            className="image-container"
            src={src}
            alt={alt}
            style={{
              width: properties.newWidth,
              height: properties.newHeight,
            }}
          />
          {renderImageSelectorBox()}
        </div>
        <div className="d-flex flex-column gap-2">
          {renderSelectedCordinates()}
          <div className="d-flex gap-2">
            <button
              type="button"
              className="btn1 btn radious-0 w-100 mt-2 f-13 text-uppercase py-2 font15fontweight600"
              onClick={() => {
                setCoOrdinates([...coOrdinates, defaultCords]);
              }}
              disabled={coOrdinates.length >= constant.maxImageSelector}
            >
              Add
            </button>
            <button
              type="button"
              className="btn1 btn radious-0 w-100 mt-2 f-13 text-uppercase py-2 font15fontweight600"
              onClick={() => onDone(coOrdinates, properties)}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    );
  };
  return render();
};

export default ImageSelector;
