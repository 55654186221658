import React, { useState } from "react";
import NewLabelForm from "./NewLabelForm";
import { useICContext } from "../../../../../context/ai-ic.context";
import { Link, useParams } from "react-router-dom";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import { submitMultiUploadRequest } from "../../../../../service/ai-model.service";
import {
  generateCsvBlob,
  getImageStoreHost,
  getUUID,
} from "../../../../../helper/helper";
import { useAppContext } from "../../../../../context/app.context";
import { ToastType } from "../../../../../helper/constant";
import { useAuthContext } from "../../../../../context/auth.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faClose,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

const ImageClassificationForm = () => {
  const { icList, setICList } = useICContext();
  const [read, setRead] = useState();
  const {
    aiDataSelectedForm,
    saveAIModelForm,
    hideRightMenu,
    switchToSavedModel,
  } = useAIModelContext();
  const { updateLoader, showAlert } = useAppContext();
  const { userInfo } = useAuthContext();
  const params = useParams();

  const submitUploadRequest = () => {
    const { modelId } = aiDataSelectedForm;
    const formData = new FormData();
    formData.append("templateId", params.id);
    formData.append("modelId", modelId);
    icList.forEach((item) =>
      item.imageList.forEach(({ file }) => formData.append("csv", file)),
    );
    submitMultiUploadRequest(formData).then(console.log).catch(console.log);
  };

  const submitTrainingData = () => {
    const { id, modelId, model_name, parentId } = aiDataSelectedForm;
    const csvData = generateCSVData();
    const blob = generateCsvBlob(csvData);
    const formData = new FormData();
    formData.append("templateId", params.id);
    formData.append("modelId", modelId);
    formData.append("id", id);
    formData.append("csv", blob, `${getUUID()}.csv`);
    console.log(formData);
    updateLoader(true);
    saveAIModelForm(formData, (data) =>
      switchToSavedModel({ ...data, model_name, parentId }),
    );
  };

  const generateCSVData = () => {
    const { modelId } = aiDataSelectedForm;
    const {
      org: { org_name },
    } = userInfo;
    const templateId = params.id;
    const csvData = [];
    icList.map((item) =>
      item.imageList.map((fileData) => {
        csvData.push({
          "image-url": `${getImageStoreHost()}${org_name}//${templateId}/${modelId}/${fileData.file.name}`,
          "image-label": item.feature,
        });
      }),
    );
    return csvData;
  };

  const renderLabelList = () => {
    return icList.map((item, key) => {
      return <NewLabelForm key={key} featureIndex={key} featureItem={item} />;
    });
  };

  const renderActionButtons = () => {
    if (read)
      return (
        <>
          <div className="form-group">
            <button
              type="button"
              className="btn1"
              onClick={(e) => {
                e.preventDefault();
                setRead(false);
              }}
            >
              {" "}
              change
            </button>
          </div>
          <div className="form-group">
            <button
              type="button"
              className="btn1"
              onClick={() => hideRightMenu()}
            >
              close
            </button>
          </div>
        </>
      );

    return (
      <>
        <div className="form-group">
          <button
            type="submit"
            className="btn1 btn radious-0 w-100 mt-2 f-13 text-uppercase py-2 font15fontweight600"
          >
            save
          </button>
        </div>
        <div className="form-group">
          <button
            type="button"
            className="btn1 btn radious-0 w-100 mt-2 f-13 text-uppercase py-2 bg-light-grey font15fontweight600"
            onClick={() => hideRightMenu()}
          >
            cancel
          </button>
        </div>
      </>
    );
  };

  const addNewLabel = () => {
    const list = icList.slice();
    setICList([...list, {}]);
  };

  const submitICTrainingData = () => {
    if (icList.length) {
      submitUploadRequest();
      submitTrainingData();
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    submitICTrainingData();
  };

  const renderModelDataForm = () => {
    return (
      <>
        {renderLabelList()}
        <div className="form-group p-3">
          <button
            type="button"
            className="btn1 btn radious-0 w-100 mt-2 f-13 text-uppercase py-2 font15fontweight600"
            onClick={() => addNewLabel()}
          >
            Add Label
          </button>
        </div>
      </>
    );
  };
  const render = () => {
    return (
      <>
        <form className="sm-form" onSubmit={onFormSubmit}>
          {renderModelDataForm()}
          <div className="form-group p-3">{renderActionButtons()}</div>
        </form>
      </>
    );
  };

  return render();
};

export default ImageClassificationForm;
