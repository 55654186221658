import React from "react";
import images from "../../../../../helper/images";

const GoogleReviewWidget = () => {
  const render = () => {
    return (
      <div className="col-md-4 mt-3 fadeUp">
        <div className="d-flex align-items-center">
          <svg width="27" height="30" viewBox="0 0 37 40" fill="none">
            <g clipPath="url(#clip0_84_56)">
              <path
                d="M36.9324 20.3742C36.9324 18.7355 36.8067 17.5396 36.5346 16.2994H18.843V23.6961H29.2276C29.0183 25.5342 27.8877 28.3025 25.3752 30.1626L25.34 30.4103L30.9338 34.9939L31.3213 35.0349C34.8805 31.5579 36.9324 26.4422 36.9324 20.3742Z"
                fill="#4285F4"
              />
              <path
                d="M18.843 39.8626C23.9306 39.8626 28.2016 38.0908 31.3213 35.0348L25.3752 30.1626C23.7841 31.3363 21.6485 32.1557 18.843 32.1557C13.8601 32.1557 9.63087 28.6789 8.12328 23.8733L7.9023 23.8931L2.08583 28.6545L2.00977 28.8782C5.10838 35.389 11.4732 39.8626 18.843 39.8626Z"
                fill="#34A853"
              />
              <path
                d="M8.12339 23.8733C7.7256 22.6331 7.49539 21.3043 7.49539 19.9313C7.49539 18.5582 7.7256 17.2295 8.10247 15.9893L8.09193 15.7252L2.20257 10.8873L2.00988 10.9843C0.732796 13.6861 0 16.7202 0 19.9313C0 23.1424 0.732796 26.1763 2.00988 28.8782L8.12339 23.8733Z"
                fill="#FBBC05"
              />
              <path
                d="M18.843 7.70672C22.3813 7.70672 24.768 9.32336 26.129 10.6744L31.4469 5.18214C28.1808 1.97099 23.9306 0 18.843 0C11.4732 0 5.10838 4.47343 2.00977 10.9843L8.10235 15.9893C9.63087 11.1837 13.8601 7.70672 18.843 7.70672Z"
                fill="#EB4335"
              />
            </g>
            <defs>
              <clipPath id="clip0_84_56">
                <rect width="36.9501" height="40" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <h3 className="ms-3">Google Review Summary</h3>
        </div>
        <div className="mt-4">
          <img
            src={images.socialMediaResult.googleReview}
            className="w-100"
            alt=""
          />
        </div>
      </div>
    );
  };

  return render();
};

export default GoogleReviewWidget;
