import React from "react";

const CustomLoader = (props) => {
  const { full, center, masked, size, color } = props;
  return (
    <div
      className={`custom-loader ${full ? "loader-center" : ""} ${masked ? "masked" : ""}`}
    >
      <div
        className={`spinner-border text-blue ${masked ? "text-primary" : ""}  ${size ? `spinner-border-${size}` : ""}`}
        style={{ color }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default CustomLoader;
