import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  convertToArrayOfObjects,
  getHighestByParameter,
} from "../../../../../helper/helper";

const ImagePredictionGrid = (props) => {
  const { imageList = [] } = props;
  const getRandomVariant = () => {
    const variants = ["success", "danger"]; // List of available variant colors
    const randomIndex = Math.floor(Math.random() * variants.length);
    return variants[randomIndex];
  };
  const renderImageGrid = () => {
    if (imageList.length) {
      return imageList.map((image, key) => {
        const { src, prediction = {} } = image;
        const predictionArray = convertToArrayOfObjects(prediction);
        const expectedPred = getHighestByParameter(predictionArray, "value");
        if (src)
          return (
            <div key={key} className="col-xs-6 col-md-4 col-lg-2 ai-image-grid">
              <img
                src={`data:image/png;base64,${src}`}
                alt="aiimage"
                className="ai-image w-100"
              />
              <p className="m-0 py-1">
                <b>Label</b>:{" "}
                <span className="text-uppercase">{expectedPred.key}</span>
              </p>
              <ProgressBar
                className="mb-2"
                key={1}
                striped
                label={`${expectedPred.value}%`}
                now={expectedPred.value}
              />
            </div>
          );
      });
    }
    return <p className="text-center">Loading...</p>;
  };
  const render = () => {
    return (
      <div className="ai-image-prediction-result row">{renderImageGrid()}</div>
    );
  };

  return render();
};

export default ImagePredictionGrid;
