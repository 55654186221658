import React, { useEffect, useState } from "react";
import { useAIModelContext } from "../../../context/ai-model.context";
import RecentModelItem from "./RecentModelItem";
import { TemplateStatus } from "../../../helper/constant";
import { useScreenSizeContext } from "../../../context/mobile-layout.context";

const RecentModels = () => {
  const { templates } = useAIModelContext();
  const [keyword, setKeyword] = useState("");
  const [templateList, setTemplateList] = useState([]);
  const { isMobileScreen } = useScreenSizeContext();

  useEffect(() => {
    if (templates) setTemplateList([...templates]);
  }, [templates]);

  useEffect(() => {
    if (keyword) {
      const temps = [...templates].filter(
        (zz) =>
          zz.template_name?.toLowerCase().includes(keyword.toLowerCase()) ||
          zz.status?.toLowerCase().includes(keyword.toLowerCase())
      );
      setTemplateList(temps);
    } else setTemplateList(templates);
  }, [keyword, templates]);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const render = (list) => {
    return list.map((template) => (
      <RecentModelItem key={template.id} template={template} />
    ));
  };

  const renderNewModels = () => {
    const list = templateList.filter(
      (zz) =>
        zz.status === TemplateStatus.DRAFT || zz.status === TemplateStatus.NEW
    );
    if (list.length)
      return (
        <>
          <h4 className="subheading1 position-relative pb-3 my-5 text-center left-42">
            Draft
          </h4>
          <div className="row">{render(list)}</div>
        </>
      );
  };

  const renderScheduledModels = () => {
    const list = templateList.filter(
      (zz) => zz.status === TemplateStatus.SCHEDULED
    );
    if (list.length)
      return (
        <>
          <h3 className="subheading2 position-relative pb-3 my-5 text-center">
            Scheduled
          </h3>
          <div className="row mb-3">{render(list)}</div>
        </>
      );
  };

  const renderCompletedModels = () => {
    const list = templateList.filter(
      (zz) => zz.status === TemplateStatus.COMPLETED
    );
    if (list.length)
      return (
        <>
          <h4 className="subheading3 position-relative pb-3 my-5 text-center">
            Completed
          </h4>
          <div className="row">{render(list)}</div>
        </>
      );
  };
  return (
    <>
      <div
        className={`d-flex justify-content-between align-items-center gap-2 ${isMobileScreen ? "flex-column" : ""}`}
      >
        <h3>Recent Workflows</h3>
        <div
          className={`form-group mb-0 d-flex align-items-center form-control ${isMobileScreen ? "" : "align-align-self-end w-30"} ${isFocused ? "form-control-focus" : ""}`}
        >
          <input
            type="text"
            className="border-0 w-100 px-2 bg-transparent"
            placeholder="Search"
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <svg width="18" height="18" viewBox="0 0 20 20" fill="none">
            <path
              d="M19 19L13 13M1 8C1 8.91925 1.18106 9.82951 1.53284 10.6788C1.88463 11.5281 2.40024 12.2997 3.05025 12.9497C3.70026 13.5998 4.47194 14.1154 5.32122 14.4672C6.1705 14.8189 7.08075 15 8 15C8.91925 15 9.82951 14.8189 10.6788 14.4672C11.5281 14.1154 12.2997 13.5998 12.9497 12.9497C13.5998 12.2997 14.1154 11.5281 14.4672 10.6788C14.8189 9.82951 15 8.91925 15 8C15 7.08075 14.8189 6.1705 14.4672 5.32122C14.1154 4.47194 13.5998 3.70026 12.9497 3.05025C12.2997 2.40024 11.5281 1.88463 10.6788 1.53284C9.82951 1.18106 8.91925 1 8 1C7.08075 1 6.1705 1.18106 5.32122 1.53284C4.47194 1.88463 3.70026 2.40024 3.05025 3.05025C2.40024 3.70026 1.88463 4.47194 1.53284 5.32122C1.18106 6.1705 1 7.08075 1 8Z"
              stroke="#474646"
              strokeOpacity="0.5"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      {!isMobileScreen && renderNewModels()}
      {!isMobileScreen && renderScheduledModels()}
      {renderCompletedModels()}
    </>
  );
};

export default RecentModels;
