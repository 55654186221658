import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../context/auth.context";
import { useAIModelContext } from "../../../context/ai-model.context";
import images from "../../../helper/images";

const ProfilePage = () => {
  const {} = useAuthContext();
  const {} = useAIModelContext();
  const { userInfo } = useAuthContext();
  const [edit, setEdit] = useState(false);
  const [form, setForm] = useState({
    fields: {
      org_email: userInfo.org.org_email,
      org_name: userInfo.org.org_name,
      address: userInfo.org.address,
    },
    errors: false,
  });

  const handleFormChange = (key, value) => {
    const { fields } = form;
    fields[key] = value;
    console.log({ fields });
    setForm({ ...form.errors, fields: { ...fields } });
  };

  const renderCompanyInfo = () => {
    return (
      <>
        <div className="d-flex align-items-center mb-5 justify-content-between borderbottom">
          <h4 className="text-blue pb-2 font-weight-bold ">
            Company Information
          </h4>
          <Link onClick={() => setEdit(!edit)}>
            <span className="c-link text-right d-block cursor-pointer text-blue js-toggleForm">
              {edit ? "Save" : "Edit"}
            </span>
          </Link>
        </div>
        <div className="form formEit2">
          <div className="row align-items-center">
            <div className="col-md-2">
              <div className="font-weight-bold">Company Name</div>
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                value={form.fields.org_name}
                onChange={(e) => handleFormChange("org_name", e.target.value)}
                readOnly
              />
            </div>
          </div>
          <div className="row align-items-center mt-3">
            <div className="col-md-2">
              <div className="font-weight-bold">Email</div>
            </div>
            <div className="col-md-6">
              <input
                type="email"
                className="form-control"
                value={form.fields.org_email}
                onChange={(e) => handleFormChange("org_email", e.target.value)}
                readOnly
              />
            </div>
          </div>
          <div className="row align-items-center mt-3">
            <div className="col-md-2">
              <div className="font-weight-bold">Address</div>
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleFormChange("address", e.target.value)}
                value={form.fields.address}
                readOnly={!edit}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderCustomerInfo = () => {
    const { user: { displayName, email } = {} } = userInfo;
    return (
      <>
        <h4 className="text-blue pb-2 my-5 font-weight-bold borderbottom">
          {" "}
          Customer Information
        </h4>

        <div className="row align-items-center">
          <div className="col-md-2">
            <div className="font-weight-bold">First Name</div>
          </div>
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              defaultValue={displayName || email.split("@")[0]}
              readOnly
            />
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col-md-2">
            <div className="font-weight-bold">Last Name</div>
          </div>
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              defaultValue={displayName}
              readOnly
            />
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col-md-2">
            <div className="font-weight-bold">Email</div>
          </div>
          <div className="col-md-6">
            <input
              type="email"
              className="form-control"
              value={email}
              readOnly
            />
          </div>
        </div>
        {/* <div className="row align-items-center mt-3">
                    <div className="col-md-2">
                        <div className="font-weight-bold">Address</div>
                    </div>
                    <div className="col-md-6">
                        <input type="text" className="form-control" value={} readOnly />
                    </div>
                </div> */}
      </>
    );
  };

  const renderBusinessInfo = () => {
    return (
      <>
        <h4 className="text-blue pb-2 mt-5 font-weight-bold borderbottom">
          Business Plan
        </h4>
        <div className="row align-items-center mt-3">
          <div className="col-md-2">
            <div className="font-weight-bold">AI Strategy</div>
          </div>
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              value="$ 500 /model/month"
              readOnly
            />
          </div>
        </div>
      </>
    );
  };

  const renderCreditCardInfo = () => {
    return (
      <>
        <h4 className="text-blue pb-2 my-5 font-weight-bold borderbottom">
          Credit Card Information
        </h4>

        <div>
          <img src={images.visa} width="50px" alt="visa" />
        </div>

        <div className="row align-items-center mt-3">
          <div className="col-md-2">
            <div className="font-weight-bold">Card No. </div>
          </div>
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              value="43XX-XXXX-XXXX-7192"
              readOnly
            />
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col-md-2">
            <div className="font-weight-bold">Address</div>
          </div>
          <div className="col-md-6">
            <input type="text" className="form-control" value="--" readOnly />
          </div>
        </div>
      </>
    );
  };
  const render = () => {
    return (
      <div className="container">
        <div className="formsectionbox p-5">
          {renderCompanyInfo()}
          {renderCustomerInfo()}
          {/* {renderBusinessInfo()} */}
          {/* {renderCreditCardInfo()} */}
        </div>
      </div>
    );
  };
  return render();
};

export default ProfilePage;
