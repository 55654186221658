import React from "react";
import {
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getDynamicColor, stringToHslColor } from "../../../helper/helper";

const PredScatterChart = (props) => {
  const { config } = props;

  const renderXAxis = () => {
    return (
      <XAxis
        type="number"
        dataKey={config.datakeys[0]}
        name={config.labels[0]}
        domain={config.domains?.x || ["auto"]}
      >
        <Label value={config.labels[0]} offset={-15} position="insideBottom" />
      </XAxis>
    );
  };

  const renderYAxis = () => {
    return (
      <YAxis
        yAxisId="left"
        type="number"
        domain={config.domains?.y || ["auto"]}
        dataKey={config.datakeys[1]}
        name={config.labels[1]}
        stroke="#8884d8"
      >
        <Label
          value={config.labels[1]}
          offset={-15}
          angle={-90}
          position="insideLeft"
        />
      </YAxis>
    );
  };

  const renderLegend = () => {
    return <Legend verticalAlign="top" height={36} />;
  };

  const renderScatterData = () => {
    const backgroundColor = stringToHslColor(config.labels[0]);
    return (
      <Scatter
        yAxisId="left"
        name={config.labels[1]}
        data={config.data}
        fill={backgroundColor}
      />
    );
  };

  const render = () => {
    if (config && config.data && config.data.length)
      return (
        <div className="d-flex flex-column gap-1 text-center">
          <ResponsiveContainer width="100%" height={400}>
            <ScatterChart
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid />
              {renderLegend()}
              {renderXAxis()}
              {renderYAxis()}
              {config.tooltip ? (
                <Tooltip content={config.tooltip} />
              ) : (
                <Tooltip cursor={{ strokeDasharray: "3 3" }} />
              )}
              {renderScatterData()}
            </ScatterChart>
          </ResponsiveContainer>
          <h4 className="">{config.title}</h4>
        </div>
      );
    return <p>No data found</p>;
  };

  return render();
};

export default PredScatterChart;
