import React from "react";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const EvaluateBarChart = (props) => {
  const COLORS = ["#B4D3F3", "#6FA7E0", "#264C7A", "#638EBA"];
  const {
    chart: {
      data,
      layout = "horizontal",
      x,
      y,
      width = 400,
      height = 400,
    } = {},
    customTooltip,
  } = props;

  const render = () => {
    if (data && data.length)
      return (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            layout={layout}
            width={width}
            height={height}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <Legend verticalAlign="bottom" />
            <XAxis
              dataKey={x.key}
              type={x.type}
              domain={x.domain}
              hide={x.hide}
            />
            <YAxis
              domain={y.domain}
              type={y.type}
              dataKey={y.key}
              hide={y.hide}
            />
            {customTooltip ? <Tooltip content={customTooltip} /> : <Tooltip />}
            {renderBar(x.key, COLORS[2])}
            {x.key1 && renderBar(x.key1, COLORS[1])}
          </BarChart>
        </ResponsiveContainer>
      );
    return <p>No data found</p>;
  };

  const renderBar = (key, color = "") => {
    return (
      <Bar
        dataKey={key}
        fill={color}
        barSize={30}
        background={{ fill: "#eee" }}
        stackId={key}
      ></Bar>
    );
  };

  return render();
};

export default EvaluateBarChart;
