import React, { useState } from "react";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import { useParams } from "react-router-dom";
import { submitMultiUploadRequest } from "../../../../../service/ai-model.service";
import { useAppContext } from "../../../../../context/app.context";
import {
  generateCsvBlob,
  getCoordinates,
  getImageStoreHost,
  getUUID,
} from "../../../../../helper/helper";
import { useAuthContext } from "../../../../../context/auth.context";
import ImageSelector from "../../../../common/ImageSelector";

const ImageExtractTraining = (props) => {
  const { mainImage, fileList, onCompleted, onImageProcessed } = props;
  const { aiDataSelectedForm, saveAIModelForm, switchToSavedModel } =
    useAIModelContext();
  const { updateLoader, showAlert } = useAppContext();
  const { userInfo } = useAuthContext();
  const [lblTag, setLblTag] = useState("");
  const [form, setForm] = useState({ value: {}, error: false });

  const params = useParams();

  const renderMainImage = () => {
    return (
      <ImageSelector
        src={mainImage.dataURL}
        alt={mainImage.file.name}
        style={{ width: "100%", height: 400 }}
        cords={mainImage.cords}
        onDone={(cords, properties) =>
          onImageProcessed({ ...mainImage, cords, properties })
        }
      />
    );
  };

  const submitUploadRequest = () => {
    const { modelId } = aiDataSelectedForm;
    const formData = new FormData();
    formData.append("templateId", params.id);
    formData.append("modelId", modelId);
    fileList.forEach(({ file }) => formData.append("csv", file));
    submitMultiUploadRequest(formData).then().catch(console.log);
  };

  const submitTrainingData = () => {
    const { id, modelId, model_name, parentId } = aiDataSelectedForm;
    const csvData = generateCSVData(fileList);
    const blob = generateCsvBlob(csvData);
    const formData = new FormData();
    formData.append("templateId", params.id);
    formData.append("modelId", modelId);
    formData.append("id", id);
    formData.append("csv", blob, `${getUUID()}.csv`);
    updateLoader(true);
    saveAIModelForm(formData, (data) => {
      switchToSavedModel({ ...data, model_name, parentId });
      if (onCompleted) onCompleted();
    });
  };
  const generateCSVData = () => {
    const { modelId } = aiDataSelectedForm;
    const {
      org: { org_name },
    } = userInfo;
    const templateId = params.id;
    const csvData = fileList.flatMap((fileData) => {
      return fileData.cords.map((cord) => {
        return {
          "image-url": `${getImageStoreHost()}${org_name}/${templateId}/${modelId}/${fileData.file.name}`,
          label: lblTag,
          ...getCoordinates(cord, fileData.properties),
        };
      });
    });
    return csvData;
  };

  const validateForm = () => {
    let formIsValid = true;
    let error = {};
    if (!lblTag) {
      formIsValid = false;
      error["tag"] = "Tag required.";
    }
    setForm({ error });
    return formIsValid;
  };

  const onSubmitChanges = () => {
    const list = fileList.slice();
    if (list.length && validateForm()) {
      submitUploadRequest();
      submitTrainingData();
    }
  };

  const renderTagLabelForm = () => {
    return (
      <div className="align-self-stretch">
        <div className="h6 f-13">
          <b>
            Tag name <span className="text-danger">*</span>
          </b>
        </div>
        <input
          type="text"
          className={`form-control ${form.error["tag"] ? "error-br" : ""}`}
          placeholder="Please enter label name"
          value={lblTag}
          onChange={(e) => setLblTag(e.target.value)}
          name="tag"
        />
        {form.error["tag"] && (
          <small className="text-danger">{form.error["tag"]}</small>
        )}
      </div>
    );
  };

  const render = () => {
    const enableSubmit = fileList.filter(
      (item) => item.cords && item.cords.length,
    );
    if (mainImage)
      return (
        <>
          <div className="d-flex gap-1 flex-column my-3">
            {renderMainImage()}
            <p className="m-0 p-0">
              *Use selection box to highlight area of interest.(Resizable)
            </p>
          </div>
          <button
            className="btn1 btn radious-0 mt-2 f-13 text-uppercase py-2 font15fontweight600 align-self-end"
            type="button"
            disabled={enableSubmit.length === 0}
            onClick={() => onSubmitChanges()}
          >
            Submit changes
          </button>
        </>
      );
    return (
      <div
        style={{ height: "60vh" }}
        className="d-flex justify-content-center align-items-center align-self-center"
      >
        <p className="m-0">Select image from right panel</p>
      </div>
    );
  };
  return (
    <>
      {renderTagLabelForm()}
      {render()}
    </>
  );
};

export const ImageExtractFilledForm = () => {
  const { aiDataSelectedForm } = useAIModelContext();
  const renderDocumentAIFilledForm = () => {
    let targets = [];
    const { target_column } = aiDataSelectedForm;
    try {
      targets = Object.keys(JSON.parse(target_column));
    } catch (ex) {
      console.log(ex);
    }
    if (targets.length)
      return (
        <div className="d-flex flex-column gap-2">
          <div className="d-flex flex-column border-bottom p-3 gap-1">
            <p className="p-0 ai-field-title text-white">Target :</p>
            <ul>
              {targets.map((target, key) => (
                <li key={key} className="text-capitalize text-white">
                  {target}
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    return <p>No targets found</p>;
  };
  const render = () => {
    if (aiDataSelectedForm && aiDataSelectedForm.target_column)
      return renderDocumentAIFilledForm();
  };
  return render();
};

export default ImageExtractTraining;
