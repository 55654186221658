import React, { useEffect, useRef, useState } from "react";
import ReactChipInput from "react-chip-input";

const Tag = (props) => {
  const [chips, setChips] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const inputRef = useRef();
  useEffect(() => {
    if (props.selectedTags) setChips(props.selectedTags);
    else setChips([]);
  }, [props.selectedTags, props.mainImage]);

  const handleAddChip = (chip) => {
    if (!chips.includes(chip)) setChips([...chips, chip]);

    if (!suggestions.includes(chip)) setSuggestions([...suggestions, chip]);
  };

  const handleDeleteChip = (chip, index) => {
    const newChips = [...chips];
    newChips.splice(index, 1);
    setChips(newChips);
  };

  const renderSuggestions = () => {
    if (suggestions.length) {
      return suggestions.map((sug, index) => (
        <li key={index} onClick={(e) => handleAddChip(sug)}>
          {sug}
        </li>
      ));
    }
  };
  return (
    <div className="d-flex flex-column gap-min">
      <ReactChipInput
        ref={inputRef}
        classes="ai-chip-input"
        chips={chips}
        onFocus={(e) => setShowSuggestion(!showSuggestion)}
        onSubmit={(value) => handleAddChip(value)}
        onRemove={(index) => handleDeleteChip(index)}
      />
      {suggestions.length > 0 && (
        <div className="suggestedtagp-3">
          <div className="text-secondary">Suggested Tags :</div>
          <ul className="suggestedtaglist">{renderSuggestions()}</ul>
        </div>
      )}
      <button
        disabled={chips.length === 0}
        type="button"
        className=" btn btn1 radious-0 w-15  mt-2 f-13 text-uppercase py-2 font15fontweight600 align-self-center"
        onClick={() =>
          props?.onImageProcessed({ ...props.mainImage, selectedTags: chips })
        }
      >
        Save
      </button>
    </div>
  );
};

export default Tag;
