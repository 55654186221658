import axios from "axios";
import constant from "../helper/constant";
import http from "./../helper/http";

export const validateRecapcha = (payload) => {
  return http.post(constant.API.AUTH.CAPCHA, payload);
};

export const getIndustryList = () => {
  return http.get(constant.API.AUTH.GET_INDUSTRIES);
};

export const getUnsignedRevenue = (payload) => {
  return http.post(constant.API.AUTH.CALCULATE_UNSIGNED_REVENUE, payload);
};

export const getUnsignedReportByMail = (payload) => {
  return http.post(constant.API.AUTH.GET_UNSIGNED_REPORT_BY_MAIL, payload);
};

export const getTenantList = (email) => {
  return http.post(constant.API.AUTH.GET_TENANT_LIST, { email });
};

export const getSolutionList = (id) => {
  return http.post(constant.API.AUTH.GET_SOLUTION_LIST, { id });
};

export const createOrganization = (params) => {
  return http.post(constant.API.AUTH.ORG, params);
};

export const getOrganization = (params) => {
  return http.get(constant.API.AUTH.ORG, { params });
};

export const updateOrganization = (id, payload) => {
  return http.put(`${constant.API.AUTH.ORG}/${id}`, payload);
};

export const createNewOrgDatabase = () => {
  return http.get(constant.API.AUTH.CREATE_NEW_DB);
};

export const createNewOrgCloudStorage = () => {
  return http.get(constant.API.AUTH.CREATE_CLOUD_STORAGE);
};

export const sendEmailReport = (payload) => {
  return axios.post(process.env.REACT_APP_PDF_GENERATION_LINK, payload);
};
