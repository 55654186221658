import constant from "../../constant";
import { getSharedInfo } from "./../../shared-info";

const HttpRequestInterceptor = (http) => {
  http.interceptors.request.use((config) => {
    let token = getSharedInfo(constant.SHARED.BEARER_TOKEN);
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  }, console.log);
};

export default HttpRequestInterceptor;
