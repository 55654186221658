import React from "react";

const RightSidemenu = ({ children }) => {
  return (
    <div className="col-md-2 px-md-0 rightbar-outer">
      <div className="rightbar bg-white">{children}</div>
    </div>
  );
};

export default RightSidemenu;
