import React from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import constant from "../../../../helper/constant";

const AutocompleteWithPlaceId = (props) => {
  const { onSelected, place } = props;

  return (
    <div className="form-group">
      <ReactGoogleAutocomplete
        className="form-control"
        defaultValue={place.formatted_address}
        apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
        options={{
          types: ["restaurant"],
          componentRestrictions: { country: constant.countryRegion },
        }}
        onPlaceSelected={(place) => {
          console.log(place);
          if (place) onSelected(place);
        }}
      />
    </div>
  );
};

export default AutocompleteWithPlaceId;
