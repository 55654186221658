import React, { useState } from "react";
import { Cell, Pie, PieChart, Sector } from "recharts";
import { formatToCurrency } from "../../../helper/helper";
import { useScreenSizeContext } from "../../../context/mobile-layout.context";
import constant from "../../../helper/constant";

const PredDonutChart = (props) => {
  const { data = [], width = 500, height = 500, center } = props;
  const COLORS = ["#B4D3F3", "#6FA7E0", "#264C7A", "#638EBA"];

  const [activeIndex, setActiveIndex] = useState(0);
  const { isMobileScreen } = useScreenSizeContext();

  const renderDonutChart = () => {
    const dimensions = isMobileScreen
      ? constant.donutDimension.mobile
      : constant.donutDimension.desktop;
    return (
      <PieChart width={dimensions.width} height={dimensions.height}>
        <Pie
          dataKey="value"
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={dimensions.innerRadius}
          outerRadius={dimensions.outerRadius}
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          startAngle={90}
          endAngle={450}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={dynamicColor(index)} />
          ))}
        </Pie>
      </PieChart>
    );
  };

  const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
      props;
    return (
      <g>
        {center && center(cx, cy)}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  const dynamicColor = (index) => COLORS[index % COLORS.length];

  const renderLegendLabel = (item, index) => {
    const backgroundColor = dynamicColor(index);
    const { name, value } = item;
    return (
      <div
        className={`d-flex align-items-start donut-legend gap-2`}
        key={`legend-${index}`}
      >
        <div className="donut-legend-item" style={{ backgroundColor }}></div>
        <div className="d-flex gap-1 flex-column align-items-start">
          <p className="m-0 p-0 text-uppercase label">{name}</p>
          <p className="m-0 p-0 text-uppercase value font-weight-bold">
            {formatToCurrency(value)}
          </p>
        </div>
      </div>
    );
  };

  const renderLegend = () => {
    if (data.length && data[0].value)
      return (
        <div className={`d-flex flex-column ${isMobileScreen ? "gap-1" : "gap-2"}`}>
          {data.map((item, index) => renderLegendLabel(item, index))}
        </div>
      );
  };

  const render = () => {
    if (data.length)
      return (
        <div
          className={`d-flex gap-2 align-items-center justify-content-center rotate-90 ${isMobileScreen ? "flex-column" : ""}`}
        >
          {renderDonutChart()}
          {renderLegend()}
        </div>
      );
    return <p>No data available</p>;
  };

  return render();
};

export default PredDonutChart;
