import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import constant, { ToastType } from "../../helper/constant";
import { useAIModelContext } from "../../context/ai-model.context";
import { useAuthContext } from "../../context/auth.context";
import RecentModels from "./dashboard/RecentModels";
import { useAppContext } from "../../context/app.context";
import { useScreenSizeContext } from "../../context/mobile-layout.context";
const Home = () => {
  const { getTemplates, clearModelInfo, templates, isOverviewFirstTime } =
    useAIModelContext();
  const { showAlert } = useAppContext();
  const { isLoggedIn } = useAuthContext();
  const { isMobileScreen } = useScreenSizeContext();

  useEffect(() => {
    clearModelInfo();
  }, []);

  useEffect(() => {
    if (isOverviewFirstTime)
      showAlert("Your business overview is ready!", ToastType.SUCCESS, false);
  }, [isOverviewFirstTime]);

  useEffect(() => {
    if (isLoggedIn) getTemplates();
  }, [isLoggedIn]);

  const render = () => {
    return (
      <div className="row mx-0 mt-5">
        <div
          className={`col-md-12 px-md-0 ${isMobileScreen ? "p-0 mb-5" : ""}`}
        >
          <div className="col-md-4 mx-auto">
            <h1 className="h1 font-weight-bold">Welcome to Avtar AI.</h1>
            {!isMobileScreen && (
              <Link
                to={constant.PAGES.HOME.AI_TEMPLATE}
                className="btn1 mt-3 arrowhover d-flex align-items-center justify-content-between font-weight-bold w-100"
              >
                Create New Workflow Manually
                <span>
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 45 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M44.0607 13.0607C44.6464 12.4749 44.6464 11.5251 44.0607 10.9393L34.5147 1.3934C33.9289 0.807612 32.9792 0.807612 32.3934 1.3934C31.8076 1.97918 31.8076 2.92893 32.3934 3.51472L40.8787 12L32.3934 20.4853C31.8076 21.0711 31.8076 22.0208 32.3934 22.6066C32.9792 23.1924 33.9289 23.1924 34.5147 22.6066L44.0607 13.0607ZM0 13.5H43V10.5H0V13.5Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </Link>
            )}
          </div>
          <div className="col-md-3 my-4 mx-auto">
            <Link
              to={constant.PAGES.HOME.BUSINESS_OVERVIEW}
              className={`btn3 py-3 px-0 d-flex align-items-center justify-content-center font-weight-bold w-100 text-center`}
            >
              Business Overview{" "}
              {isOverviewFirstTime && <span className="badge-indicator"></span>}
            </Link>
          </div>

          {templates && templates.length !== 0 && (
            <div
              className={`${isMobileScreen ? "mx-2" : "mx-5"}  my-3 p-5 my-3 p-4 mp-4`}
            >
              <RecentModels />
            </div>
          )}
        </div>
      </div>
    );
  };

  return render();
};

export default Home;
