import React from "react";
import images from "../../../../../helper/images";

const CustomerFeelingWidget = () => {
  const render = () => {
    return (
      <div className="col-md-4 mt-3 fadeUp">
        <h3>Customer Feeling</h3>
        <div className="mt-4">
          <img
            src={images.socialMediaResult.customerFeeling}
            className="w-100"
            alt=""
          />
        </div>
      </div>
    );
  };

  return render();
};

export default CustomerFeelingWidget;
