import { useEffect, useState } from "react";
import images from "../../../../../../helper/images";
import { useAISocialMediaContext } from "../../../../../../context/ai-social-media.context";
import CustomLoader from "../../../../../common/CustomLoader";
import { createSocialImages } from "../../../../../../service/ai-model.service";
import { useAIModelContext } from "./../../../../../../context/ai-model.context";

const AdImageItem = (props) => {
  const { item, rowIndex } = props;
  const [adImages, setAdImages] = useState([]);
  const [form, setForm] = useState();
  const { selectedPredictionModel } = useAIModelContext();

  const { adContent, updateAdContent } = useAISocialMediaContext();
  useEffect(() => {
    createSocialAdImageContent(item.description);
  }, []);

  const onAdImageSubmitted = (e) => {
    e.preventDefault();
    console.log(form);
  };
  const onImageSelected = (value, rowIndex) => {
    const data = { ...adContent[rowIndex], url: value };
    const list = adContent.slice();
    list[rowIndex] = data;
    updateAdContent(list);
  };

  const createSocialAdImageContent = (prompt = "") => {
    createSocialImages({ prompt }, selectedPredictionModel.taskId)
      .then((result) => {
        setAdImages(result.data);
      })
      .catch((ex) => {
        createSocialAdImageContent();
      });
  };

  const renderAdImageList = () => {
    if (adImages && adImages.length)
      return (
        <>
          <h4>AI Generated Image</h4>
          <form noValidate onSubmit={onAdImageSubmitted}>
            <div className="grid">
              {adImages.map((adImg, imgIndex) => (
                <div key={imgIndex} className="grid__item">
                  <label
                    className={`image-checkbox ${adContent[rowIndex].url === adImg.url ? "image-checkbox-checked " : ""}`}
                  >
                    <img className="img-responsive" src={adImg.url} alt="" />
                    <input
                      type="checkbox"
                      name="image"
                      checked={adContent[rowIndex].url === adImg.url}
                      value={adImg.url}
                      onChange={(e) =>
                        onImageSelected(e.target.value, rowIndex)
                      }
                    />
                    <svg
                      className="fa"
                      width="35"
                      height="35"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g id="Interface / Check">
                        <path
                          id="Vector"
                          d="M6 12L10.2426 16.2426L18.727 7.75732"
                          stroke="#fff"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </label>
                </div>
              ))}
            </div>
          </form>
        </>
      );
    return (
      <div className="d-flex justify-content-center">
        <CustomLoader masked />
      </div>
    );
  };

  const renderCustomAdImage = () => {
    return (
      <>
        <h4>Upload Images</h4>
        <div className="upload__box mt-4">
          <div className="upload__btn-box">
            <label className="d-flex align-items-center">
              <div className="form-control text-secondary w-75 px-3 rounded-pill">
                .Jpg, .Png, .Gif
              </div>
              <div className="ms-3 w-25">
                <div className="upload__btn w-100">Upload images</div>
              </div>
              <input
                type="file"
                multiple
                data-max_length="20"
                className="upload__inputfile"
              />
            </label>
          </div>
          <div className="upload__img-wrap"></div>
        </div>
      </>
    );
  };

  const render = () => {
    return (
      <div className="mt-2 mb-5">
        <div className={`text-right me-auto addtitle2`}>
          <h4>Ad #{rowIndex + 1}</h4>
        </div>
        <div className="ReviwContent lighorangebg rounded">
          <h3 className="text-orange mb-0 px-3 pt-3 d-flex justify-content-between align-items-center">
            <p className="m-0"> Ad Content</p>
          </h3>
          <p className="p-3">{item.description}</p>
        </div>
        <div className="mt-5 border border-orange mb-5 p-3 rounded">
          <h3 className="text-orange mb-3">Ad Images</h3>
          {renderCustomAdImage()}
          <div className="text-center mx-auto ortxt position-relative my-4">
            or
          </div>
          {renderAdImageList()}
        </div>
      </div>
    );
  };
  return render();
};

export default AdImageItem;
