import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAIModelContext } from "../../../context/ai-model.context";
import constant from "../../../helper/constant";
import {
  calculatePercentage,
  currencyDifference,
  formatNumberInMillions,
  formatToCurrency,
  sumOfObject,
} from "../../../helper/helper";
import ModelCreator from "../ModelCreator";
import PredDonutChart from "../../common/charts/PredDonutChart";
import { useAuthContext } from "../../../context/auth.context";

const AIValue = () => {
  const { getAIValue, aiValue } = useAIModelContext();
  const { revenueReportNew, updateRevenueReport } = useAuthContext();
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (params.id) {
      getAIValue(params.id, (data) => {
        updateRevenueReport(data);
      });
    }
  }, [params.id]);

  const onShowTimeLine = () => {
    navigate(`/${constant.PAGES.HOME.AI_ROADMAP}/${params.id}`);
  };

  const renderDonutChart = () => {
    if (revenueReportNew && revenueReportNew.additionalProfit) {
      const report = getRevenueReport();
      return <PredDonutChart data={report} center={renderCenter} />;
    }
  };

  const getRevenueReport = () => {
    return [
      {
        name: "revenue Increase",
        value: revenueReportNew.report.revenueIncrease,
      },
      {
        name: "material cost reduction",
        value: revenueReportNew.report.matCostReduction,
      },
      {
        name: "manpower efficiencies",
        value: revenueReportNew.report.manPowerEff,
      },
      {
        name: "machine cost reduction",
        value: revenueReportNew.report.macCostReduction,
      },
    ];
  };

  const renderCenter = (cx, cy) => {
    const additionalProfit = sumOfObject(revenueReportNew.report);
    if (additionalProfit) {
      const percentage = calculatePercentage(
        additionalProfit,
        revenueReportNew.revenue + additionalProfit
      ).toFixed(2);
      return (
        <>
          <text
            x={cx}
            y={cy - 25}
            dy={8}
            textAnchor="middle"
            fill="#000000"
            fontSize={24}
            fontWeight="bold"
          >
            <tspan>Additional Profit*</tspan>
          </text>
          <text
            x={cx}
            y={cy + 10}
            dy={8}
            textAnchor="middle"
            fill="#217CE8"
            fontSize={28}
            fontWeight="bold"
          >
            {formatToCurrency(additionalProfit)}
          </text>
          <text
            x={cx}
            y={cy + 35}
            dy={8}
            textAnchor="middle"
            fill="#217CE8"
            fontSize={18}
            fontWeight="bold"
          >
            {`(${percentage}%)`}
          </text>
        </>
      );
    }
  };

  const render = () => {
    if (aiValue) {
      return (
        <div className="row mx-0">
          <div className="col-md-12 px-md-0">
            <div className="p-3 pb-5">
              <div className="text-center">
                <ModelCreator />
                {/* <div className="flowmidbox p-4">
                  <div className="mt-2">
                    <big>Profit* </big>
                    <span className="ml-3 h1">
                      {formatToCurrency(aiValue.afterTotalExpense)}
                    </span>
                  </div>
                </div> */}
                <h3 className="py-3">Additional Profit Breakdown</h3>
                <div className="col-sm-12">{renderDonutChart()}</div>
                <button
                  className="btn1 btn radious-0  w-30 mt-2 f-13 text-uppercase my-4 py-2 font15fontweight600"
                  onClick={onShowTimeLine}
                >
                  Timeline to execute AI Models
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  return render();
};

export default AIValue;
