import React, { useEffect, useState } from "react";
import { getSocialMediaReviews } from "../../../../../service/ai-model.service";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import { useParams } from "react-router-dom";
import CustomLoader from "../../../../common/CustomLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as farStart } from "@fortawesome/free-regular-svg-icons";
import { formatDateInDays } from "../../../../../helper/helper";

const SocialReviewList = ({ isNegative }) => {
  const { selectedPredictionModel } = useAIModelContext();
  const [reviews, setReviews] = useState([]);
  const params = useParams();
  useEffect(() => {
    if (params.id) {
      const templateId = params.id;
      const taskId = selectedPredictionModel.taskId;
      getSocialMediaReviews({ templateId, taskId, isNegative }).then((data) => {
        if (data && data.reviews) {
          data.reviews.sort((a, b) => b.rating - a.rating);
          setReviews(data.reviews);
        }
      });
    }
  }, [params.id, selectedPredictionModel, isNegative]);

  const renderRating = (rating) => {
    return [1, 2, 3, 4, 5].map((item, index) => {
      if (index < Number(rating))
        return <FontAwesomeIcon key={index} icon={faStar} color="#ffbb00" />;
      return <FontAwesomeIcon key={index} icon={farStart} color="#ccc" />;
    });
  };
  const renderReviewItem = (item) => {
    const {
      reviewer_avatar,
      reviewer_id,
      reviewer,
      text,
      rating,
      photos,
      datetime,
    } = item;
    return (
      <li key={reviewer_id}>
        <div className="reviewbox mt-5 mt-md-3 d-flex">
          <div className="reviewboximg ">
            <div className="position-relative">
              <span>
                <img src={reviewer_avatar} alt={reviewer} />
              </span>
              {/* <div className="reviewplatfrm"><img src={images.socialMediaResult.google} alt="" /></div> */}
            </div>
          </div>
          <div className="ms-md-4">
            <div className="mb-1 h5 text-dark">{reviewer}</div>
            <div className="reviewdtls d-flex align-items-baseline">
              <div className="ratings"> {renderRating(rating)}</div>
              <small className="text-secondary ms-2">
                {formatDateInDays(datetime)}
              </small>
            </div>
            <p>{text}</p>
            <div className="reviewboximglist d-flex align-items-center gap-3">
              {photos.map((item, key) => (
                <span key={key}>
                  <img src={item} alt="" />
                </span>
              ))}
            </div>
          </div>
        </div>
      </li>
    );
  };
  const renderReviewList = () => {
    if (reviews && reviews.length)
      return (
        <>
          <div className="d-flex mb-2 align-items-center justify-content-between">
            <h3>Latest Reviews</h3>
            {/* <select className="form-select" aria-label="Default select example">
                            <option selected>Most Relevant</option>
                            <option value="1">Newest</option>
                            <option value="2">Highest</option>
                            <option value="3">Lowest</option>
                        </select> */}
          </div>
          <div className="bg-white p-4">
            <ul className="reviewsec position-relative">
              {reviews.map(renderReviewItem)}
            </ul>
          </div>
        </>
      );
    return (
      <div className="d-flex justify-content-center align-items-center">
        <CustomLoader masked />
      </div>
    );
  };

  const render = () => {
    return renderReviewList();
  };

  return render();
};

export default SocialReviewList;
