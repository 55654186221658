import React from "react";
import { useParams, useRoutes } from "react-router-dom";
import { socialMediaRoutes } from "./social-media.routes";

const SocialMediaRouter = () => {
  const socialRoutes = useRoutes(socialMediaRoutes);
  const params = useParams();
  const render = () => {
    return socialRoutes;
  };
  return render();
};

export default SocialMediaRouter;
