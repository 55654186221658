import React, { useEffect } from "react";
import CustomLoader from "./../../common/CustomLoader";
import { useAIAskMeContext } from "../../../context/ai-ask-me.context";
import { useAppContext } from "../../../context/app.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDotCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

const ChatbotHistory = () => {
  const { isLoading } = useAppContext();
  const { getQueryHistory, queryHistory, updateCurrentQuery, showModal } =
    useAIAskMeContext();

  useEffect(() => {
    if (showModal) getQueryHistory();
  }, [showModal]);

  const renderHistory = () => {
    if (queryHistory && queryHistory.length)
      return queryHistory.map((item, key) => (
        <div
          key={key}
          onClick={() => updateCurrentQuery(item.id)}
          className="py-2 text-white cursor-pointer d-flex align-items-start gap-1"
        >
          <FontAwesomeIcon icon={faQuestionCircle} />
          <div>{item.id}</div>
        </div>
      ));
    return <p>No data available</p>;
  };

  const render = () => {
    return (
      <div className="chatbotsidebar position-relative px-3 py-4">
        <h3 className="h6 font-weight-bold mt-4 text-white">History</h3>
        <div className="font-14 text-secondary mt=y-3">Recent Queries</div>
        <div className="mx-h-35">{!isLoading && renderHistory()}</div>
        {isLoading && (
          <div className="d-flex justify-content-center">
            <CustomLoader />
          </div>
        )}
      </div>
    );
  };
  return render();
};

export default ChatbotHistory;
