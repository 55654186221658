import React, { useEffect, useState } from "react";
import ImageButton from "../../../../common/ImageButton";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import { Link, useParams } from "react-router-dom";
import {
  generateProcessor,
  getTextFromImage,
  submitMultiUploadRequest,
} from "../../../../../service/ai-model.service";
import { useAppContext } from "../../../../../context/app.context";
import constant, { FileType, ToastType } from "../../../../../helper/constant";
import {
  convertArrayToObjects,
  generateCsvBlob,
  getImageStoreHost,
  getUUID,
} from "../../../../../helper/helper";
import { useAuthContext } from "../../../../../context/auth.context";

const DocumentAITraining = (props) => {
  const { mainImage, fileList, onCompleted, fileType } = props;
  const { aiDataSelectedForm, saveAIModelForm, switchToSavedModel } =
    useAIModelContext();
  const { updateLoader, showAlert } = useAppContext();
  const { userInfo } = useAuthContext();
  const params = useParams();
  const [extractedMessage, setExtractedMessage] = useState(null);

  useEffect(() => {
    setExtractedMessage(null);
  }, [mainImage]);

  const extractTextFromImage = () => {
    try {
      const formData = new FormData();
      formData.append("csv", mainImage.file);
      formData.append("processorName", constant.processorName);
      updateLoader(true);
      getTextFromImage(formData)
        .then((result) => {
          if (result) {
            const list = result.text
              .split("\n")
              .filter((zz) => zz && zz.trim() !== "")
              .map((element) => {
                if (element) return { label: "", value: element };
              });
            // console.log(list);
            setExtractedMessage(list);
          }
        })
        .catch((ex) => showAlert(ex, ToastType.ERROR))
        .finally(() => updateLoader(false));
    } catch (ex) {
      console.log(ex);
    }
  };

  const renderMainImage = () => {
    if (fileType === FileType.PDF)
      return (
        <embed
          src={mainImage.dataURL}
          type="application/pdf"
          width="100%"
          height={400}
        ></embed>
      );
    return (
      <ImageButton
        image={mainImage}
        alt={mainImage.file.name}
        style={{ width: "100%", height: 400 }}
        disableTrash
      />
    );
  };

  const onGenerateKey = () => {
    extractTextFromImage();
  };

  const onLabelChange = (label, keyIndex) => {
    const list = [...extractedMessage];
    list[keyIndex].label = label;
    setExtractedMessage(list);
  };

  const renderKeys = () => {
    if (extractedMessage && extractedMessage.length) {
      return (
        <div className="d-flex flex-1 justify-content-center flex-column align-items-center">
          <h4>Generated keys</h4>
          <p>Please add keys for below list to be used in Document AI model</p>
          <div
            className="d-flex flex-column gap-2 p-2"
            style={{ maxHeight: "35vh", overflow: "auto" }}
          >
            {extractedMessage.map((item, keyIndex) => (
              <div
                className="d-flex justify-content-between align-items-center"
                key={keyIndex}
              >
                <p className="m-0 p-0">{item.value}</p>
                <input
                  type="text"
                  placeholder="Enter label name"
                  value={item.label}
                  onChange={(e) => onLabelChange(e.target.value, keyIndex)}
                />
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  const submitUploadRequest = () => {
    const { modelId } = aiDataSelectedForm;
    const formData = new FormData();
    formData.append("templateId", params.id);
    formData.append("modelId", modelId);
    fileList.forEach(({ file }) => formData.append("csv", file));
    submitMultiUploadRequest(formData).then(console.log).catch(console.log);
  };

  const submitTrainingData = (list) => {
    console.log(list);
    const { modelId, id, model_name, parentId } = aiDataSelectedForm;
    const csvData = generateCSVData(list);
    const blob = generateCsvBlob(csvData);
    const features = convertArrayToObjects(list);
    const formData = new FormData();
    formData.append("templateId", params.id);
    formData.append("modelId", modelId);
    formData.append("id", id);
    formData.append("csv", blob, `${getUUID()}.csv`);
    formData.append("target", JSON.stringify(features));
    updateLoader(true);
    saveAIModelForm(formData, (data) => {
      switchToSavedModel({ ...data, model_name, parentId });
      if (onCompleted) onCompleted();
    });
  };
  const generateCSVData = (list) => {
    const features = convertArrayToObjects(list);
    const { modelId } = aiDataSelectedForm;
    const {
      org: { org_name },
    } = userInfo;
    const templateId = params.id;
    const csvData = fileList.map((fileData) => {
      return {
        "image-url": `${getImageStoreHost()}${org_name}//${templateId}/${modelId}/${fileData.file.name}`,
        ...features,
      };
    });
    return csvData;
  };

  const onSubmitChanges = () => {
    const list = extractedMessage.filter((zz) => zz.label && zz.label !== "");
    if (list.length) {
      submitUploadRequest();
      submitTrainingData(list);
    }
  };

  const render = () => {
    if (mainImage)
      return (
        <>
          <div className="d-flex gap-3 my-5">
            <div className="d-flex gap-2 flex-column flex-1">
              {renderMainImage()}
              {
                <button
                  className="btn1 align-self-end"
                  type="button"
                  onClick={() => onGenerateKey()}
                >
                  Generate keys
                </button>
              }
            </div>
            {renderKeys()}
          </div>
          {extractedMessage && extractedMessage.length && (
            <button
              className="btn1 rounded align-self-end"
              type="button"
              onClick={() => onSubmitChanges()}
            >
              Submit changes
            </button>
          )}
        </>
      );
    return (
      <div
        style={{ height: "60vh" }}
        className="d-flex justify-content-center align-items-center"
      >
        <p className="m-0">
          Select suitable image from right panel to generate keys
        </p>
      </div>
    );
  };
  return render();
};

export const DocumentAIFilledForm = () => {
  const { aiDataSelectedForm } = useAIModelContext();
  const renderDocumentAIFilledForm = () => {
    let targets = [];
    const { target_column } = aiDataSelectedForm;
    if (target_column) {
      try {
        targets = Object.keys(JSON.parse(target_column));
      } catch (ex) {
        console.log(ex);
      }
      if (targets.length)
        return (
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-column border-bottom p-3 gap-1">
              <p className="p-0 ai-field-title text-white">Target :</p>
              <ul>
                {targets.map((target, key) => (
                  <li key={key} className="text-capitalize text-white">
                    {target}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
    }
    return <p>No targets found</p>;
  };
  const render = () => {
    if (aiDataSelectedForm && aiDataSelectedForm.status)
      return renderDocumentAIFilledForm();
  };
  return render();
};

export default DocumentAITraining;
