import { render } from "@testing-library/react";
import React from "react";
import PredPieChart from "../../common/charts/PredPieChart";
import { useScreenSizeContext } from "../../../context/mobile-layout.context";

const AIRecommendations = (props) => {
  const { recommendations } = props;
  const { isMobileScreen } = useScreenSizeContext();

  const getPieChartData = (response) => {
    return response.map((item) => {
      let data = {};
      data["name"] = item["Employee_Role"];
      data["value"] = item["No_of_Employees"];
      return data;
    });
  };

  const renderRecommendPieChart = () => {
    if (
      recommendations.recommendations &&
      recommendations.recommendations.Employee_Distribution
    ) {
      const data = getPieChartData(
        recommendations.recommendations.Employee_Distribution
      );
      return <PredPieChart data={data} />;
    }
  };

  const renderRecommendTable = () => {
    if (
      recommendations.recommendations &&
      recommendations.recommendations.Employee_Distribution
    ) {
      const list =
        recommendations.recommendations.Employee_Distribution.slice();
      const keys = Object.keys(
        recommendations.recommendations.Employee_Distribution[0]
      );
      const columns = keys.map((item) => item.replaceAll("_", " "));
      return (
        <table className="ai-recommendation-table">
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th key={`rec-col-${index}`} className="p-2">
                  {col}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {list.map((item, listIndex) => {
              return (
                <tr key={`row-key-${listIndex}`}>
                  {keys.map((colKey, colKeyIndex) => (
                    <td key={`col-key-${colKeyIndex}`} className="p-2">
                      {item[colKey]}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
  };

  const renderAIRecommendProfit = () => {
    if (
      recommendations.recommendations &&
      recommendations.recommendations.AI_Applications_to_Increase_Profits
    ) {
      const list =
        recommendations.recommendations.AI_Applications_to_Increase_Profits;
      return (
        <div className="d-flex flex-column gap-2">
          <h6>AI Applications to Increase Profits</h6>
          <ul className="ai-recommend-list-view pl-5">
            {list.map((item, listIndex) => (
              <li key={`profit-${listIndex}`}>{item}</li>
            ))}
          </ul>
        </div>
      );
    }
  };

  const render = () => {
    return (
      <>
        <h5 className="py-3">AI Recommendations</h5>
        <p>
          The distribution of employees accross various taks in the table chain,
          along with their roles and average salaries
        </p>
        <div className={`d-flex flex-column gap-3 ${isMobileScreen ? "" : ""}`}>
          <div
            className={`d-flex gap-2 align-items-center ${isMobileScreen ? "flex-column" : "justify-content-around"}`}
          >
            {renderRecommendTable()}
            {renderRecommendPieChart()}
          </div>
          {renderAIRecommendProfit()}
        </div>
      </>
    );
  };

  return render();
};

export default AIRecommendations;
