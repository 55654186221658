import React, { Fragment } from "react";
import { useAuthContext } from "../../context/auth.context";
import SideMenu from "../../navigation/Sidemeu";
import Footer from "./Footer";
import Header from "./Header";
import { UserRoles } from "../../helper/constant";
import { useScreenSizeContext } from "../../context/mobile-layout.context";

const BasePageContainer = ({ children, hideFooter }) => {
  const { isLoggedIn, userInfo } = useAuthContext();
  const { isMobileScreen } = useScreenSizeContext();

  const renderSideMenu = () => {
    if (
      isLoggedIn &&
      userInfo &&
      userInfo.org &&
      (userInfo.org.org_config || userInfo.org.role !== UserRoles[0].id)
    )
      return <SideMenu />;
  };

  return (
    <>
      <section className="container-fluid contentWrpper px-0">
        <Header />
        {!isMobileScreen && renderSideMenu()}
        {children}
        {!hideFooter && <Footer />}
      </section>
    </>
  );
};

export default BasePageContainer;
