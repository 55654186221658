import React from "react";
import { Link } from "react-router-dom";
import images from "../../../../helper/images";
import constant, {
  CRM_LIST,
  ZohoAuthConfig,
} from "../../../../helper/constant";
import {
  getSharedInfo,
  setZohoRedirectUrl,
} from "../../../../helper/shared-info";

const CRMLogin = (props) => {
  const onZohoLogin = () => {
    setZohoRedirectUrl(`${window.location.pathname}?rightPanel=true`);
    const authUrl = `${ZohoAuthConfig.authUrl}?response_type=${ZohoAuthConfig.responseType}&client_id=${ZohoAuthConfig.clientId}&scope=${ZohoAuthConfig.scope}&redirect_uri=${ZohoAuthConfig.redirectUri}`;
    window.location.href = authUrl;
  };

  const onCRMLogin = () => {
    switch (props.type) {
      case CRM_LIST.ZOHO:
        onZohoLogin();
      default:
        onZohoLogin();
    }
  };

  const getCRMLogo = () => {
    switch (props.type) {
      case CRM_LIST.ZOHO:
        return images.zohoIcon;
      default:
        return images.zohoIcon;
    }
  };

  const renderCRMContent = () => {
    const access_token = getSharedInfo(constant.SHARED.ZOHO_AUTH_TOKEN);
    if (access_token) {
      return <p>Show CRM Data List</p>;
    }
    return (
      <Link
        onClick={() => onCRMLogin()}
        className="d-flex justify-content-center"
      >
        <img src={getCRMLogo()} alt="zoho" className="ai-data-crm-icon" />
      </Link>
    );
  };

  const render = () => {
    return renderCRMContent();
  };

  return render();
};

export default CRMLogin;
