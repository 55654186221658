import React, { useEffect, useState } from "react";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import { Link, useParams } from "react-router-dom";
import { submitMultiUploadRequest } from "../../../../../service/ai-model.service";
import { useAppContext } from "../../../../../context/app.context";
import {
  generateCsvBlob,
  getImageStoreHost,
  getUUID,
} from "../../../../../helper/helper";
import { useAuthContext } from "../../../../../context/auth.context";
import ImageButton from "../../../../common/ImageButton";
import TagGenerator from "./TagGenerator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const MultiLabelICTraining = (props) => {
  const { mainImage, fileList, onCompleted, onImageProcessed } = props;
  const { aiDataSelectedForm, saveAIModelForm, switchToSavedModel } =
    useAIModelContext();
  const { updateLoader, showAlert } = useAppContext();
  const { userInfo } = useAuthContext();
  const [selectedTags, setSelectedTags] = useState([]);
  const params = useParams();

  useEffect(() => {
    setSelectedTags(mainImage?.selectedTags || []);
  }, [mainImage]);

  const renderMainImage = () => {
    return (
      <ImageButton
        image={mainImage}
        alt={mainImage.file.name}
        style={{ width: 500, height: 300, margin: "0 auto" }}
        disableTrash
      />
    );
  };

  const submitUploadRequest = () => {
    const { modelId } = aiDataSelectedForm;
    const formData = new FormData();
    formData.append("templateId", params.id);
    formData.append("modelId", modelId);
    fileList.forEach(({ file }) => formData.append("csv", file));
    submitMultiUploadRequest(formData).then().catch(console.log);
  };

  const submitTrainingData = () => {
    const { id, modelId, model_name, parentId } = aiDataSelectedForm;
    const csvData = generateCSVData(fileList);
    const blob = generateCsvBlob(csvData);
    const formData = new FormData();
    formData.append("templateId", params.id);
    formData.append("modelId", modelId);
    formData.append("id", id);
    formData.append("csv", blob, `${getUUID()}.csv`);
    console.log(formData);
    updateLoader(true);
    saveAIModelForm(formData, (data) => {
      switchToSavedModel({ ...data, model_name, parentId });
      if (onCompleted) onCompleted();
    });
  };
  const generateCSVData = (list) => {
    const { modelId } = aiDataSelectedForm;
    const {
      org: { org_name },
    } = userInfo;
    const templateId = params.id;
    const csvData = fileList.map((fileData) => {
      return {
        "image-url": `${getImageStoreHost()}${org_name}//${templateId}/${modelId}/${fileData.file.name}`,
        label: fileData.selectedTags.map((item) => item).join(","),
      };
    });
    console.log(JSON.stringify(csvData));
    return csvData;
  };

  const onSubmitChanges = () => {
    const list = fileList.slice();
    if (list.length) {
      submitUploadRequest();
      submitTrainingData();
    }
  };

  const onTagChange = (tag) => {
    if (tag) {
      let list = selectedTags.slice();
      const tags = list.map((item) => item.label);
      if (tags.includes(tag.label)) {
        list = list.filter((item) => item.label !== tag.label);
      } else {
        list.push({ ...tag });
      }
      setSelectedTags(list);
    }
  };

  const renderTagGenerator = () => {
    return (
      <TagGenerator
        onImageProcessed={onImageProcessed}
        mainImage={mainImage}
        selectedTags={selectedTags}
      />
    );
  };

  const onSelectedTagDelete = (index) => {
    const list = selectedTags.slice();
    list.splice(index, 1);
    setSelectedTags(list);
  };

  const renderTagList = () => {
    if (selectedTags.length) {
      console.log(selectedTags);
      return selectedTags.map((tag, index) => {
        return (
          <div className="d-flex gap-1 px-2 img-tag">
            <p className="p-0">{tag.label}</p>
            <Link onClick={() => onSelectedTagDelete(index)}>
              <FontAwesomeIcon icon={faXmark} className="text-dark" />
            </Link>
          </div>
        );
      });
    }
  };

  const render = () => {
    if (mainImage)
      return (
        <>
          <div className="container  d-flex gap-1 my-3 justify-content-between flex-column">
            <div className="ai-multi-img-main">{renderMainImage()}</div>
            {renderTagGenerator()}
          </div>
          <button
            className="btn1 btn radious-0 mt-2 f-13 text-uppercase py-2 font15fontweight600 align-self-end"
            type="button"
            onClick={() => onSubmitChanges()}
          >
            Submit changes
          </button>
        </>
      );
    return (
      <div
        style={{ height: "60vh" }}
        className="d-flex justify-content-center align-items-center align-self-center"
      >
        <p className="m-0">Select image from right panel</p>
      </div>
    );
  };
  return <>{render()}</>;
};

export const MultiLabelICFilledForm = () => {
  const { aiDataSelectedForm } = useAIModelContext();
  const renderDocumentAIFilledForm = () => {
    let targets = [];
    const { target_column } = aiDataSelectedForm;
    if (target_column) {
      try {
        targets = Object.keys(JSON.parse(target_column));
      } catch (ex) {
        console.log(ex);
      }
      if (targets.length)
        return (
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-column border-bottom p-3 gap-1">
              <p className="p-0 ai-field-title text-white">Target :</p>
              <ul>
                {targets.map((target, key) => (
                  <li key={key} className="text-capitalize text-white">
                    {target}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
    }
    return <p>No targets found</p>;
  };
  const render = () => {
    if (aiDataSelectedForm && aiDataSelectedForm.status)
      return renderDocumentAIFilledForm();
  };
  return render();
};

export default MultiLabelICTraining;
