import React, { useEffect, useState } from "react";
import ImageButton from "../../../../common/ImageButton";
import DocumentAITraining from "./DocumentAITraning";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import { ModelType } from "../../../../../helper/constant";
import ImageExtractTraining from "../image-extraction/ImageExtractTraning";
import MultiLabelICTraining from "../multi-label-image-classification/MultiLabelICTraning";

const DocumentAIModalContent = (props) => {
  const {
    selectedFiles,
    onCompleted,
    onImageProcessed,
    fileType,
    onImageRemove,
  } = props;
  const { aiDataSelectedForm } = useAIModelContext();
  const [mainImage, setMainImage] = useState(null);

  useEffect(() => {
    setMainImage(null);
  }, []);

  const onImageSelected = (image) => {
    setMainImage(image);
  };

  const renderPreview = () => {
    if (selectedFiles && selectedFiles.length)
      return selectedFiles.map((image, key) => (
        <ImageButton
          fileType={fileType}
          key={key}
          image={image}
          onRemove={() => onImageRemove(key)}
          onClick={() => onImageSelected({ ...image, imageIndex: key })}
          alt={image.file.fileName}
        />
      ));
  };

  const renderMainContent = () => {
    const { modelId } = aiDataSelectedForm;
    switch (modelId) {
      case ModelType.DocumentAI:
        return (
          <DocumentAITraining
            fileType={fileType}
            mainImage={mainImage}
            fileList={[...selectedFiles]}
            onCompleted={onCompleted}
          />
        );
      case ModelType.MultiLabelImageClassification:
        return (
          <MultiLabelICTraining
            mainImage={mainImage}
            fileList={[...selectedFiles]}
            onImageProcessed={onImageProcessed}
            onCompleted={onCompleted}
          />
        );
      case ModelType.ObjectDetection:
        return (
          <ImageExtractTraining
            mainImage={mainImage}
            fileList={[...selectedFiles]}
            onImageProcessed={onImageProcessed}
            onCompleted={onCompleted}
          />
        );
      default:
        return <></>;
    }
  };

  const renderDocumentAIModalContent = () => {
    return (
      <div className="row my-2">
        <div className="col-xs-12 col-md-10">
          <div className="d-flex ai-multi-layout-left-container flex-column">
            {renderMainContent()}
          </div>
        </div>
        <div className="col-xs-12 col-md-2 bl-separator">
          <div className="d-flex ai-multi-layout-right-container gap-2 py-2">
            <p className="align-self-start">Selected({selectedFiles.length})</p>
            {renderPreview()}
          </div>
        </div>
      </div>
    );
  };

  return renderDocumentAIModalContent();
};

export default DocumentAIModalContent;
