import React from "react";
import images from "../../../../../helper/images";

const SalesReportWidget = () => {
  const render = () => {
    return (
      <div className="col-md-4 mt-3 fadeUp">
        <h3>Sales Report</h3>
        <div className="mt-4">
          <img
            src={images.socialMediaResult.salesReport}
            className="w-100"
            alt=""
          />
        </div>
      </div>
    );
  };

  return render();
};

export default SalesReportWidget;
