// user.reducer.js
import { useReducer } from "react";
import * as ACTION from "./types";

const initialState = {
  queryHistory: [],
  currentQuery: "",
  currentQueryResult: null,
  queryTasks: [],
  queryWorkflows: [],
  showModal: false,
  askMeSelectedModels: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.UPDATE_QUERY_HISTORY:
      return {
        ...state,
        queryHistory: action.payload,
      };
    case ACTION.UPDATE_CURRENT_QUERY:
      return {
        ...state,
        currentQuery: action.payload,
      };
    case ACTION.UPDATE_CURRENT_QUERY_WORKFLOWS:
      return {
        ...state,
        queryWorkflows: action.payload,
      };
    case ACTION.UPDATE_CURRENT_QUERY_TASKS:
      return {
        ...state,
        queryTasks: action.payload,
      };
    case ACTION.UPDATE_SHOW_MODAL:
      return {
        ...state,
        showModal: action.payload,
      };
    case ACTION.UPDATE_ASK_ME_SELECTED_MODEL:
      return {
        ...state,
        askMeSelectedModels: action.payload,
      };
    case ACTION.CLEAR_ASK_ME:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

const useAIAskMeReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    state,
    dispatch,
  };
};

export default useAIAskMeReducer;
