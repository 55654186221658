import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useAISocialMediaContext } from "../../../../../../context/ai-social-media.context";
import { useState } from "react";

const AdWidget = (props) => {
  const { widget, rowIndex } = props;

  const render = () => {
    return (
      <div className="my-3">
        <div className="text-right me-auto addtitle2">
          <h4>Ad #{rowIndex + 1}</h4>
        </div>
        <div className="ReviwContent lighorangebg rounded">
          <p className="p-3">{widget.description}</p>
          <img src={widget.url} className="w-100 px-3" alt="" />
        </div>
      </div>
    );
  };
  return render();
};

export default AdWidget;
