import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FileType } from "../../helper/constant";
import images from "../../helper/images";

const ImageButton = (props) => {
  const { image, onClick, alt, style, onRemove, disableTrash, fileType } =
    props;

  const onImageSelected = () => {
    if (onClick) onClick();
  };

  const render = () => {
    return (
      <div
        className={`d-flex flex-column image-container ${image?.cords || image?.selectedTags?.length ? "ai-image-cropped" : ""}`}
      >
        {!disableTrash && (
          <Link
            onClick={() => onRemove()}
            className="align-self-end image-trash-icon"
          >
            <FontAwesomeIcon className="text-danger" icon={faXmarkCircle} />
          </Link>
        )}
        {(image?.cords || image?.selectedTags) && (
          <div className="ai-image-success">
            <FontAwesomeIcon
              className="text-success"
              icon={faCircleCheck}
              size="3x"
            />
          </div>
        )}
        <Link onClick={(e) => onImageSelected()} className="px-2">
          {fileType === FileType.PDF ? (
            <img
              src={images.iconPDF}
              alt={alt}
              style={style}
              className="ai-image-preview"
            />
          ) : (
            <img
              src={image.dataURL}
              alt={alt}
              style={style}
              className="ai-image-preview"
            />
          )}
        </Link>
      </div>
    );
  };
  return render();
};

export default ImageButton;
