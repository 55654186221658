import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../context/auth.context";
import { UserRoles } from "../../helper/constant";

const Protected = ({ children }) => {
  const { isLoggedIn } = useAuthContext();
  const { userInfo } = useAuthContext();
  if (!isLoggedIn) return <Navigate to="/" replace />;

  if (
    isLoggedIn &&
    userInfo &&
    userInfo &&
    userInfo.org &&
    userInfo.org.role === UserRoles[0].id &&
    !userInfo.org.org_config
  )
    return <Navigate to="/create-org" replace />;

  return children;
};
export default Protected;
