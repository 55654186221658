import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import CustomLoader from "../components/common/CustomLoader";
import { useAuthContext } from "../context/auth.context";
import { appRoutes, authRoutes } from "./routes";
import { useEffect } from "react";
import constant from "../helper/constant";

const Navigator = () => {
  const { isLoggedIn, isLoading } = useAuthContext();
  const appRouter = useRoutes(appRoutes);
  const authRouter = useRoutes(authRoutes);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      if (location.pathname.includes(constant.PAGES.AUTH.LOGIN)) navigate("/");
    }
  }, [isLoggedIn]);

  const render = () => {
    if (isLoggedIn) return appRouter;
    return authRouter;
  };

  return (
    <>
      {isLoading && <CustomLoader full masked />}
      {render()}
    </>
  );
};

export default Navigator;
