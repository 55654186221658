import { appLogout } from "../../../../firebase";

const DEBUG = process.env.REACT_APP_NODE_ENV !== "production";
const ErrorInterceptor = (http) => {
  http.interceptors.response.use(
    (response) => response.data,
    (error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.href = "/";
        return Promise.reject(error?.response);
      } else {
        /*dispatch your error in a more user friendly manner*/
        if (DEBUG) {
          //easier debugging
          console.group("Error");
          console.groupEnd();
        }
      }
      return Promise.reject(error?.response?.data || error);
    },
  );
};

export default ErrorInterceptor;
