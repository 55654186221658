import React, { createContext, useContext } from "react";
import * as ACTION from "../reducers/types";
import useICReducer from "../reducers/ic.reducer";

const ICContext = createContext();

const ICProvider = ({ children }) => {
  const { state, dispatch } = useICReducer();
  const setICList = (data) =>
    dispatch({ type: ACTION.SET_AI_IMAGE_CLASSIFICATION, payload: data });
  const setSelectedICIndex = (data) =>
    dispatch({
      type: ACTION.SET_SELECTED_AI_IMAGE_CLASSIFICATION,
      payload: data,
    });

  return (
    <ICContext.Provider
      value={{
        ...state,
        setICList,
        setSelectedICIndex,
      }}
    >
      {children}
    </ICContext.Provider>
  );
};

export const useICContext = () => useContext(ICContext);

export { ICProvider, ICContext };
