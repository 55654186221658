import React, { useEffect } from "react";
import axios from "axios";
import constant, { ZohoAuthConfig } from "../../helper/constant";
import { clearZohoRedirectUrl, getZohoRedirectUrl, setSharedInfo } from "../../helper/shared-info";
import { redirect, useNavigate } from "react-router-dom";
import CustomLoader from "./../../components/common/CustomLoader";

const Callback = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.hash.substring(1));
    const access_token = urlParams.get("access_token");
    const expires_in = urlParams.get("expires_in");

    if (access_token) {
      setSharedInfo(constant.SHARED.ZOHO_AUTH_TOKEN, access_token);
      clearOnTokenExpiry(expires_in * constant.OneSecond);
    }
    const url = getZohoRedirectUrl();
    clearZohoRedirectUrl();
    navigate(url);
  }, []);

  const clearOnTokenExpiry = (expiry) => {
    setTimeout(() => {
      setSharedInfo(constant.SHARED.ZOHO_AUTH_TOKEN, null);
    }, expiry);
  };

  return <CustomLoader full />;
};

export default Callback;
