import React, { useState } from "react";
import images from "../../../helper/images";
import { useLocation } from "react-router-dom";

const AIAutoGuide = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isAuto = queryParams.get("mode");
  const [autoGuide, setAutoGuide] = useState({
    showGuide: isAuto === "auto",
    showTask: false,
    showDept: false,
    showValue: false,
  });
  const render = () => {
    if (autoGuide.showGuide)
      return (
        <>
          <div className="dotwavebox">
            <div
              className="dotwave-circle dtowave1"
              onClick={() =>
                setAutoGuide({
                  ...autoGuide,
                  showTask: true,
                })
              }
            ></div>
            <div
              className="dotwave-circle dtowave2 "
              onClick={() =>
                setAutoGuide({
                  ...autoGuide,
                  showDept: true,
                })
              }
            ></div>
            <div
              className="dotwave-circle dtowave3 "
              onClick={() =>
                setAutoGuide({
                  ...autoGuide,
                  showValue: true,
                })
              }
            ></div>
          </div>
          {autoGuide.showTask && (
            <div className="taskbox1 zi-120">
              <div className="tsk1 taskbox-shadow mb-3 px-3 pb-3 pt-1 text-center bg-white">
                <div
                  className="tskdismiss1 text-right py-2 cursor-pointer"
                  onClick={() =>
                    setAutoGuide({
                      ...autoGuide,
                      showTask: false,
                    })
                  }
                >
                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.60977 3.51387L9.4991 8.40321L14.3631 3.53921C14.4705 3.42485 14.6 3.33337 14.7436 3.27025C14.8873 3.20713 15.0422 3.17367 15.1991 3.17188C15.535 3.17188 15.8572 3.30533 16.0948 3.54287C16.3323 3.78042 16.4658 4.1026 16.4658 4.43854C16.4687 4.59384 16.4399 4.7481 16.3811 4.89186C16.3223 5.03562 16.2347 5.16584 16.1238 5.27454L11.1964 10.1385L16.1238 15.0659C16.3325 15.2701 16.4549 15.5467 16.4658 15.8385C16.4658 16.1745 16.3323 16.4967 16.0948 16.7342C15.8572 16.9718 15.535 17.1052 15.1991 17.1052C15.0377 17.1119 14.8766 17.085 14.7261 17.0261C14.5757 16.9672 14.4391 16.8777 14.3251 16.7632L9.4991 11.8739L4.62243 16.7505C4.51541 16.8611 4.38755 16.9493 4.24624 17.0102C4.10492 17.0711 3.95295 17.1034 3.7991 17.1052C3.46316 17.1052 3.14098 16.9718 2.90343 16.7342C2.66588 16.4967 2.53243 16.1745 2.53243 15.8385C2.52948 15.6832 2.55829 15.529 2.6171 15.3852C2.67591 15.2415 2.76348 15.1112 2.87443 15.0025L7.80177 10.1385L2.87443 5.21121C2.66567 5.00697 2.54325 4.7304 2.53243 4.43854C2.53243 4.1026 2.66588 3.78042 2.90343 3.54287C3.14098 3.30533 3.46316 3.17188 3.7991 3.17188C4.1031 3.17568 4.39443 3.29854 4.60977 3.51387Z"
                      fill="#B4B0B0"
                    />
                  </svg>
                </div>
                <div>
                  <img src={images.task1} className="w-100" alt="" />
                </div>
                <h4 className="mt-2 h6 mb-1">Tasks per Dept.</h4>
              </div>
            </div>
          )}

          {autoGuide.showDept && (
            <div className="taskbox2 zi-120">
              <div className="tsk2 taskbox-shadow mb-3 px-3 pb-3 pt-1 text-center bg-white">
                <div
                  className="tskdismiss2 text-right py-2 cursor-pointer"
                  onClick={() =>
                    setAutoGuide({
                      ...autoGuide,
                      showDept: false,
                    })
                  }
                >
                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.60977 3.51387L9.4991 8.40321L14.3631 3.53921C14.4705 3.42485 14.6 3.33337 14.7436 3.27025C14.8873 3.20713 15.0422 3.17367 15.1991 3.17188C15.535 3.17188 15.8572 3.30533 16.0948 3.54287C16.3323 3.78042 16.4658 4.1026 16.4658 4.43854C16.4687 4.59384 16.4399 4.7481 16.3811 4.89186C16.3223 5.03562 16.2347 5.16584 16.1238 5.27454L11.1964 10.1385L16.1238 15.0659C16.3325 15.2701 16.4549 15.5467 16.4658 15.8385C16.4658 16.1745 16.3323 16.4967 16.0948 16.7342C15.8572 16.9718 15.535 17.1052 15.1991 17.1052C15.0377 17.1119 14.8766 17.085 14.7261 17.0261C14.5757 16.9672 14.4391 16.8777 14.3251 16.7632L9.4991 11.8739L4.62243 16.7505C4.51541 16.8611 4.38755 16.9493 4.24624 17.0102C4.10492 17.0711 3.95295 17.1034 3.7991 17.1052C3.46316 17.1052 3.14098 16.9718 2.90343 16.7342C2.66588 16.4967 2.53243 16.1745 2.53243 15.8385C2.52948 15.6832 2.55829 15.529 2.6171 15.3852C2.67591 15.2415 2.76348 15.1112 2.87443 15.0025L7.80177 10.1385L2.87443 5.21121C2.66567 5.00697 2.54325 4.7304 2.53243 4.43854C2.53243 4.1026 2.66588 3.78042 2.90343 3.54287C3.14098 3.30533 3.46316 3.17188 3.7991 3.17188C4.1031 3.17568 4.39443 3.29854 4.60977 3.51387Z"
                      fill="#B4B0B0"
                    />
                  </svg>
                </div>
                <div>
                  <img src={images.task3} className="w-100" alt="" />
                </div>
                Dept. Name
              </div>
            </div>
          )}

          {autoGuide.showValue && (
            <div className="taskbox3 zi-120">
              <div className="tsk3 taskbox-shadow mb-3 px-3 pb-3 pt-1 text-center bg-white">
                <div
                  className="tskdismiss3 text-right py-2 cursor-pointer"
                  onClick={() =>
                    setAutoGuide({
                      ...autoGuide,
                      showValue: false,
                    })
                  }
                >
                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.60977 3.51387L9.4991 8.40321L14.3631 3.53921C14.4705 3.42485 14.6 3.33337 14.7436 3.27025C14.8873 3.20713 15.0422 3.17367 15.1991 3.17188C15.535 3.17188 15.8572 3.30533 16.0948 3.54287C16.3323 3.78042 16.4658 4.1026 16.4658 4.43854C16.4687 4.59384 16.4399 4.7481 16.3811 4.89186C16.3223 5.03562 16.2347 5.16584 16.1238 5.27454L11.1964 10.1385L16.1238 15.0659C16.3325 15.2701 16.4549 15.5467 16.4658 15.8385C16.4658 16.1745 16.3323 16.4967 16.0948 16.7342C15.8572 16.9718 15.535 17.1052 15.1991 17.1052C15.0377 17.1119 14.8766 17.085 14.7261 17.0261C14.5757 16.9672 14.4391 16.8777 14.3251 16.7632L9.4991 11.8739L4.62243 16.7505C4.51541 16.8611 4.38755 16.9493 4.24624 17.0102C4.10492 17.0711 3.95295 17.1034 3.7991 17.1052C3.46316 17.1052 3.14098 16.9718 2.90343 16.7342C2.66588 16.4967 2.53243 16.1745 2.53243 15.8385C2.52948 15.6832 2.55829 15.529 2.6171 15.3852C2.67591 15.2415 2.76348 15.1112 2.87443 15.0025L7.80177 10.1385L2.87443 5.21121C2.66567 5.00697 2.54325 4.7304 2.53243 4.43854C2.53243 4.1026 2.66588 3.78042 2.90343 3.54287C3.14098 3.30533 3.46316 3.17188 3.7991 3.17188C4.1031 3.17568 4.39443 3.29854 4.60977 3.51387Z"
                      fill="#B4B0B0"
                    />
                  </svg>
                </div>
                <div>
                  <img src={images.task2} className="w-100" alt="" />
                </div>
                <h4 className="mt-2 h6 mb-1">
                  <h4 className="mt-2 h6 mb-0">Value of each Task</h4>
                  <small>(No. of employee x Avg. salary)</small>
                </h4>
              </div>
            </div>
          )}
        </>
      );
  };
  return render();
};

export default AIAutoGuide;
