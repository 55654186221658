import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useAISocialMediaContext } from "../../../../../../context/ai-social-media.context";
import { useState } from "react";

const AdItem = (props) => {
  const [read, setRead] = useState(true);
  const { item, rowIndex, editable } = props;
  const [adText, setAdText] = useState(item.description);
  const { adContent, updateAdContent } = useAISocialMediaContext();

  const onAdSubmitted = (e) => {
    e.preventDefault();
    if (adText) {
      const list = adContent?.slice();
      const data = { ...item, description: adText };
      list.splice(rowIndex, 1, data);
      updateAdContent(list);
      setRead(!read);
    }
  };

  const render = () => {
    return (
      <div className="mt-2 mb-5">
        <div className={`text-right me-auto addtitle2`}>
          <h4>Ad #{rowIndex + 1}</h4>
        </div>
        <div className="ReviwContent lighorangebg rounded">
          <h3 className="text-orange mb-0 px-3 pt-3 d-flex justify-content-between align-items-center">
            <p className="m-0"> Ad Content</p>
            {editable && (
              <Link onClick={() => setRead(!read)}>
                <FontAwesomeIcon icon={faEdit} size="xs" />
              </Link>
            )}
          </h3>
          {read && <p className="p-3">{item.description}</p>}
          {!read && (
            <form
              onSubmit={onAdSubmitted}
              noValidate
              className="container text-center"
            >
              <textarea
                className="form-group form-control"
                value={adText}
                onChange={(e) => setAdText(e.target.value)}
              />
              <button className="btn1 mb-3" type="submit">
                Done
              </button>
            </form>
          )}
        </div>
      </div>
    );
  };
  return render();
};

export default AdItem;
