import React from "react";
import { Link } from "react-router-dom";

const KnowledgeAIList = (props) => {
  const { predictions = [], onItemSelected } = props;

  const renderPredictionList = () => {
    if (predictions.length)
      return predictions
        .map((item, rowIndex) => {
          const qmark = item.data.query;
          return (
            <li key={rowIndex} className="ai-knowledge-list-item my-3">
              <Link
                className="ai-kw-ai-question"
                onClick={() => onItemSelected(item, rowIndex)}
              >
                {item.data.query}
              </Link>
            </li>
          );
        })
        .reverse();
    return <p className="text-gray">No past predictions.</p>;
  };

  const render = () => {
    return (
      <>
        <ul className="ai-knowledge-list">
          <h3>Past Questions</h3>
          {renderPredictionList()}
        </ul>
      </>
    );
  };
  return render();
};

export default KnowledgeAIList;
