import React, { Fragment } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { BasePageContainer } from "../../components/common";
import CustomLoader from "../../components/common/CustomLoader";
import Protected from "../../components/common/Protected";
import { useAIModelContext } from "../../context/ai-model.context";
import { useAppContext } from "../../context/app.context";
import { useScreenSizeContext } from "../../context/mobile-layout.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const DashboardPage = () => {
  const { showLeftMenu } = useAppContext();
  const { isLoading } = useAIModelContext();
  const { pathname, hash } = useLocation();
  const { isMobileScreen } = useScreenSizeContext();

  const renderHome = () => {
    if (isMobileScreen && pathname !== "/")
      return (
        <Link
          className="px-3 font-weight-bold d-flex align-items-center"
          onClick={() => {
            window.history.back();
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <p className="m-0 ml-1 p-0"></p>Back
        </Link>
      );
  };

  return (
    <>
      {isLoading && <CustomLoader full />}
      <Protected>
        <BasePageContainer>
          <section
            className={`dashboardwrap ${!showLeftMenu && !isMobileScreen ? "active" : ""} ${isMobileScreen ? "ml-0 top-80" : ""}`}
          >
            {renderHome()}
            <Outlet />
          </section>
        </BasePageContainer>
      </Protected>
    </>
  );
};

export default DashboardPage;
