import React from "react";
import SocialMediaHeader from "./SocialMediaHeader";
import "./social-media.css";
import SocialMediaRouter from "./SocialMediaRouter";
import { AISocailMediaProvider } from "../../../../../context/ai-social-media.context";

const SocialMediaResult = () => {
  const render = () => {
    return (
      <AISocailMediaProvider>
        <div className="social-media-result">
          <SocialMediaHeader
            header={{ name: "", link: "", logo: "", placeId: "" }}
          >
            <SocialMediaRouter />
          </SocialMediaHeader>
        </div>
      </AISocailMediaProvider>
    );
  };

  return render();
};

export default SocialMediaResult;
