import constant from "./constant";

let sharedInfo = {};

export const setSharedInfo = (key, data) => {
  if (key) sharedInfo[key] = data;
};

export const getSharedInfo = (key) => {
  return sharedInfo[key];
};

export const clearSharedInfo = (key) => {
  sharedInfo[key] = null;
};

export const setStorageData = (key, url) => {
  window.localStorage.setItem(key, url);
};

export const getStorageData = (key) => {
  return window.localStorage.getItem(key);
};

export const clearStorage = (key = "") => {
  if (key) {
    window.localStorage.removeItem(key);
  } else {
    window.localStorage.clear();
  }
};

export const setZohoRedirectUrl = (url) => {
  setStorageData(constant.zohoRedirect, url);
};

export const getZohoRedirectUrl = () => {
  return getStorageData(constant.zohoRedirect);
};

export const clearZohoRedirectUrl = () => {
  return window.localStorage.getItem(constant.zohoRedirect);
};
