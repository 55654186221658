import React from "react";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faDownload } from "@fortawesome/free-solid-svg-icons";

export const ICFilledForm = () => {
  const { aiDataSelectedForm, hideRightMenu } = useAIModelContext();

  const renderAIFilledForm = () => {
    const { gcs_source } = aiDataSelectedForm;
    return <></>;
  };

  const render = () => {
    return renderAIFilledForm();
  };
  return render();
};

export default ICFilledForm;
