import React, { useEffect, useRef, useState } from "react";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import { useAppContext } from "../../../../../context/app.context";
import { useScreenSizeContext } from "../../../../../context/mobile-layout.context";
import { useWebSocket } from "./../../../../../hooks/useWebSocket";
import constant, { SOCKET, SensorType } from "../../../../../helper/constant";
import PredLineChart from "../../../../common/charts/PredLineChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocation, faLocationDot } from "@fortawesome/free-solid-svg-icons";

const SensorLogger = () => {
  const { selectedPredictionModel } = useAIModelContext();
  const { updateLoader, isLoading, showAlert } = useAppContext();
  const { isMobileScreen } = useScreenSizeContext();
  const [accData, setAccData] = useState();
  const { status, messages, sendMessage } = useWebSocket(
    constant.loggerTestDeviceId
  );
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    if (status === SOCKET.CONNECTED) {
    }
  }, [status]);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation({ latitude, longitude });
      },
      // if there was an error getting the users location
      (error) => {
        console.error("Error getting user location:", error);
      }
    );
  }, []);

  const renderOrientationChart = () => {
    if (messages.length) {
      const logs = messages.filter(
        (item) => item.name === SensorType.Orientation
      );
      return <OrientationChart logs={logs} />;
    }
  };

  const renderMicroPhoneChart = () => {
    if (messages.length) {
      const logs = messages.filter(
        (item) => item.name === SensorType.Microphone
      );
      return <MicrophoneChart logs={logs} />;
    }
  };

  const renderLightSensorChart = () => {
    if (messages.length) {
      const logs = messages.filter(
        (item) => item.name === SensorType.LightSensor
      );
      return <LightSensorChart logs={logs} />;
    }
  };

  const renderAccelerometerChart = () => {
    if (messages.length) {
      const logs = messages.filter(
        (item) => item.name === SensorType.Accelarometer
      );
      return <AccelerometerChart logs={logs} />;
    }
  };

  const renderGravityChart = () => {
    if (messages.length) {
      const logs = messages.filter((item) => item.name === SensorType.Gravity);
      return <AccelerometerChart logs={logs} />;
    }
  };

  const renderGyroscopeChart = () => {
    if (messages.length) {
      const logs = messages.filter(
        (item) => item.name === SensorType.Gyroscope
      );
      return <AccelerometerChart logs={logs} />;
    }
  };

  const renderMagnetometerChart = () => {
    if (messages.length) {
      const logs = messages.filter(
        (item) => item.name === SensorType.Magnatometer
      );
      return <AccelerometerChart logs={logs} />;
    }
  };

  const render = () => {
    return (
      <div className="d-flex flex-column gap-2 pb-5">
        <div
          className={`d-flex justify-content-between gap-2 pt-2 ${isMobileScreen ? "flex-column" : ""}`}
        >
          <h5>Sensor Stream</h5>
          <div className="d-flex flex-column gap-1">
            <p className="p-0">
              Device Id :{" "}
              <span className="strong">{constant.loggerTestDeviceId}</span>
            </p>
            {userLocation && (
              <p className={`p-0 ${isMobileScreen ? "" : "align-self-end"}`}>
                <FontAwesomeIcon icon={faLocationDot} />
                <span className="strong ml-1">
                  Lat: {userLocation.latitude.toFixed(3)}, Lang:{" "}
                  {userLocation.longitude.toFixed(3)}
                </span>
              </p>
            )}
          </div>
        </div>
        {renderOrientationChart()}
        {renderMicroPhoneChart()}
        {renderLightSensorChart()}
        {renderAccelerometerChart()}
        {renderMagnetometerChart()}
        {renderGravityChart()}
        {renderGyroscopeChart()}
        {/* {JSON.stringify(messages)} */}
      </div>
    );
  };

  return render();
};

export default SensorLogger;

const MicrophoneChart = (props) => {
  const { logs } = props;

  const transformLineConfig = () => {
    if (logs.length) {
      const transformData = logs.map((item, index) => {
        let data = {};
        const {
          values: { dBFS },
          time,
        } = item;
        data["name"] = index;
        data["dBFS"] = dBFS;
        return data;
      });

      return {
        title: logs[0].name,
        labels: ["Samples", ""],
        datakeys: ["name", "dBFS"],
        data: transformData,
      };
    }
  };
  const render = () => {
    const config = transformLineConfig();
    return <PredLineChart config={config} />;
  };
  return render();
};

const OrientationChart = (props) => {
  const { logs } = props;

  const transformLineConfig = () => {
    if (logs.length) {
      const transformData = logs.map((item, index) => {
        let data = {};
        const {
          values: { pitch, yaw, roll },
          time,
        } = item;
        data["name"] = index;
        data["pitch"] = pitch;
        data["yaw"] = yaw;
        data["roll"] = roll;
        return data;
      });

      return {
        title: logs[0].name,
        labels: ["Samples", ""],
        datakeys: ["name", "pitch", "yaw", "roll"],
        data: transformData,
      };
    }
  };
  const render = () => {
    const config = transformLineConfig();
    return <PredLineChart config={config} />;
  };
  return render();
};

const AccelerometerChart = (props) => {
  const { logs } = props;

  const transformLineConfig = () => {
    if (logs.length) {
      const transformData = logs.map((item, index) => {
        let data = {};
        const {
          values: { x, y, z },
          time,
        } = item;
        data["name"] = index;
        data["x"] = x;
        data["y"] = y;
        data["z"] = z;
        return data;
      });

      return {
        title: logs[0].name,
        labels: ["Samples", ""],
        datakeys: ["name", "x", "y", "z"],
        data: transformData,
      };
    }
  };
  const render = () => {
    const config = transformLineConfig();
    return <PredLineChart config={config} />;
  };
  return render();
};

const LightSensorChart = (props) => {
  const { logs } = props;

  const transformLineConfig = () => {
    if (logs.length) {
      const transformData = logs.map((item, index) => {
        let data = {};
        const {
          values: { lux },
          time,
        } = item;
        data["name"] = index;
        data["lux"] = lux;
        return data;
      });

      return {
        title: logs[0].name,
        labels: ["Samples", ""],
        datakeys: ["name", "lux"],
        data: transformData,
      };
    }
  };
  const render = () => {
    const config = transformLineConfig();
    return <PredLineChart config={config} />;
  };
  return render();
};
