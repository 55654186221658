import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = (props) => {
  const { msg, title } = props;
  return (
    <section className="d-flex text-center align-items-center justify-content-cetnter page-404">
      <div className=" col-md-4 mx-auto ">
        <svg
          width="340"
          height="190"
          viewBox="0 0 490 330"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="244.802" cy="180.149" r="80.6876" fill="#0E3E77" />
          <path
            d="M213.93 122.506C213.229 120.168 211.124 113.385 208.317 104.966C204.145 92.448 197.091 85.4316 192.18 85.4317C186.836 85.4317 177.445 85.3199 169.727 104.966C162.01 124.611 160.606 130.336 147.977 136.539C139.063 140.917 125.524 135.726 113.597 133.732C101.67 131.739 79.217 133.732 79.217 133.732"
            stroke="#217CE8"
            strokeWidth="5"
            strokeLinecap="round"
          />
          <path
            d="M210.02 159.75C217.483 154.838 225.735 151.33 236.743 150.674C234.124 140.032 230.969 130.752 225.735 126.027C215.001 118.122 204.153 129.304 203.768 133.377C202.342 137.206 207.193 152.15 210.02 159.75Z"
            fill="#007BFF"
          />
          <path
            d="M218.658 125.172C220.799 126.051 225.647 140.072 228.008 147.909L234.154 147.909C233.621 145.717 232.218 142.209 231.329 139.73C227.382 124.974 215.982 124.072 218.658 125.172Z"
            fill="white"
            fillOpacity="0.5"
          />
          <ellipse
            cx="223.101"
            cy="154.523"
            rx="14.0876"
            ry="2.68615"
            transform="rotate(-18.0894 223.101 154.523)"
            fill="#0751A2"
          />
          <ellipse
            cx="229.002"
            cy="152.628"
            rx="1.76915"
            ry="1.24674"
            transform="rotate(-18.0894 229.002 152.628)"
            fill="white"
          />
          <ellipse
            cx="216.402"
            cy="156.924"
            rx="1.76915"
            ry="1.15502"
            transform="rotate(-18.0894 216.402 156.924)"
            fill="white"
          />
          <path
            d="M287.6 217.756C300.229 224.304 326.922 227.952 347.238 199.513C364.078 175.941 360.569 161.491 368.989 151.385C375.665 143.371 380.917 148.996 386.53 156.012C391.02 161.625 391.44 165.552 396.353 168.641C401.265 171.73 416 168.641 416 168.641"
            stroke="#217CE8"
            strokeWidth="5"
            strokeLinecap="round"
          />
          <line
            x1="257.405"
            y1="188.857"
            x2="263.328"
            y2="192.806"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <line
            x1="248.985"
            y1="200.084"
            x2="254.909"
            y2="204.033"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <path
            d="M267.253 186.467L251.115 209.621C259.535 216.637 268.656 222.952 275.672 223.653C289.003 223.653 291.108 208.217 289.003 204.709C287.88 200.78 274.035 190.91 267.253 186.467Z"
            fill="#007BFF"
          />
          <path
            d="M259.426 206.113C265.975 211.025 279.633 220.707 281.878 220.146C284.685 219.444 274.97 225.478 263.043 215.936C259.171 212.838 257.106 211.353 256.119 210.744L255.918 211.025C255.286 210.519 254.959 210.028 256.119 210.744L259.426 206.113Z"
            fill="white"
            fillOpacity="0.5"
          />
          <line
            x1="216.276"
            y1="213.63"
            x2="226.407"
            y2="211.941"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <line
            x1="223.493"
            y1="229.949"
            x2="231.802"
            y2="223.91"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <line
            x1="241.412"
            y1="239.475"
            x2="244.581"
            y2="229.705"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <line
            x1="268.886"
            y1="157.68"
            x2="258.786"
            y2="155.816"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <line
            x1="267.659"
            y1="139.876"
            x2="257.791"
            y2="142.724"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <line
            x1="254.057"
            y1="124.82"
            x2="247.749"
            y2="132.926"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <path
            d="M9.8 209V181.4L73 91H111.2V179.4H127.6V209H111.2V235H77V209H9.8ZM79.4 128.6L45.8 179.4H79.4V128.6ZM357.066 209V181.4L420.266 91H458.466V179.4H474.866V209H458.466V235H424.266V209H357.066ZM426.666 128.6L393.066 179.4H426.666V128.6Z"
            fill="black"
          />
        </svg>
        <h1 className="h2 font-weight-bold">Page not Found</h1>
        <Link className="btn1 mt-3 font-weight-bold w-100" to={"/"}>
          BACK TO HOME
        </Link>
      </div>
    </section>
  );
};

export default PageNotFound;
