// user.reducer.js
import { useReducer } from "react";
import * as ACTION from "./types";

const initialState = {
  icList: [],
  selectedICIndex: null,
};

const reducer = (state = initialState, action) => {
  console.log(action);
  switch (action.type) {
    case ACTION.SET_SELECTED_AI_IMAGE_CLASSIFICATION:
      return {
        ...state,
        selectedICIndex: action.payload,
      };
    case ACTION.SET_AI_IMAGE_CLASSIFICATION:
      return {
        ...state,
        icList: action.payload,
      };
    default:
      return state;
  }
};

const useICReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    state,
    dispatch,
  };
};

export default useICReducer;
