import React, { useState } from "react";
import images from "../../helper/images";
import { BasePageContainer } from "../../components/common";
import UnsignedForm from "../../components/auth/UnsingedForm";
import PredDonutChart from "./../../components/common/charts/PredDonutChart";
import { useAuthContext } from "../../context/auth.context";
import {
  calculatePercentage,
  formatToCurrency,
  sumOfObject,
  validateEmail,
  validateOrgName,
} from "../../helper/helper";
import constant, { ToastType } from "../../helper/constant";
import { Link, useNavigate } from "react-router-dom";
import ModalComponent from "../../components/common/ModalComponent";
import Recapcha from "../../components/auth/Recapcha";
import { useAppContext } from "../../context/app.context";
import { sendEmailReport } from "../../service/auth.service";
import useInput from "../../hooks/useInput";
import CustomLoader from "../../components/common/CustomLoader";
import { useScreenSizeContext } from "../../context/mobile-layout.context";

const UnsignedPage = () => {
  const { revenueReportNew, updateUnsignedUserInfo } = useAuthContext();
  const { showAlert } = useAppContext();
  const [showModal, setShowModal] = useState(false);
  const [companyName, bindCompanyname, resetCompanyName] = useInput("");
  const [businessEmail, bindBusinessEmail, resetBusinessEmail] = useInput("");
  const [form, setForm] = useState({ error: { valid: false } });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isMobileScreen } = useScreenSizeContext();

  const renderDonutChart = () => {
    if (revenueReportNew.additionalProfit) {
      const report = getRevenueReport();
      return <PredDonutChart data={report} center={renderCenter} />;
    }
    return (
      <div className="d-flex justify-content-center unsigned-chart-container">
        <img
          src={images.donutChart}
          alt={"report"}
          className="unsigned-placeholder"
        />
      </div>
    );
  };

  const handleValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!companyName) {
      formIsValid = false;
      error["companyName"] = "Company name required.";
    }
    if (!validateOrgName(companyName)) {
      formIsValid = false;
      error["companyName"] = "Company name required.";
    }

    if (!businessEmail) {
      formIsValid = false;
      error["businessEmail"] = "Email is required.";
    }

    if (!validateEmail(businessEmail)) {
      formIsValid = false;
      error["businessEmail"] = "Email is required.";
    }

    setForm({ error });
    return formIsValid;
  };

  const handleModalClose = () => setShowModal(false);

  const getRevenueReport = () => {
    return [
      {
        name: "revenue Increase",
        value: revenueReportNew.report.revenueIncrease,
      },
      {
        name: "material cost reduction",
        value: revenueReportNew.report.matCostReduction,
      },
      {
        name: "manpower efficiencies",
        value: revenueReportNew.report.manPowerEff,
      },
      {
        name: "machine cost reduction",
        value: revenueReportNew.report.macCostReduction,
      },
    ];
  };

  const renderModal = () => {
    return (
      <ModalComponent show={showModal} handleClose={handleModalClose} autoClose>
        <form action="#">
          <div className={`form-group row `}>
            <label className="col-sm-5 col-form-label">
              Company Name <span className="text-danger">*</span>
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                className={`form-control ${
                  form.error["companyName"] ? "error-br" : ""
                }`}
                {...bindCompanyname}
              />
              {form.error["companyName"] && (
                <small className="text-danger">
                  {form.error["companyName"]}
                </small>
              )}
            </div>
          </div>
          <div className={`form-group row `}>
            <label className="col-sm-5 col-form-label">
              Business Email <span className="text-danger">*</span>
            </label>
            <div className="col-sm-7">
              <input
                type="email"
                className={`form-control ${
                  form.error["businessEmail"] ? "error-br" : ""
                }`}
                {...bindBusinessEmail}
              />
              {form.error["businessEmail"] && (
                <small className="text-danger">
                  {form.error["businessEmail"]}
                </small>
              )}
            </div>
          </div>
          <div className="text-center">
            <Recapcha onVerification={() => {}} />
          </div>

          <div className="mt-5 text-center">{renderGetEmailButton()}</div>
        </form>
      </ModalComponent>
    );
  };

  const renderGetEmailButton = () => {
    if (loading) return <CustomLoader masked />;
    return (
      <Link className="btn1 btn1next" onClick={onGetReportByMail}>
        Get Report in Email
      </Link>
    );
  };

  const renderCenter = (cx, cy) => {
    const additionalProfit = sumOfObject(revenueReportNew.report);
    if (additionalProfit) {
      const percentage = calculatePercentage(
        additionalProfit,
        revenueReportNew.revenue + additionalProfit
      ).toFixed(2);
      const dimensions = isMobileScreen
        ? constant.donutDimension.mobile
        : constant.donutDimension.desktop;
      return (
        <>
          <text
            x={cx - dimensions.centerXOffset}
            y={cy - dimensions.centerYOffset}
            dy={8}
            textAnchor="middle"
            fill="#000000"
            fontSize={isMobileScreen ? 20 : 24}
            fontWeight="bold"
          >
            <tspan>Additional Profit*</tspan>
          </text>
          <text
            x={cx}
            y={cy + (isMobileScreen ? 5 : 15)}
            dy={8}
            textAnchor="middle"
            fill="#217CE8"
            fontSize={isMobileScreen ? 20 : 28}
            fontWeight="bold"
          >
            {formatToCurrency(additionalProfit)}
          </text>
          <text
            x={cx}
            y={cy + (isMobileScreen ? 30 : 35)}
            dy={8}
            textAnchor="middle"
            fill="#217CE8"
            fontSize={18}
            fontWeight="bold"
          >
            {`(${percentage}%)`}
          </text>
        </>
      );
    }
  };

  const getDetailsBtn = () => {
    if (revenueReportNew.additionalProfit)
      return (
        <div className={`text-center ${isMobileScreen ? "mt-3" : "mt-5"}`}>
          <button
            className="btn1 extrapadding font-weight-bold"
            onClick={() => setShowModal(true)}
          >
            GET DETAILS
          </button>
        </div>
      );
  };

  const onGetReportByMail = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      const payload = {
        replacements: {
          $ABC: `${companyName}`,
          $PROFIT: `${formatToCurrency(revenueReportNew.afterTotalExpense)}`,
          $RDI: `${revenueReportNew.roi}%`,
          $Cost: `${formatToCurrency(revenueReportNew.delay_cost)}`,
          $Period: `${revenueReportNew.payback_period} Mos`,
          $REV: `${formatToCurrency(revenueReportNew.report.revenueIncrease)}`,
          $RED: `${formatToCurrency(revenueReportNew.report.matCostReduction)}`,
          $EFF: `${formatToCurrency(revenueReportNew.report.manPowerEff)}`,
          $MACH: `${formatToCurrency(
            revenueReportNew.report.macCostReduction
          )}`,
        },
        email: businessEmail,
      };
      setLoading(true);
      sendEmailReport(payload)
        .then((res) => {
          updateUnsignedUserInfo({
            org_name: companyName,
            org_mail: businessEmail,
          });
          navigate(`/${constant.PAGES.AUTH.VALUE_CALCULATOR_REPORT}`);
        })
        .catch((ex) => {
          setLoading(false);
          showAlert(constant.MESSAGES.COMMON_ERROR_MESSAGE, ToastType.ERROR);
        });
    }
  };

  const render = () => {
    return (
      <BasePageContainer hideFooter>
        <section
          className={`unsigned-page-header text-center bg-blue text-white d-flex justify-content-center flex-column gap-1 ${isMobileScreen ? "py-2 px-1" : "py-5"}`}
        >
          <p
            className={`mx-auto p-0 ${isMobileScreen ? "px-1 font-weight-bold" : "w-50 fs-30"}`}
          >
            Provide your company details & get AI generated predictions in few
            seconds
          </p>
          <p
            className={`m-0 p-0 mx-auto ${isMobileScreen ? "px-1" : "w-50 fs-20 px-5 "}`}
          >
            Detailed status report of your business is just a click away
          </p>
        </section>

        <section className="py-4">
          <div className={`m-auto ${isMobileScreen ? "w-90" : "w-70"}`}>
            <div className="row align-items-center">
              <div className="col-md-4">
                <UnsignedForm />
              </div>
              <div className="col-md-8">
                {renderDonutChart()}
                {getDetailsBtn()}
              </div>
            </div>
          </div>
          {renderModal()}
        </section>
      </BasePageContainer>
    );
  };

  return render();
};

export default UnsignedPage;
