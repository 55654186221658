import React, { useEffect, useState } from "react";
import { useAIModelContext } from "../../../../context/ai-model.context";
import ModalComponent from "../../../common/ModalComponent";
import MultiUploadLayout from "./MultiUploadLayout";
import { useAppContext } from "../../../../context/app.context";
import CustomLoader from "../../../common/CustomLoader";
import { ModelType } from "../../../../helper/constant";
import { DocumentAIFilledForm } from "./documentAI/DocumentAITraning";
import { useICContext } from "../../../../context/ai-ic.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

const MultipleUploadForm = (props) => {
  const { aiDataSelectedForm, hideRightMenu } = useAIModelContext();
  const { setSelectedICIndex } = useICContext();
  const { isLoading } = useAppContext();
  const [showModal, setShowModal] = useState(false);

  const renderMultipleForm = () => {
    return (
      <>
        {isLoading && <CustomLoader full />}
        <MultiUploadLayout onCompleted={() => onModalClose()} />
      </>
    );
  };

  const onModalClose = () => {
    setShowModal(false);
    if (!props?.hideClose) hideRightMenu();
  };

  const renderModal = () => {
    if (aiDataSelectedForm) {
      const { model_name } = aiDataSelectedForm;
      return (
        <div className="ai-training-multi-form-modal">
          <ModalComponent
            full
            height="80vh"
            title={`Model Training: ${model_name}`}
            show={showModal}
            handleClose={onModalClose}
          >
            {renderMultipleForm()}
          </ModalComponent>
        </div>
      );
    }
  };

  const renderUploadButton = () => {
    return (
      <div className="form-group">
        <button
          type="button"
          className="btn1 btn radious-0 w-100 mt-2 f-13 text-uppercase py-2 font15fontweight600"
          onClick={() => {
            if (props) setSelectedICIndex(props.buttonIndex);
            setShowModal(true);
          }}
        >
          {" "}
          <FontAwesomeIcon icon={faUpload} /> Upload Files
        </button>
      </div>
    );
  };

  const render = () => {
    return (
      <>
        {renderModal()}
        <div className="d-flex align-items-stretch justify-content-center flex-column gap-3">
          {renderUploadButton()}
          {!props?.hideClose && (
            <div className="form-group">
              <button
                type="button"
                className="btn1 btn radious-0 w-100 mt-2 f-13 text-uppercase py-2 bg-light-grey font15fontweight600"
                onClick={() => hideRightMenu()}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </>
    );
  };
  return render();
};

export default MultipleUploadForm;
