import React, { useEffect, useRef, useState } from "react";
import { useAIModelContext } from "./../../../../../context/ai-model.context";
import { useAppContext } from "./../../../../../context/app.context";
import {
  getKnowledgeAIPrediction,
  getSocialMediaModel,
  requestKnowledgePrediction,
} from "../../../../../service/ai-model.service";
import KnowledgeAIList from "./knowledge-ai-list";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faXmarkCircle,
} from "@fortawesome/free-regular-svg-icons";
import { useParams } from "react-router-dom";
import { getUUID } from "./../../../../../helper/helper";
import CustomLoader from "./../../../../common/CustomLoader";

const KnowledgeAI = () => {
  const { selectedPredictionModel } = useAIModelContext();
  const params = useParams();
  const datasetId = useRef();
  const { updateLoader, showAlert, isLoading } = useAppContext();
  const [predictionList, setPredictionList] = useState([]);
  const [activeQuery, setActiveQuery] = useState({
    datasetId: "",
    query: "",
    answer: "",
  });

  useEffect(() => {
    getSocialMediaModel(selectedPredictionModel.taskId)
      .then((r) => {
        if (r && r.length) datasetId.current = r[0].input.datasetId;
      })
      .catch((ex) => console.log(ex));
  }, [selectedPredictionModel, datasetId]);

  useEffect(() => {
    if (params && params.id && selectedPredictionModel.taskId) {
      updateLoader(true);
      getKnowledgeAIPrediction(params.id, selectedPredictionModel.taskId)
        .then((result) => {
          if (result) setPredictionList(result);
        })
        .finally(() => updateLoader(false));
    }
  }, [selectedPredictionModel, params]);

  const onItemSelected = (item, rowIndex) => {
    setActiveQuery({
      answer: item.data.answer,
      query: item.data.query,
      datasetId: datasetId.current,
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    updateLoader(true);
    const payload = {
      id: getUUID(),
      ...activeQuery,
      datasetId: datasetId.current,
    };
    requestKnowledgePrediction(
      params.id,
      selectedPredictionModel.taskId,
      payload,
    )
      .then((result) => {
        const data = {
          ...activeQuery,
          ...result,
          datasetId: datasetId.current,
        };
        setActiveQuery(data);
        const list = predictionList.slice();
        list.push({ data });
        setPredictionList(list);
      })
      .finally(() => updateLoader(false));
  };

  const renderKnowledgeForm = () => {
    return (
      <>
        <p>KnowledgeAI Model ready for prediction</p>
        <form onSubmit={onFormSubmit}>
          <div className="form-group row align-align-items-stretch gap-2">
            <div className="col-xs-12 col-md-12">
              <textarea
                rows={4}
                type="text"
                className="form-control text-area"
                placeholder="Please ask your question"
                value={activeQuery.query}
                onChange={(e) =>
                  setActiveQuery({ ...activeQuery, query: e.target.value })
                }
                name="question"
              />
            </div>
            <div className="col-xs-12 col-md-12">
              <div className="d-flex gap-2 justify-content-end">
                <button
                  type="submit"
                  className="btn1 btn bg-success radious-0 w-15 f-13 text-uppercase py-2 bg-light-grey font15fontweight600"
                >
                  Search
                </button>
                <button
                  type="button"
                  onClick={() =>
                    setActiveQuery({
                      query: "",
                      answer: "",
                      datasetId: datasetId.current,
                    })
                  }
                  className="btn1 btn bg-danger radious-0 w-15 f-13 text-uppercase py-2 bg-light-grey font15fontweight600"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  };

  const renderSearchResult = () => {
    if (activeQuery.answer)
      return (
        <div className="my-2">
          <h5>Answer :</h5>
          <p className="">{activeQuery.answer}</p>
        </div>
      );
  };
  const renderMainContent = () => {
    return (
      <>
        {renderKnowledgeForm()}
        {renderSearchResult()}
      </>
    );
  };
  const render = () => {
    return (
      <>
        {isLoading && (
          <div className="full-mask d-flex justify-content-center">
            <CustomLoader masked />
          </div>
        )}
        <div className="row justify-content-between">
          <div className="col-xs-12 col-md-6 col-lg-3">
            <KnowledgeAIList
              predictions={predictionList}
              onItemSelected={onItemSelected}
            />
          </div>
          <div className="col-xs-12 col-md-6 col-lg-9">
            {renderMainContent()}
          </div>
        </div>
      </>
    );
  };
  return render();
};

export default KnowledgeAI;
