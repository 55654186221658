import React from "react";
import { Link } from "react-router-dom";
import images from "../../../../../helper/images";

const SocialMediaHeader = (props) => {
  const { name, link, logo, placeId } = props.header;
  return (
    <>
      <header className="overflohidden">
        <div className="row align-items-center">
          <div className="col-6 col-md-3 col-lg-3">
            <div className="logo">
              <Link to={``}>
                <img src={images.socialMediaResult.logo} alt="Logo" />
              </Link>
            </div>
          </div>
        </div>
      </header>
      <main className="">{props.children}</main>
      <footer className="footer py-3 text-white text-center">
        <div className="container">
          <div>TM & © 2021 Popeyes Louisiana Kitchen, Inc.</div>
        </div>
      </footer>
    </>
  );
};

export default SocialMediaHeader;
