import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import AutocompleteWithPlaceId from "./AutocompletePlaceId";
import { useAIModelContext } from "../../../../context/ai-model.context";
import { OutletList, ProviderList } from "../../../../helper/constant";
import { getSocialMediaModel } from "../../../../service/ai-model.service";
import CustomLoader from "../../../common/CustomLoader";

const SocialMediaForm = (props) => {
  const { formData, onSubmit, isRead } = props;
  const {
    hideRightMenu,
    aiDataSelectedForm,
    setAIModelFormData,
    saveSocialModelForm,
    aiDataFormValues,
  } = useAIModelContext();
  const [read, setRead] = useState(isRead);
  const [isLoading, setIsLoading] = useState(true);
  const [provider, setProvider] = useState();
  const [outlet, setOutlet] = useState();
  const [place, setPlace] = useState({ formatted_address: "", place_id: "" });
  const providerList = useRef(ProviderList);
  const outletList = useRef(OutletList);
  const [form, setForm] = useState({ error: {} });
  const params = useParams();

  useEffect(() => {
    if (aiDataFormValues.LLM) {
      const { input } = aiDataFormValues;
      setProvider(input.provider);
      setOutlet(input.outlet);
      setPlace({
        formatted_address: input.formatted_address,
        place_id: input.place_id,
      });
      setIsLoading(false);
      setRead(true);
    } else {
      getSocialMediaModel(aiDataSelectedForm.id)
        .then((r) => {
          if (r && r.length) {
            const { input = {} } = r[0];
            setAIModelFormData(r[0]);
            setProvider(input.provider);
            setOutlet(input.outlet);
            setPlace({
              formatted_address: input.formatted_address,
              place_id: input.place_id,
            });
            setRead(true);
          }
        })
        .catch((ex) => console.log(ex))
        .finally(() => setIsLoading(false));
    }
  }, [aiDataSelectedForm]);

  const handleValdiation = () => {
    let isValid = true;
    let error = {};
    if (!provider) {
      isValid = false;
      error["provider"] = "Provider required.";
    }
    if (!outlet) {
      isValid = false;
      error["outlet"] = "Outlet required.";
    }
    if (!provider) {
      isValid = false;
      error["placeId"] = "Place required.";
    }
    setForm({ error });
    return isValid;
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (handleValdiation()) {
      // onSubmit({ provider, outlet, placeId });
      const { formatted_address, place_id } = place;
      const data = {
        id: aiDataSelectedForm.id,
        placeId: place_id,
        templateId: params.id,
        provider,
        modelId: aiDataSelectedForm.modelId,
        input: { provider, outlet, place_id, formatted_address },
      };
      saveSocialModelForm(data);
    }
  };

  const renderGoogleAutoComplete = () => {
    if (read)
      return (
        <div className="h6  f-13">
          <b>Location</b> : {place.formatted_address}
        </div>
      );
    return (
      <AutocompleteWithPlaceId place={place} onSelected={(e) => setPlace(e)} />
    );
  };

  const renderActionButtons = () => {
    if (read)
      return (
        <>
          <div className="form-group">
            <button
              type="button"
              className="btn1 btn radious-0 w-100 mt-2 f-13 text-uppercase py-2 font15fontweight600"
              onClick={(e) => {
                e.preventDefault();
                setRead(false);
              }}
            >
              Change
            </button>
          </div>
          <div className="form-group">
            <button
              type="button"
              className="btn1 btn radious-0  bg-light-grey  w-100 mt-2 f-13 text-uppercase py-2 font15fontweight600"
              onClick={() => hideRightMenu()}
            >
              Close
            </button>
          </div>
        </>
      );

    return (
      <>
        <div className="form-group">
          <button
            type="submit"
            className="btn1 btn radious-0  w-100 mt-2 f-13 text-uppercase py-2 font15fontweight600"
          >
            save
          </button>
        </div>
        <div className="form-group">
          <button
            type="button"
            className="btn1 btn radious-0  bg-light-grey  w-100 mt-2 f-13 text-uppercase py-2 font15fontweight600"
            onClick={() => hideRightMenu()}
          >
            Cancel
          </button>
        </div>
      </>
    );
  };
  const render = () => {
    return (
      <div className="social-medial-form">
        <div className="text-center">{isLoading && <CustomLoader />}</div>
        <form className="sm-form" onSubmit={onFormSubmit}>
          <div className="form-group">
            {read && (
              <div className="h6  f-13">
                <b>Provider</b> : {provider}
              </div>
            )}
            {!read && (
              <select
                className="form-control selectpicker"
                value={provider}
                onChange={(e) => {
                  e.stopPropagation();
                  setProvider(e.target.value);
                }}
                style={{ borderColor: form.error["provider"] ? "red" : "" }}
              >
                <option value="">Provider</option>
                {providerList.current.map((opt) => (
                  <option key={opt.id} value={opt.id}>
                    {opt.value}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="form-group">
            {read && (
              <div className="h6  f-13">
                <b>Outlet</b> : {outlet}
              </div>
            )}
            {!read && (
              <select
                className="form-control selectpicker"
                value={outlet}
                onChange={(e) => {
                  e.stopPropagation();
                  setOutlet(e.target.value);
                }}
                style={{ borderColor: form.error["outlet"] ? "red" : "" }}
              >
                <option value="">Outlet</option>
                {outletList.current.map((opt) => (
                  <option key={opt.id} value={opt.id}>
                    {opt.value}
                  </option>
                ))}
              </select>
            )}
          </div>
          {renderGoogleAutoComplete()}
          {!read && (
            <div className="form-group mt-2">{renderActionButtons()}</div>
          )}
        </form>
      </div>
    );
  };

  return render();
};

export default SocialMediaForm;
