// user.reducer.js
import { useReducer } from "react";
import * as ACTION from "./types";
import { AI_SM_CREATE_AD_IMAGES } from "./types";

const initialState = {
  adContent: null,
  adImages: [],
  reviewKeywords: null,
};

const reducer = (state = initialState, action) => {
  console.log(action);
  switch (action.type) {
    case ACTION.AI_SM_CREATE_AD_CONTENT:
      return {
        ...state,
        adContent: action.payload,
      };
    case ACTION.AI_SM_CREATE_AD_IMAGES:
      return {
        ...state,
        adImages: action.payload,
      };
    case ACTION.AI_SM_GET_REVIEW_KEYWORDS:
      return {
        ...state,
        reviewKeywords: action.payload,
      };
    case ACTION.AI_SM_CLEAR:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

const useAISocialMediaReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return { state, dispatch };
};

export default useAISocialMediaReducer;
