import React, { useEffect, useState } from "react";
import { useAIModelContext } from "../../../context/ai-model.context";
import TaskForm from "./TaskForm";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  calculatePercentage,
  getCalculateExpense,
  getTotalOfProperty,
  getUUID,
} from "../../../helper/helper";
import CustomDropdown from "../../common/CustomDropdown";
import constant, { TaskType, ToastType } from "../../../helper/constant";
import { Link } from "react-router-dom";
import { getDepartmentSuggestedTasks } from "../../../service/ai-model.service";
import { useAuthContext } from "../../../context/auth.context";
import { useAppContext } from "../../../context/app.context";

const DepartmentForm = () => {
  const {
    selectedModel,
    getDepartments,
    saveDepartment,
    departments,
    getTasks,
    hideRightMenu,
    taskList,
    activeTemplate,
  } = useAIModelContext();
  const [read, setRead] = useState(false);
  const [form, setForm] = useState({ error: {} });
  const [department, setDepartment] = useState();
  const [tasks, setTasks] = useState([]);

  const [suggestedBox, toggleSuggestedBox] = useState(false);
  const [suggestedTaskList, setSuggestedTaskList] = useState([]);
  const { userInfo } = useAuthContext();
  const { showAlert } = useAppContext();

  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    if (department) {
      const data = departments.filter((item) => item.id === department);
      if (data.length) {
        getTasks(data[0].department_name?.trim());
        setForm({ error: { department: !department } });
        getSuggestedTasks();
      }
    }
  }, [department]);

  const getSuggestedTasks = () => {
    const { org } = userInfo;
    setSuggestedTaskList([]);
    const data = departments.filter((item) => item.id === department);
    if (data.length) {
      const payload = {
        department: data[0].department_name,
        industry: org.org_config?.industry,
      };
      getDepartmentSuggestedTasks(payload).then((data) => {
        // const taskIdList = tasks.map(item => item.id);
        // const list = data.filter(item => !taskIdList.includes(item.id))
        setSuggestedTaskList(data);
      });
    }
  };

  useEffect(() => {
    if (selectedModel.department) {
      setTasks(selectedModel.tasks);
      setDepartment(selectedModel.department);
      setRead(true);
    } else setForm({ error: { department: "" } });
  }, [selectedModel]);

  const handleValdiation = () => {
    let isValid = true;
    let error = {};
    if (!department) {
      isValid = false;
      error["department"] = "Department required.";
    }
    if (tasks.length) {
      const totalEffort = getTotalOfProperty(tasks, "effort");
      if (totalEffort > 100) {
        isValid = false;
        error["invalid"] = true;
      }
    }
    setForm({ error });
    return isValid;
  };

  const onDepartmentSubmit = (e) => {
    e.preventDefault();
    if (handleValdiation()) {
      const dept = departments.filter((zz) => zz.id === department);
      const totalExpense = getTotalOfProperty(tasks, "expense");
      const newTasks = tasks.map((task) => {
        const effort = calculatePercentage(task.expense, totalExpense);
        return {
          ...task,
          effort,
        };
      });
      if (dept.length) {
        const updateModel = {
          ...selectedModel,
          department,
          departmentName: dept[0].department_name,
          icon: dept[0].icon,
          tasks: newTasks,
          template_image: dept[0].template_image,
        };
        saveDepartment(updateModel, () => {
          clearForm();
        });
      }
    }
  };

  const clearForm = () => {
    setTasks([{}]);
    setDepartment("");
    setForm({ error: {} });
  };

  const isSaveActive = () => {
    let isActive = true;
    //minimum one task
    if (tasks.length === 0) return false;
    //Task name check And Duplicate task
    const emptyTask = tasks.filter((task) => !task.task_name || task.hasError);
    if (emptyTask.length) return false;

    //Task fields check
    const emptyTaskData = tasks.filter((task) =>
      handleTaskFormValidation(task)
    );
    if (emptyTaskData.length) return false;
    return isActive;
  };

  const handleTaskFormValidation = (task) => {
    const { dropdown } = task;
    switch (dropdown) {
      case TaskType.EMPLOYEE:
        const { avgSalary, noOfEmpForTask } = task;
        return !(avgSalary && noOfEmpForTask);
      case TaskType.INVENTORY:
        const { inventory } = task;
        return !inventory;
      case TaskType.CHURNRATE:
        const { churnrate } = task;
        return !churnrate;
      case TaskType.DOWNTIME:
        const { downtime } = task;
        return !downtime;
      default:
        return false;
    }
  };

  const renderActionButtons = () => {
    if (read)
      return (
        <div className="p-412">
          <div className="form-group">
            <button
              type="button"
              className="btn1 btn radious-0  w-100 mt-2 f-13 text-uppercase py-2 font15fontweight600"
              onClick={(e) => {
                e.preventDefault();
                setRead(false);
              }}
            >
              Modify
            </button>
          </div>
          <div className="form-group">
            <button
              type="button"
              className="btn1 btn radious-0  w-100 mt-2 f-13 bg-light-grey  text-uppercase py-2 font15fontweight600"
              onClick={() => hideRightMenu()}
            >
              Close
            </button>
          </div>
        </div>
      );

    return (
      <div className="pb-4">
        <div className="mb-2">
          <button
            type="submit"
            disabled={!isSaveActive()}
            className="btn2 btn radious-0  w-100 mt-2 f-13 text-uppercase py-2 font15fontweight600"
          >
            save
          </button>
        </div>
        <div className="mb-2">
          <button
            type="button"
            className="btn1 btn radious-0  bg-light-grey w-100 mt-2 f-13 text-uppercase py-2 font15fontweight600"
            onClick={() => hideRightMenu()}
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  const onSuggestedTaskSelected = (task) => {
    addSuggestedTaskToList(task);
  };

  const renderSuggestedTaskList = () => {
    if (suggestedTaskList.length)
      return suggestedTaskList.map((task, key) => {
        return (
          <li className="position-relative" key={key}>
            <OverlayTrigger
              trigger={"click"}
              placement="left"
              rootClose
              overlay={
                <Tooltip>
                  <span className="">{task.reason}</span>
                </Tooltip>
              }
            >
              <svg
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.75"
                  stroke="#76869B"
                  strokeWidth="0.5"
                />
                <path
                  d="M4.63177 8.01031L4.62188 3.64668L5.29233 3.64516L5.30223 8.00879L4.63177 8.01031ZM4.96114 2.91864C4.83046 2.91893 4.71767 2.87468 4.62277 2.78588C4.52976 2.69708 4.48312 2.59018 4.48284 2.46518C4.48255 2.34018 4.52871 2.23306 4.62131 2.14384C4.71581 2.05461 4.8284 2.00985 4.95908 2.00955C5.08976 2.00925 5.2016 2.05351 5.29461 2.14231C5.38951 2.23111 5.4371 2.33801 5.43738 2.46301C5.43766 2.58801 5.39056 2.69513 5.29606 2.78436C5.20346 2.87358 5.09182 2.91834 4.96114 2.91864Z"
                  fill="#76869B"
                />
              </svg>
            </OverlayTrigger>
            <Link
              onClick={() => {
                onSuggestedTaskSelected(task);
              }}
              className="f-8"
            >
              {task.task}
            </Link>
          </li>
        );
      });
  };

  const renderSuggestedTasks = () => {
    if (!read && suggestedBox)
      return (
        <div className="sugestedbox justify-content-center">
          <ul className="gap-1">{renderSuggestedTaskList()}</ul>
        </div>
      );
  };
  const addMore = (e) => {
    e.preventDefault();
    if (tasks.length < constant.maxTaskLimit) setTasks([...tasks, {}]);
    else
      showAlert(
        `More than ${constant.maxTaskLimit} Tasks are not allowed.Please prioritize tasks`,
        ToastType.WARNING
      );
  };

  const addSuggestedTaskToList = (task) => {
    const newData = [...tasks];
    const { revenue } = activeTemplate;
    const parentId = selectedModel.id;
    const item = taskList.filter((zz) => zz.id == task.id)[0];
    const isTaskExisting = newData.filter((zz) => zz.id == task.id);
    if (isTaskExisting.length === 0) {
      let { id } = item;
      let taskItem = {
        ...item,
        name: task.task,
        task_name: id,
        noOfEmpForTask: task.employees,
        avgSalary: task.salary,
        parentId,
        taskId: getUUID(),
      };
      taskItem.expense = getCalculateExpense(item.dropdown, taskItem, revenue);
      setTasks([...newData, taskItem]);
    } else {
      showAlert(`Task already exists`, ToastType.WARNING);
    }
  };

  const renderSuggestedTaskBtn = () => {
    if (suggestedTaskList.length > 0)
      return (
        <Link onClick={() => toggleSuggestedBox(!suggestedBox)}>
          <p className=" m-0 p-0 text-blue cursor-pointer f-13 mb-2 Suggestedtask">
            Suggested Tasks
          </p>
        </Link>
      );

    return (
      <div className="w-40">
        <div className="list pre">
          <div className="item line"></div>
        </div>
      </div>
    );
  };

  const renderAddTaskHeading = () => {
    return (
      <>
        <div className="align-items-center justify-content-between mt-2">
          <button
            className="btn3 w-100 btn radious-0  w-25 mt-2 f-13 font15fontweight600"
            onClick={addMore}
            disabled={!department}
            style={!department ? { cursor: "not-allowed" } : {}}
          >
            Add Task
          </button>
        </div>
        {department && renderSuggestedTaskBtn()}
      </>
    );
  };

  const renderDepartmentNewUI = () => {
    if (departments && departments.length) {
      return (
        <CustomDropdown
          hasError={form.error["department"]}
          name="Department"
          options={departments}
          field="department_name"
          value={department}
          onChange={(e) => setDepartment(e)}
        />
      );
    }
  };

  const render = () => {
    const { departmentName } = selectedModel;
    return (
      <form onSubmit={onDepartmentSubmit}>
        <div className="">
          {read && (
            <div className="mb-1">
              <b className="text-blue">Department</b> : {departmentName}
            </div>
          )}
          {!read && renderDepartmentNewUI()}
        </div>
        <div className="d-flex flex-column gap-1">
          {!read && renderAddTaskHeading()}
          {renderSuggestedTasks()}
        </div>
        <div className="py-3">
          {department && (
            <TaskForm setTasks={setTasks} tasks={tasks} read={read} />
          )}
          {form.error["invalid"] && (
            <p className="error-msg">
              Please check for duplicate tasks / efforts.
            </p>
          )}
          {renderActionButtons()}
        </div>
      </form>
    );
  };

  return render();
};

export default DepartmentForm;
