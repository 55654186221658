import React from "react";
import images from "../../../helper/images";
import { Link } from "react-router-dom";

const CreateAgent = () => {
  const renderChooseAgent = () => {
    return (
      <div class="col-md-10 px-0 mx-auto" id="wapper">
        <div class="font-weight-bold mb-2">Choose Agent</div>
        <div class="custom-dropdown form-control bg-white w-100">
          <div class="dropdown-selected ">Select an option</div>
          <div class="dropdown-options">
            <div class="dropdown-item">HR</div>
            <div class="dropdown-item">Sales</div>
            <div class="dropdown-item">Admin</div>
            <div class="dropdown-item">Marketing</div>
            <div class="dropdown-item add-new-option">Add New Agent</div>
          </div>
        </div>
        <div class="form-group mt-4">
          <input
            type="text"
            class="form-control"
            placeholder="Type the Agent Name..."
          />
        </div>
      </div>
    );
  };

  const renderChoosePrompt = () => {
    return (
      <div class="col-md-10 px-0 mx-auto" id="wapper">
        <div class="font-weight-bold mb-2">Choose Prompt</div>
        <div class="custom-dropdown form-control bg-white w-100">
          <div class="dropdown-selected ">Select an option</div>
          <div class="dropdown-options">
            <div class="dropdown-item">HR</div>
            <div class="dropdown-item">Sales</div>
            <div class="dropdown-item">Admin</div>
            <div class="dropdown-item">Marketing</div>
            <div class="dropdown-item add-new-option">Add New Agent</div>
          </div>
        </div>
        <div class="form-group mt-4">
          <input
            type="text"
            class="form-control"
            placeholder="Type the Agent Name..."
          />
        </div>
        <div class="text-right mb-3">
          <a class="text-underline" href="#">
            Custom
          </a>
        </div>
        <textarea
          class="form-control bg-white"
          name=""
          placeholder="Type your own prompt"
          id=""
        ></textarea>
      </div>
    );
  };

  const renderSelectModels = () => {
    return (
      <>
        <div class="font-weight-bold mt-5 mb-2">Assign Models</div>
        <div class="assignmudulesbox row">
          <div class="col-md-6">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
              />
              <label class="form-check-label" htmlhtmlFor="defaultCheck1">
                Generic Strategic Plan
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck2"
              />
              <label class="form-check-label" htmlhtmlFor="defaultCheck2">
                Custom Strategic Plan
              </label>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderAddSources = () => {
    return (
      <>
        <div class="font-weight-bold mt-5 mb-3">Add Source</div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="defaultCheck1161"
          />
          <label class="form-check-label161" htmlFor="defaultCheck1161">
            Ask Me Anything
          </label>
        </div>
        <div>
          <div class="d-flex align-items-center  mt-3">
            <div class="fileUpload w-100">
              <input type="file" id="validatedCustomFile" />
              <span class="bg-white border w-100 px-4 py-2">Select File</span>
            </div>
            <button
              class="bg-white ml-2 text-blue font-weight-bold border px-4 py-2"
              type="submit"
            >
              UPLOAD
            </button>
          </div>
        </div>
        <div class="font-weight-bold mt-5 mb-3">External Source</div>
        <div class="p-3 border d-flex gap-2 flex-wrap">
          <img src={images.zohoIcon} class="w-25" alt="zoho" />
        </div>
      </>
    );
  };

  const renderTools = () => {
    return (
      <>
        <div class="font-weight-bold mt-5 mb-3">Tools</div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="checkbox"
            id="inlineCheckbox1"
            value="option1"
          />
          <label class="form-check-label" htmlFor="inlineCheckbox1">
            Browsing
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="checkbox"
            id="inlineCheckbox2"
            value="option2"
          />
          <label class="form-check-label" htmlFor="inlineCheckbox2">
            Code
          </label>
        </div>
      </>
    );
  };

  const addAIAgent = () => {};

  const renderCreateAgent = () => {
    return (
      <div class="mt-5 text-center">
        <button
          class="btn1 text-uppercase font-weight-bold"
          onClick={() => addAIAgent()}
        >
          Create agent
        </button>
      </div>
    );
  };

  const render = () => {
    return (
      <div class="row m-0">
        <div class="col-md-10 p-0">
          <div class="p-5 h-100">
            <div class="col-md-8 mx-auto">
              {renderChooseAgent()}
              {renderChoosePrompt()}
              {renderSelectModels()}
              {renderAddSources()}
              {renderTools()}
              {renderCreateAgent()}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return render();
};

export default CreateAgent;
