// user.reducer.js
import { useReducer } from "react";
import * as ACTION from "./types";

const initialState = {
  showRightMenu: false,
  isLoading: false,
  selectedModel: {},
  models: [],
  template_name: null,
  departments: [],
  taskList: [],
  isShowModel: false,
  valueCalculated: false,
  templates: [],
  activeTemplateId: null,
  activeTemplate: null,
  aiRoadmap: null,
  aiValue: null,
  isAIModelFlow: false,
  aiDataSelectedForm: null,
  aiDataFormValues: [],
  selectedPredictionModel: null,
  isAIPredict: false,
  disableAutoCreate: false,
  isOverviewFirstTime: false,
  predictionData: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.UPDATE_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ACTION.AI_STRATEGY_GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.payload.list,
        disableAutoCreate: action.payload.disableAutoCreate,
      };
    case ACTION.AI_STRATEGY_SET_ACTIVE_TEMP_ID:
      return {
        ...state,
        activeTemplateId: action.payload,
      };
    case ACTION.AI_STRATEGY_SET_ACTIVE_TEMPLATE:
      return {
        ...state,
        template_name: action.payload.template_name,
        activeTemplate: action.payload,
      };
    case ACTION.AI_STRATEGY_UPDATE_TEMPLATE_NAME:
      return {
        ...state,
        template_name: action.payload,
      };
    case ACTION.AI_STRATEGY_SAVE_MODEL:
      return {
        ...state,
        showRightMenu: false,
        models: action.payload,
      };
    case ACTION.AI_STRATEGY_CALCULATE_VALUE:
      return {
        ...state,
        valueCalculated: action.payload,
      };
    case ACTION.AI_STRATEGY_GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
      };
    case ACTION.AI_STRATEGY_GET_TASKS:
      return {
        ...state,
        taskList: action.payload,
      };
    case ACTION.AI_STRATEGY_ON_SELECT_MODEL:
      return {
        ...state,
        showRightMenu: true,
        selectedModel: action.payload,
      };
    case ACTION.AI_STRATEGY_CLOSE_DROPDOWN_FORM:
      return {
        ...state,
        showRightMenu: false,
        selectedModel: {},
      };
    case ACTION.AI_STRATEGY_SET_MODELS:
    case ACTION.AI_STRATEGY_ADD_MODEL:
    case ACTION.AI_STRATEGY_DELETE_MODEL:
      return {
        ...state,
        showRightMenu: false,
        isAIModelFlow: false,
        isAIPredict: false,
        models: action.payload,
      };
    case ACTION.AI_STRATEGY_SHOW_MODELS:
      return {
        ...state,
        isShowModel: true,
      };
    case ACTION.AI_STRATEGY_DISABLE_MODELS:
      return {
        ...state,
      };
    case ACTION.AI_STRATEGY_RESET_MODEL_STATUS:
      return {
        ...state,
        isShowModel: false,
        isAIPredict: false,
        isAIModelFlow: false,
        aiValue: null,
      };
    case ACTION.AI_STRATEGY_GET_AI_VALUES_SUCCESS:
      return {
        ...state,
        aiValue: action.payload,
      };
    case ACTION.AI_STRATEGY_GET_AI_ROADMAP_SUCCESS:
      return {
        ...state,
        aiRoadmap: action.payload,
      };
    case ACTION.AI_STRATEGY_SET_AI_DATA_STATUS:
      return {
        ...state,
        isAIModelFlow: action.payload,
        isAIPredict: false,
        isShowModel: action.payload,
        models: [...state.models],
        aiValue: null,
      };
    case ACTION.AI_STRATEGY_SET_AI_MODEL_ON_SELECT_MODEL:
      return {
        ...state,
        showRightMenu: true,
        aiDataSelectedForm: action.payload,
      };
    case ACTION.UPDATE_RIGHT_MENU:
      return {
        ...state,
        showRightMenu: action.payload,
      };
    case ACTION.AI_STRATEGY_SET_AI_MODEL_SAVE_AI_DATA:
      return {
        ...state,
        aiDataFormValues: action.payload,
      };
    case ACTION.AI_STRATEGY_AI_DATA_FEATURE_OPTIONS_SUCCESS:
      return {
        ...state,
        aiDataFeaturesOptions: action.payload,
      };
    case ACTION.AI_STRATEGY_AI_DATA_TARGET_OPTIONS_SUCCESS:
      return {
        ...state,
        aiDataTargetOptions: action.payload,
      };
    case ACTION.AI_STRATEGY_AI_DATA_WORKFLOW_OPTIONS_SUCCESS:
      return {
        ...state,
        aiDataWorkflowOptions: action.payload,
      };
    case ACTION.AI_STRATEGY_SET_AI_PREDICT_SHOW_STATUS:
      return {
        ...state,
        isAIPredict: action.payload,
      };
    case ACTION.AI_STRATEGY_SET_AI_PREDICT_LOOKUP:
      return {
        ...state,
        predictLookup: action.payload,
      };
    case ACTION.AI_PREDICT_UPDATE_MODEL:
      return {
        ...state,
        selectedPredictionModel: action.payload,
      };
    case ACTION.UPDATE_OVERVIEW_FIRST_TIME:
      return {
        ...state,
        isOverviewFirstTime: action.payload,
      };
    case ACTION.UPDATE_AUTO_CREATE_FLOW:
      return {
        ...state,
        disableAutoCreate: action.payload,
      };
    case ACTION.AI_STRATEGY_UPDATE_AI_PREDICTION_DATA:
      return {
        ...state,
        predictionData: action.payload,
      };
    case ACTION.CLEAR_MODEL_INFO:
      return {
        ...state,
        selectedModel: {},
        models: [],
        template_name: null,
        departments: [],
        taskList: [],
        activeTemplateId: null,
        activeTemplate: {},
        aiDataFormValues: [],
        aiDataSelectedForm: {},
        isAIModelFlow: false,
        isAIPredict: false,
        selectedPredictionModel: null,
        predictionData: null,
      };
    default:
      return state;
  }
};

const useAIModelReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return { state, dispatch };
};

export default useAIModelReducer;
