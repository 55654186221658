import React from "react";
import RegressionTable from "./Regressiontable";

const RegressionResult = () => {
  const render = () => {
    return <RegressionTable />;
  };

  return render();
};

export default RegressionResult;
