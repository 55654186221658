import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import constant, {
  TimeList,
  TimeSeriesTransformList,
  ToastType,
} from "../../../../../helper/constant";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../../../../context/app.context";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import CustomDropdown from "../../../../common/CustomDropdown";
import { AutomatedTargetSwitch } from "../DefaultForm";

const TimeSeriesForm = (props) => {
  const { onSubmit } = props;
  const [read, setRead] = useState(false);
  const {
    hideRightMenu,
    aiDataSelectedForm,
    saveAIModelForm,
    switchToSavedModel,
  } = useAIModelContext();

  const [headerList, setHeaderList] = useState([]);
  const [targets, setTargets] = useState([]);
  const [timeList, setTimeList] = useState([]);
  const [csv, setCSV] = useState("");
  const [csvData, setCSVData] = useState([]);
  const [target, setTarget] = useState("");
  const [featureList, setFeatureList] = useState([]);
  const [timeseries, setTimeseries] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [seriesIdentifier, setSeriesIdentifier] = useState("");
  const [forecast, setForecast] = useState("");
  const [form, setForm] = useState({ error: {} });
  const params = useParams();
  const { showAlert } = useAppContext();

  useEffect(() => {
    if (aiDataSelectedForm && aiDataSelectedForm.target_column) {
      setCSV(aiDataSelectedForm.gcs_source);
      setTarget(aiDataSelectedForm.target_column);
      setForecast("");
      setTimeseries("");
      // featureList(aiDataSelectedForm.features_column);
      setRead(true);
    }
  }, [aiDataSelectedForm]);

  useEffect(() => {
    if (csv) {
      Papa.parse(csv, {
        header: true,
        error: (e) =>
          showAlert(
            e || constant.MESSAGES.COMMON_ERROR_MESSAGE,
            ToastType.ERROR,
          ),
        complete: (r) => {
          setCSVData(r.data.slice(0, 4));
          setHeaderList(r.meta.fields);
          setTargets(r.meta.fields);
          const list = r.meta.fields.map((item) => ({
            label: item,
            checked: false,
            transformation: "",
            availability: false,
          }));
          setFeatureList(list);
          setTimeList(TimeList);
        },
      });
    }
  }, [csv, TimeList]);

  useEffect(() => {
    if (target) {
      const list = [...targets]
        .filter((zz) => !target.includes(zz))
        .map((item) => ({
          label: item,
          checked: false,
          transformation: "",
          availability: false,
        }));
      setFeatureList(list);
    }
  }, [target]);

  const handleValdiation = () => {
    let isValid = true;
    let error = {};
    if (!csv) {
      isValid = false;
      error["csv"] = "csv required.";
    }
    if (!target || target.length === 0) {
      isValid = false;
      error["target"] = "Target required.";
    }
    if (!featureList || featureList.length === 0) {
      isValid = false;
      error["featureList"] = "Feature required.";
    }
    if (!timestamp) {
      isValid = false;
      error["timestamp"] = "Timestamp required.";
    }
    if (!seriesIdentifier) {
      isValid = false;
      error["seriesIdentifier"] = "Series Identifier required.";
    }

    if (!timeseries) {
      isValid = false;
      error["timeseries"] = "Timeseries required.";
    }

    if (!forecast) {
      isValid = false;
      error["forecast"] = "Forecast required.";
    }
    setForm({ error });
    return isValid;
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (handleValdiation()) {
      const { id, modelId, model_name, parentId } = aiDataSelectedForm;
      const modifiedFeature = {};
      featureList
        .filter((item) => item.checked)
        .forEach((item) => {
          const { label, availability, transformation } = item;
          modifiedFeature[label] = { availability, transformation };
        });
      const forecastValue = TimeList.filter((item) => item.id === forecast);
      const payload = {
        id,
        csv,
        feature: modifiedFeature,
        target,
        modelId,
        timeseries,
        forecast: forecastValue[0].value,
        templateId: params.id,
        model_name,
        parentId,
        seriesIdentifier,
        timestamp,
      };
      onSubmit(payload);
      let formdata = new FormData();
      formdata.append("id", id);
      formdata.append("csv", csv);
      formdata.append("feature", featureList);
      formdata.append("target", target);
      formdata.append("template_name", timeseries);
      formdata.append("forecast_horizon", forecastValue[0].value);
      formdata.append("templateId", params.id);
      formdata.append("modelId", modelId);
      formdata.append("timestamp", timestamp);
      formdata.append("series_identifier", seriesIdentifier);
      saveAIModelForm(formdata, (data) =>
        switchToSavedModel({ ...data, model_name, parentId }),
      );
    }
  };

  const renderFileSelection = () => {
    return (
      <div className="form-group">
        {read && <div className="h6  f-13">{`Data : ${csv?.name}`}</div>}
        {!read && (
          <>
            <b className="pb-1 custom-dropdown-label">Select file</b>
            <div
              className="file-upload-wrapper2 border-bottom "
              data-text="Select file!"
              style={{ borderColor: form.error["file"] ? "red" : "" }}
            >
              <input
                name="file-upload"
                id="df-file-upload"
                type="file"
                className="file-upload-field2"
                accept=".csv"
                onChange={handleFileChange}
              />
            </div>
          </>
        )}
        {!read && csv && <div className="df-file-selected">{csv?.name}</div>}
      </div>
    );
  };

  const renderTarget = () => {
    return (
      <div className="form-group">
        {read && <div className="h6  f-13">Target : {target}</div>}
        {!read && (
          <>
            <p className="m-0 p-0 h6 f-13 custom-dropdown-label">
              <b className="pb-1 custom-dropdown-label">Target</b>
            </p>
            <CustomDropdown
              hasError={form.error["target"]}
              name=""
              options={targets}
              field=""
              value={target}
              onChange={(e) => setTarget(e)}
            />
          </>
        )}
      </div>
    );
  };

  const onFeatureChange = (value, field, rowIndex) => {
    console.log({ value, field, rowIndex });
    const list = [...featureList];
    list[rowIndex][field] = value;
    setFeatureList(list);
  };

  const renderFeatureList = () => {
    return featureList.map((item, rowIndex) => {
      return (
        <FeatureItem
          key={`featureItem-${rowIndex}`}
          item={item}
          rowIndex={rowIndex}
          read={read}
          onChange={onFeatureChange}
        />
      );
    });
  };

  const renderFeature = () => {
    return (
      <div className="form-group">
        <>
          <p className="m-0 p-0 h6 f-13 custom-dropdown-label">
            <b className="pb-1 custom-dropdown-label">Features</b>
          </p>
          {renderFeatureList()}
        </>
      </div>
    );
  };

  const renderTimeStamp = () => {
    const list = featureList.map((item) => item.label);
    return (
      <div className="form-group">
        {read && <div className="h6  f-13">Timestamp : {timestamp}</div>}
        {!read && (
          <>
            <p className="m-0 p-0 h6 f-13 custom-dropdown-label">
              <b>Timestamp</b>
            </p>
            <CustomDropdown
              hasError={form.error["timestamp"]}
              name=""
              options={list}
              field=""
              value={timestamp}
              onChange={(e) => setTimestamp(e)}
            />
          </>
        )}
      </div>
    );
  };

  const renderSeriesIdentifier = () => {
    const list = featureList.map((item) => item.label);
    return (
      <div className="form-group">
        {read && (
          <div className="h6  f-13">Series Identifier : {seriesIdentifier}</div>
        )}
        {!read && (
          <>
            <p className="m-0 p-0 h6 f-13 custom-dropdown-label">
              <b>Series Identifier</b>
            </p>
            <CustomDropdown
              hasError={form.error["timestamp"]}
              name=""
              options={list}
              field=""
              value={seriesIdentifier}
              onChange={(e) => setSeriesIdentifier(e)}
            />
          </>
        )}
      </div>
    );
  };

  const renderTimeSeries = () => {
    const list = featureList.map((item) => item.label);
    return (
      <div className="form-group">
        {read && <div className="h6  f-13">Time Series : {timeseries}</div>}
        {!read && (
          <>
            <p className="m-0 p-0 h6 f-13 custom-dropdown-label">
              <b>Time Series</b>
            </p>
            <CustomDropdown
              hasError={form.error["timeseries"]}
              name=""
              options={list}
              field=""
              value={timeseries}
              onChange={(e) => setTimeseries(e)}
            />
          </>
        )}
      </div>
    );
  };

  const renderTimeForecast = () => {
    return (
      <div className="form-group">
        {read && <div className="h6  f-13">Forecast : {forecast}</div>}
        {!read && (
          <>
            <p className="m-0 p-0 h6 f-13 custom-dropdown-label">
              <b>Fore cast Horizon</b>
            </p>
            <CustomDropdown
              hasError={form.error["forecast"]}
              name=""
              options={timeList}
              field="value"
              value={forecast}
              onChange={(e) => setForecast(e)}
            />
          </>
        )}
      </div>
    );
  };

  const renderAutomateSwitch = () => {
    return (
      <div className="d-flex align-items-center justify-content-start gap-2 py-2">
        <p className="m-0 p-0 bold">Automate training</p>
        <AutomatedTargetSwitch
          data={csvData}
          onChange={(data) => onAutomatedTarget(data)}
          disabled={!csv}
        />
      </div>
    );
  };

  const onAutomatedTarget = (data) => {
    if (data && data.target_column) {
      setTarget(data.target_column);
      setTimestamp(data.time_column);
      setSeriesIdentifier(data.time_series_identifier_column);
      setForecast(data.forecast_horizon);
      setTimeseries(data.data_granularity_unit);
    }
  };

  const renderModelDataForm = () => {
    return (
      <>
        {renderFileSelection()}
        {csv && (
          <>
            {renderAutomateSwitch()}
            {renderTarget()}
            {renderTimeStamp()}
            {renderSeriesIdentifier()}
            {renderFeature()}
            {renderTimeSeries()}
            {renderTimeForecast()}
          </>
        )}
      </>
    );
  };

  const isSaveActive = () => {
    if (
      csv &&
      target &&
      featureList.length &&
      timeseries &&
      forecast &&
      timestamp &&
      seriesIdentifier
    )
      return false;
    return true;
  };

  const renderActionButtons = () => {
    if (read)
      return (
        <>
          <div className="form-group">
            <button
              type="button"
              className="btn1 btn radious-0  w-100 mt-2 f-13 text-uppercase py-2 font15fontweight600"
              onClick={(e) => {
                e.preventDefault();
                setRead(false);
              }}
            >
              Modify
            </button>
          </div>
          <div className="form-group">
            <button
              type="button"
              className="btn1 btn radious-0  w-100 mt-2 f-13 bg-light-grey  text-uppercase py-2 font15fontweight600"
              onClick={() => hideRightMenu()}
            >
              Close
            </button>
          </div>
        </>
      );

    return (
      <>
        <div className="form-group">
          <button
            type="submit"
            disabled={isSaveActive()}
            className="btn1 btn radious-0  w-100 mt-2 f-13 text-uppercase py-2 font15fontweight600"
          >
            save
          </button>
        </div>
        <div className="form-group">
          <button
            type="button"
            className="btn1 btn radious-0  w-100 mt-2 f-13 bg-light-grey  text-uppercase py-2 font15fontweight600"
            onClick={() => hideRightMenu()}
          >
            Cancel
          </button>
        </div>
      </>
    );
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length) {
      if (e.target.files[0].name.endsWith(".csv")) {
        setCSV(e.target.files[0]);
      } else showAlert("Please upload valid csv", ToastType.WARNING, false);
    }
  };

  const render = () => {
    if (aiDataSelectedForm) {
      return (
        <div className="social-medial-form pb-5">
          <form className="sm-form" onSubmit={onFormSubmit}>
            {renderModelDataForm()}
            <div className="form-group">{renderActionButtons()}</div>
          </form>
        </div>
      );
    }
  };
  return render();
};
export default TimeSeriesForm;

export const TimeSeriesFilledForm = () => {
  const { aiDataSelectedForm } = useAIModelContext();

  const renderData = () => {
    const { target_column } = aiDataSelectedForm;
    if (target_column) {
      return (
        <>
          {renderTargets()}
          {renderFeatures()}
        </>
      );
    }
  };
  const renderTargets = () => {
    const { target_column } = aiDataSelectedForm;
    const targets = target_column.split(",");
    if (targets.length)
      return (
        <div>
          <h5>Target</h5>
          {targets.map((target, rowIndex) => (
            <p className="pb-1 text-capitalize" key={rowIndex}>
              {target}
            </p>
          ))}
        </div>
      );
  };

  const renderFeatures = () => {
    const { features_column } = aiDataSelectedForm;
    if (features_column) {
      const features = Object.keys(JSON.parse(features_column));
      return (
        <div>
          <h5>Feature</h5>
          {features.map((feature, rowIndex) => (
            <p className="pb-1 text-capitalize" key={rowIndex}>
              {feature}
            </p>
          ))}
        </div>
      );
    }
  };
  const renderAIFilledForm = () => {
    return (
      <div className="p-3 pb-2 gap-2 d-flex flex-column">{renderData()}</div>
    );
  };

  const render = () => {
    return renderAIFilledForm();
  };
  return render();
};

const TransformDropdown = (props) => {
  const render = () => {
    return (
      <div className="form-group">
        {props.read && (
          <div className="h6  f-13">Transform : {props.transformation}</div>
        )}
        {!props.read && (
          <>
            <p className="m-0 p-0 h6 f-13 custom-dropdown-label">
              <b className="pb-1 custom-dropdown-label">Transform</b>
            </p>
            <CustomDropdown
              name=""
              options={TimeSeriesTransformList}
              field=""
              value={props.value}
              onChange={(e) => props.onChange(e)}
            />
          </>
        )}
      </div>
    );
  };
  return render();
};

const FeatureItem = (props) => {
  const { item, rowIndex, read, onChange } = props;
  const render = () => {
    return (
      <div className="d-flex flex-column gap-1">
        <div className="d-flex gap-1 align-items-center py-1">
          <input
            id={`feature-check-${rowIndex}`}
            className="substituted"
            type="checkbox"
            aria-hidden="true"
            checked={item.checked}
            onChange={() => onChange(!item.checked, "checked", rowIndex)}
          />
          <label
            htmlFor={`feature-check-${rowIndex}`}
            className="m-0 p-0 capitalize"
          >
            {item.label}
          </label>
        </div>
        {item.checked && (
          <div className="d-flex flex-column gap-1 px-3">
            <div className="d-flex justify-content-start gap-2 align-items-center">
              <p className="m-0 p-0">
                Availability {read && <span>: {item.availability}</span>}
              </p>
              {!read && (
                <div className="d-flex justify-content-start gap-1 align-items-center">
                  <div className="checkbox-wrapper-switch">
                    <label className="rocker rocker-small">
                      <input
                        type="checkbox"
                        checked={item.availability}
                        onChange={() =>
                          onChange(!item.availability, "availability", rowIndex)
                        }
                      />
                      <span className="switch-left">Yes</span>
                      <span className="switch-right">No</span>
                    </label>
                  </div>
                </div>
              )}
            </div>
            <TransformDropdown
              value={item.transformation}
              read={read}
              onChange={(e) => onChange(e, "transformation", rowIndex)}
            />
          </div>
        )}
      </div>
    );
  };

  return render();
};
