import React from "react";
import images from "../../helper/images";
import { formatToCurrency } from "../../helper/helper";
import { useAuthContext } from "../../context/auth.context";

const UnsignedReportChart = (props) => {
  const { isResult, showModelList, revenueReport } = props;
  // const { revenueReport } = useAuthContext();

  const renderModelList = (type, data) => {
    if (showModelList && data)
      return (
        <div className={`ai-value-model-list mt-4 ${type}`}>
          <div className="ai-value-title">AI Models</div>
          <ul className="ai-value-listitem">
            {data.map((item, index) => (
              <li key={`ai-value-key${index}`}>{item}</li>
            ))}
          </ul>
        </div>
      );
  };
  const render = () => {
    return (
      <div className={`${isResult ? "flexbox flowsec" : "stepbox flowsec"}`}>
        <div className="row row-5">
          <div className="col-md-4 col-lg-3 mt-5 px-2">
            <div className="flowbox px-3 pt-4 border text-center h-100">
              <div className="mt-2">
                <img src={images.unsigned.revenueIncrease} alt="" />
              </div>
              <h4 className="my-4">Revenue Increase </h4>
              <h3 className="py-2">
                {formatToCurrency(revenueReport.breakDown.revenue_increase)}
              </h3>
              <div className="mt-4">
                <img
                  src={images.unsigned.revenueFlow}
                  className="w-100"
                  alt=""
                />
              </div>
              {renderModelList("revenue-box", revenueReport.revenueModels)}
            </div>
          </div>
          <div className="col-md-4 col-lg-3 mt-5 px-2">
            <div className="flowbox px-3 pt-4 border text-center h-100">
              <div className="mt-2">
                <img src={images.unsigned.materialCost} alt="" />
              </div>
              <h4 className="my-4">Material Cost Reduction</h4>
              <h3 className="py-2">
                {formatToCurrency(
                  revenueReport.breakDown.material_cost_reduction,
                )}
              </h3>
              <div className="mt-4">
                <img
                  src={images.unsigned.materialFlow}
                  className="w-100"
                  alt=""
                />
              </div>
              {renderModelList(
                "material-box",
                revenueReport.materialCostModels,
              )}
            </div>
          </div>
          <div className="col-md-4 col-lg-3 mt-5 px-2">
            <div className="flowbox px-3 pt-4 border text-center h-100">
              <div className="mt-2">
                <img src={images.unsigned.manPowerEff} alt="" />
              </div>
              <h4 className="my-4">Manpower Efficiencies </h4>
              <h3 className="py-2">
                {formatToCurrency(revenueReport.breakDown.manpower_efficienies)}
              </h3>
              <div className="mt-4">
                <img
                  src={images.unsigned.manPowerFlow}
                  className="w-100"
                  alt=""
                />
              </div>
              {renderModelList("manpower-box", revenueReport.manpowerModels)}
            </div>
          </div>
          <div className="col-md-4 col-lg-3 mt-5 px-2">
            <div className="flowbox px-3 pt-4 border text-center h-100">
              <div className="mt-2">
                <img src={images.unsigned.manchineCost} alt="" />
              </div>
              <h4 className="my-4">Machine Cost Reduction </h4>
              <h3 className="py-2 ">
                {formatToCurrency(
                  revenueReport.breakDown.machine_cost_reduction,
                )}
              </h3>
              <div className="mt-4 ">
                <img
                  src={images.unsigned.manchineFlow}
                  className="w-100"
                  alt=""
                />
              </div>
              {renderModelList("machine-box", revenueReport.machineCostModels)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return render();
};

export default UnsignedReportChart;
