import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import { useAppContext } from "../../../../../context/app.context";
import { useScreenSizeContext } from "../../../../../context/mobile-layout.context";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { EvaluateType } from "../../../../../helper/constant";
import { calculatePercentage, sumOfObject } from "../../../../../helper/helper";
import EvaluateBarChart from "../../../../common/charts/EvaluateBarChart";

const RegressionEvalaute = (props) => {
  const { data } = props;
  const { isMobileScreen } = useScreenSizeContext();

  const getHelpMessage = (type) => {
    switch (type) {
      case EvaluateType.MAE:
        return "Mean absolute error (MAE) is the average of absolute differences between observed and predicted values. A low value indicates a higher-quality model, where 0 means the model made no errors. Interpreting MAE depends on the range of values in the series. MAE has the same unit as the target column.";
      case EvaluateType.MAPE:
        return "The mean absolute percentage error (MAPE) is the average of absolute percentage errors. MAPE ranges from 0 to infinity, where a lower value indicates a higher quality model. MAPE becomes infinity if 0 values are present in the ground truth data.";
      case EvaluateType.RMSE:
        return "Root mean square error (RMSE) is the root of squared differences between observed and predicted values. A lower value indicates a higher-quality model, where 0 means the model made no errors. Interpreting RMSE depends on the range of values in the series. RMSE is more responsive to large errors than MAE.";
      case EvaluateType.RMSLE:
        return "Root mean squared log error (RMSLE) is the root of squared averages of log differences between observed and predicted values. Interpreting RMSLE depends on the range of values in the series. RMSLE is less responsive to outliers than RMSE, and it tends to penalize underestimations slightly more than overestimations.";
      case EvaluateType.RS2:
        return "r squared (r^2) is the square of the Pearson correlation coefficient between the observed and predicted values. This ranges from 0 to 1, where a higher value indicates a higher-quality model.";
    }
  };

  const renderMetrics = () => {
    const metricData = getMetricData();
    if (metricData) {
      return (
        <div
          className={`d-flex align-items-center evaluate-summary-container ${isMobileScreen ? "" : "justify-content-around mx-auto w-80"}`}
        >
          <div className="d-flex flex-column gap-1 evaluate-summary-content">
            <p className="m-0 p-0 border-bottom align-self-stretch text-center bg-white-smoke">
              <span className="mr-1">MAE</span>
            </p>
            <p className="m-0 p-0">{metricData.mea}</p>
          </div>
          <div className="d-flex flex-column gap-1 evaluate-summary-content">
            <p className="m-0 p-0 border-bottom align-self-stretch text-center bg-white-smoke">
              MAPE
            </p>
            <p className="m-0 p-0">{metricData.mape}</p>
          </div>
          <div className="d-flex flex-column gap-1 evaluate-summary-content">
            <p className="m-0 p-0 border-bottom align-self-stretch text-center bg-white-smoke">
              RMSE
            </p>
            <p className="m-0 p-0">{metricData.rmse}</p>
          </div>
          <div className="d-flex flex-column gap-1 evaluate-summary-content">
            <p className="m-0 p-0 border-bottom align-self-stretch text-center bg-white-smoke">
              RMSLE
            </p>
            <p className="m-0 p-0">{metricData.rmsle}</p>
          </div>
          <div className="d-flex flex-column gap-1 evaluate-summary-content">
            <p className="m-0 p-0 border-bottom align-self-stretch text-center bg-white-smoke">
              R^2
            </p>
            <p className="m-0 p-0">{metricData.rs}</p>
          </div>
        </div>
      );
    }
  };

  const getMetricData = () => {
    if (data && data.metrics) {
      const kind = data.metrics.kind;
      const { fields } = data.metrics[kind];
      return {
        mea: parseFloat(fields.meanAbsoluteError.numberValue).toFixed(2.0),
        mape: parseFloat(fields.rootMeanSquaredError.numberValue).toFixed(2.0),
        rmse: parseFloat(fields.rootMeanSquaredError.numberValue).toFixed(2.0),
        rmsle: parseFloat(fields.rootMeanSquaredLogError.numberValue).toFixed(
          2.0
        ),
        rs: parseFloat(fields.rSquared.numberValue).toFixed(2.0),
      };
    }
  };

  const renderFetureImportance = () => {
    const chart = getFeatureChart();
    return (
      <div
        style={{ height: 400 }}
        className={`${isMobileScreen ? "" : "w-80 mx-auto"}`}
      >
        <EvaluateBarChart chart={chart} customTooltip={CustomToolTip} />
      </div>
    );
  };

  const renderHelpSection = () => {
    return (
      <div className="">
        <p>
          <span className="font-weight-bold">*MEA</span> :
          {getHelpMessage(EvaluateType.MAE)}
        </p>
        <p>
          <span className="font-weight-bold">*MAPE</span> :
          {getHelpMessage(EvaluateType.MAPE)}
        </p>
        <p>
          <span className="font-weight-bold">*RMSE</span> :
          {getHelpMessage(EvaluateType.RMSE)}
        </p>
        <p>
          <span className="font-weight-bold">*RMSLE</span> :
          {getHelpMessage(EvaluateType.RMSLE)}
        </p>
        <p>
          <span className="font-weight-bold">*R^2</span> :
          {getHelpMessage(EvaluateType.RS2)}
        </p>
      </div>
    );
  };

  const CustomToolTip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const feature = payload[0].payload;
      return (
        <div className="chart-custom-tooltip">
          <p className="label">{`Feature: ${feature.feature}`}</p>
          <p
            className="intro m-0 p-0 font-weight-bold"
            style={{ color: payload[0].color }}
          >{`Importance(%): ${feature.importance}%`}</p>
          <p
            className="intro m-0 p-0 font-weight-bold"
            style={{ color: payload[0].color }}
          >{`Importance(raw): ${feature.value.toPrecision(4)}`}</p>
        </div>
      );
    }
    return null;
  };

  const getFeatureData = () => {
    if (
      data &&
      data.modelExplanation &&
      data.modelExplanation.meanAttributions.length
    ) {
      const kind =
        data.modelExplanation.meanAttributions[0].featureAttributions.kind;
      const features =
        data.modelExplanation.meanAttributions[0].featureAttributions[kind]
          .fields;
      const fields = Object.keys(features);
      const chartData = fields.map((item) => {
        const key = features[item].kind;
        return {
          feature: item,
          value: features[item][key],
        };
      });
      const total = sumOfObject(chartData.map((item) => item.value));
      return chartData
        .map((item) => {
          item.importance = calculatePercentage(item.value, total).toPrecision(
            4
          );
          return item;
        })
        .sort((a, b) => b.importance - a.importance);
    }
    return [];
  };

  const getFeatureChart = () => {
    return {
      data: getFeatureData(),
      width: 400,
      height: 400,
      layout: "vertical",
      x: { type: "number", key: "importance", domain: [0, "auto"] },
      y: {
        type: "category",
        key: "feature",
        domain: [0, "auto"],
        hide: true,
      },
    };
  };

  const render = () => {
    if (data) {
      return (
        <div className="py-3 flex-column gap-2 d-flex">
          <div className="d-flex flex-column gap-1">
            <h5 className="text-center">Model Metrics</h5>
            {renderMetrics()}
          </div>
          <div className="d-flex flex-column gap-1 pt-3">
            <h5 className="text-center">Feature Importance</h5>
            {renderFetureImportance()}
          </div>
          {renderHelpSection()}
        </div>
      );
    }
    return <p>isLoading...</p>;
  };
  
  return render();
};

export default RegressionEvalaute;
