import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAIModelContext } from "../../../context/ai-model.context";
import ModelCreator from "../ModelCreator";
import constant, { TemplateStatus } from "../../../helper/constant";
import {
  getSocialMediaModel,
  getTrainingModel,
} from "../../../service/ai-model.service";

const AIModel = () => {
  const params = useParams();
  const [showNext, setShowNext] = useState(false);
  const {
    getAIModel,
    activeTemplate,
    models,
    setAllAIModelFormData,
    hideRightMenu,
    showRightMenu,
  } = useAIModelContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      getAIModel(params.id);
      hideRightMenu();
    }
  }, [params.id]);

  useEffect(() => {
    if (activeTemplate)
      setShowNext(activeTemplate?.status === TemplateStatus.SCHEDULED);
  }, [activeTemplate]);

  useEffect(() => {
    if (models.length) {
      const taskList = models.flatMap((model) => {
        return [...model.tasks];
      });
      const allPromises = taskList.map((task) => getAllTaskPromises(task));
      Promise.all(allPromises)
        .then((result) => {
          const list = result.map((item) => item[0] || {});
          setAllAIModelFormData(list);
        })
        .catch(console.log);
    }
  }, [models]);

  const getAllTaskPromises = (task) => {
    if (constant.LLMModels.includes(task.modelId))
      return getSocialMediaModel(task.taskId);
    else return getTrainingModel(task.taskId);
  };

  const onNext = () => {
    navigate(`/`);
  };

  const renderThanks = () => {
    return (
      <div>
        <strong>Thank you for uploading data.</strong>
        <p>We will email you once the models are created and deployed.</p>
        <p>In the meantime, let's move to the next steps.</p>
      </div>
    );
  };

  const render = () => {
    return (
      <div className="row mx-0 height-inherit">
        <div
          className={` px-md-0 ${showRightMenu ? "col-md-10" : "col-md-12"}`}
        >
          <div className="p-1">
            <div className="p-2 text-center">
              {renderThanks()}
              <ModelCreator />
              <button
                className="btn1 btn radious-0  w-15 mt-2 f-13 text-uppercase py-2 font15fontweight600"
                onClick={onNext}
              >{`Next >>`}</button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return render();
};

export default AIModel;
