import React, { useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useAIModelContext } from "../../../context/ai-model.context";
import constant from "../../../helper/constant";
import { formatToCurrency } from "../../../helper/helper";
import PredScatterChart from "../../common/charts/PredScatterChart";
import PinBarChart from "../../common/charts/PinBarChart";

const AIRoadMap = () => {
  const labels = {
    x: "Time needed to Execute Models (month)",
    y: "Value added to Company ($)",
  };
  const { getRoadmapDetails, aiRoadmap } = useAIModelContext();
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (params.id) {
      getRoadmapDetails(params.id);
    }
  }, [params.id]);

  const renderBarChart = () => {
    if (aiRoadmap) {
      return (
        <div className="pin-bar-chart">
          <PinBarChart data={aiRoadmap} labels={labels} />
        </div>
      );
    }
    return <div>Loading...</div>;
  };

  const onStartExecution = () => {
    navigate(`/${constant.PAGES.HOME.AI_DATA}/${params.id}`);
  };

  const renderScatterChart = () => {
    const config = transformScatterConfig();
    return <PredScatterChart config={config} />;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="chart-custom-tooltip d-flex flex-column gap-1">
          <p className="name m-0 p-0" style={{ color: payload[0].color }}>
            {payload[0].payload.name}
          </p>
          <p className="label m-0 p-0">Time : {label} (month)</p>
          <p className="label m-0 p-0">
            Value add : {formatToCurrency(payload[0].value)}
          </p>
        </div>
      );
    }
    return null;
  };

  const transformScatterConfig = () => {
    const domains = { x: [0, 12] };
    return {
      title: "",
      labels: [labels.x, labels.y],
      domains,
      tooltip: CustomTooltip,
      datakeys: ["month", "valueAdd"],
      data: aiRoadmap,
    };
  };
  const render = () => {
    return (
      <div className="ai-roadmap chart-container p-5">
        <h3 className="mb-3 ">Roadmap to execute AI Models</h3>
        {renderScatterChart()}
        <button
          className="btn1 btn radious-0  w-25 mt-2 f-13 text-uppercase py-2 font15fontweight600"
          onClick={onStartExecution}
        >
          Start execution
        </button>
      </div>
    );
  };
  return render();
};

export default AIRoadMap;
