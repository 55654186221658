import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  databaseURL: process.env.REACT_APP_RTDB,
  storageBucket: "ai-factory-project-357407.appspot.com",
  messagingSenderId: "311033712537",
  appId: "1:311033712537:web:e6c3c0a31c042a62810d08",
};

const app = initializeApp(firebaseConfig);

const appLogout = (cb) => {
  signOut(auth).then(cb);
};

const rtdb = getDatabase(app);
const auth = getAuth();
export {
  auth,
  rtdb,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  appLogout,
};
