import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  addUser,
  deleteUser,
  getUserList,
  updateUser,
} from "../../../service/ai-model.service";
import constant, { ToastType, UserRoles } from "../../../helper/constant";
import { useAppContext } from "./../../../context/app.context";
import { useAuthContext } from "./../../../context/auth.context";
import CustomLoader from "./../../common/CustomLoader";

const UserManagement = () => {
  const [userList, setUserList] = useState([]);
  const { showAlert, updateLoader, isLoading } = useAppContext();
  const { userInfo, requestPasswordReset } = useAuthContext();

  useEffect(() => {
    updateLoader(true);
    getUserData();
  }, []);

  const getUserData = () => {
    getUserList()
      .then((result) => setUserList(result))
      .finally(() => updateLoader(false));
  };

  const renderPageTitle = () => {
    return (
      <div className="formsectionbox p-4">
        <div className="d-flex align-items-center mb-3 justify-content-between borderbottom">
          <h4 className="text-blue pb-2 font-weight-bold ">Manage users</h4>
          <div className="text-right mb-3">
            <Link className="btn2 text-white" onClick={() => onUserAdd()}>
              <span className="mr-2">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 7V10M10.5 10V13M10.5 10H13.5M10.5 10H7.5M19.5 10C19.5 14.9706 15.4706 19 10.5 19C5.52944 19 1.5 14.9706 1.5 10C1.5 5.02944 5.52944 1 10.5 1C15.4706 1 19.5 5.02944 19.5 10Z"
                    stroke="#fff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>{" "}
              Add User
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const renderUserRow = () => {
    if (userList && userList.length) {
      return userList.map((item, userIndex) => {
        const { user, role } = item;
        if (item.isEdit) return renderUserForm(item, userIndex);
        return (
          <tr key={userIndex}>
            <td>{user.org_email}</td>
            <td>Marketing</td>
            <td className="capitalize">{role}</td>
            <td>
              {role !== UserRoles[0].id && (
                <div className="d-flex gap-2">
                  <Link onClick={() => onUserEdit(userIndex)}>
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="text-blue"
                      size="lg"
                    ></FontAwesomeIcon>
                  </Link>
                  <Link onClick={() => onUserDelete(userIndex)}>
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      className="text-danger"
                      size="lg"
                    ></FontAwesomeIcon>
                  </Link>
                </div>
              )}
            </td>
          </tr>
        );
      });
    }
  };

  const handleUserChange = (field, value, userIndex) => {
    const list = userList.slice();
    if (field === "org_email") list[userIndex].user.org_email = value;
    if (field === "role") list[userIndex].role = value;
    setUserList(list);
  };

  const renderUserForm = (item, userIndex) => {
    return (
      <tr key={userIndex}>
        <td>
          <input
            type="text"
            className="form-control"
            value={item.user.org_email || ""}
            onChange={(e) =>
              handleUserChange("org_email", e.target.value, userIndex)
            }
          />
        </td>
        <td>
          <select className="form-control">
            <option>Choose Dept.</option>
            <option name="Development" value="Development">
              Development
            </option>
          </select>
        </td>
        <td>
          <select
            className="form-control"
            onChange={(e) =>
              handleUserChange("role", e.target.value, userIndex)
            }
            value={item.role || ""}
          >
            <option>Choose Role</option>
            {UserRoles.map((item, itemIndex) => (
              <option key={itemIndex} name="User" value={item.id}>
                {item.value}
              </option>
            ))}
          </select>
        </td>
        <td>
          <div className="d-flex gap-2">
            <Link onClick={() => onUserSaved(userIndex)}>
              <FontAwesomeIcon
                icon={faCheck}
                className="text-success"
                size="lg"
              />
            </Link>
            <Link onClick={() => onUserEditCancel(userIndex)}>
              <FontAwesomeIcon
                icon={faXmark}
                className="text-danger"
                size="lg"
              />
            </Link>
          </div>
        </td>
      </tr>
    );
  };

  const onUserEdit = (userIndex) => {
    const list = userList.slice();
    list[userIndex].isEdit = true;
    list[userIndex].isUpdate = true;
    setUserList(list);
  };

  const onUserEditCancel = (userIndex) => {
    const list = userList.slice();
    list[userIndex].isEdit = false;
    setUserList(list);
  };

  const onUserAdd = () => {
    const list = userList.slice();
    list.push({ user: { org_email: "" }, role: "", isEdit: true });
    setUserList(list);
  };

  const onUserDelete = (userIndex) => {
    updateLoader(true);
    const list = userList.slice();
    const {
      user: { org_email, id },
    } = list[userIndex];
    deleteUser({ id, org_email })
      .then(() => {
        list.splice(userIndex, 1);
        setUserList(list);
        showAlert(
          `User ${constant.MESSAGES.DELETED_MESSAGE}`,
          ToastType.SUCCESS,
        );
      })
      .catch((ex) =>
        showAlert(
          ex?.error || constant.MESSAGES.COMMON_ERROR_MESSAGE,
          ToastType.ERROR,
        ),
      )
      .finally(() => updateLoader(false));
  };

  const onUserSaved = (userIndex) => {
    updateLoader(true);
    const list = userList.slice();
    const {
      user: { org_email },
      role,
      isUpdate,
    } = list[userIndex];
    if (isUpdate) {
      updateUser({ org_email, role })
        .then(() => {
          getUserData();
          showAlert(
            `User ${constant.MESSAGES.UPDATED_MESSAGE}`,
            ToastType.SUCCESS,
          );
        })
        .catch((ex) =>
          showAlert(
            ex?.error || constant.MESSAGES.COMMON_ERROR_MESSAGE,
            ToastType.ERROR,
          ),
        )
        .finally(() => updateLoader(false));
    } else {
      const {
        org: { org_name, org_tenant },
      } = userInfo;
      addUser({ org_email, org_name, role, org_tenant, active: true })
        .then(() => {
          getUserData();
          showAlert(
            `User ${constant.MESSAGES.ADDED_MESSAGE}`,
            ToastType.SUCCESS,
          );
          requestPasswordReset(org_email);
        })
        .catch((ex) =>
          showAlert(
            ex?.error || constant.MESSAGES.COMMON_ERROR_MESSAGE,
            ToastType.ERROR,
          ),
        )
        .finally(() => updateLoader(false));
    }
  };

  const renderUserManagement = () => {
    return (
      <div className="form formEit2">
        <div className="bg-white p-4">
          <table className="imgdetctble table align-middle">
            <thead>
              <tr>
                <th scope="col">Email</th>
                <th scope="col">Dept.</th>
                <th scope="col">Role</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>{renderUserRow()}</tbody>
          </table>
        </div>
      </div>
    );
  };

  const render = () => {
    return (
      <>
        {isLoading && <CustomLoader full />}
        <div className="container bg-white">
          {renderPageTitle()}
          {renderUserManagement()}
        </div>
      </>
    );
  };

  return render();
};

export default UserManagement;
