import React, { useState } from "react";
import ImageButton from "./ImageButton";
import { FileType } from "../../helper/constant";

const ZoomableImage = ({ src, alt }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const onDownload = () => {
    window.open(src, "_blank");
  };

  const render = () => {
    const name = src.split("/").pop();
    if (src.includes(".pdf"))
      return (
        <ImageButton
          image={{ file: { name } }}
          onClick={() => onDownload()}
          disableTrash
          fileType={FileType.PDF}
        />
      );
    return (
      <div className={`zoomable-image-container ${isZoomed ? "zoomed" : ""}`}>
        <img
          src={src}
          alt={alt}
          className="zoomable-image"
          onClick={() => onDownload()}
        />
      </div>
    );
  };

  return render();
};

export default ZoomableImage;
