import {
  faCheckCircle,
  faCircleXmark,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { ModelType } from "../../../../helper/constant";
import { DefaultFilledFrom } from "./DefaultForm";
import ICFilledForm from "./ImageClassification/ICFilledForm";
import { DocumentAIFilledForm } from "./documentAI/DocumentAITraning";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAIModelContext } from "../../../../context/ai-model.context";
import { ImageExtractFilledForm } from "./image-extraction/ImageExtractTraning";
import { TimeSeriesFilledForm } from "./time-series/TimeSerialForm";
import SocialMediaForm from "./SocialMediaForm";

const AIFilledFrom = () => {
  const { aiDataSelectedForm, hideRightMenu } = useAIModelContext();

  const renderDynamicFilledContent = () => {
    const { modelId } = aiDataSelectedForm;
    switch (modelId) {
      case ModelType.DocumentAI:
        return <DocumentAIFilledForm />;
      case ModelType.ImageClassification:
        return <ICFilledForm />;
      case ModelType.ObjectDetection:
        return <ImageExtractFilledForm />;
      case ModelType.TimeSerialTabular:
        return <TimeSeriesFilledForm />;
      case ModelType.SocialMedia:
        return <SocialMediaForm isRead />;
      default:
        return <DefaultFilledFrom />;
    }
  };

  const renderDownloadButton = () => {
    const { gcs_source } = aiDataSelectedForm;
    if (gcs_source) {
      const fileName = gcs_source?.split("/").pop();
      return (
        <div className="d-flex flex-column pb-2 gap-1 separator separator-top px-3">
          <p className="p-0 ai-field-title ">Training data : </p>
          <a className="" href={gcs_source} download={fileName}>
            <span>Download</span>
            <FontAwesomeIcon icon={faDownload} className="px-2" />
          </a>
        </div>
      );
    }
  };
  const renderAIFilledForm = () => {
    return (
      <div className="d-flex flex-column gap-2 mx-2">
        <div className="d-flex gap-1 justify-content-center align-items-center">
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="text-success"
            size="lg"
          />
          <p className="m-0 p-0 bold">Training data saved</p>
        </div>
        {renderDownloadButton()}
        {renderDynamicFilledContent()}
        <div className="form-group  p-3">
          <button
            type="button"
            className="btn2 btn radious-0  w-100 mt-2 f-13 bg-light-grey  text-uppercase py-2 font15fontweight600"
            onClick={() => hideRightMenu()}
          >
            close
          </button>
        </div>
      </div>
    );
  };

  const render = () => {
    if (aiDataSelectedForm && aiDataSelectedForm.status)
      return renderAIFilledForm();
    return <p className="">no data found</p>;
  };
  return render();
};

export default AIFilledFrom;
