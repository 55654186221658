import { faPlugCirclePlus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Tag from "./Tag";

const TagGenerator = (props) => {
  const render = () => {
    return (
      <div className="d-flex flex-column">
        <div className="font-weight-bold text-blue h6 mb-2">Add Tags</div>
        <Tag {...props} />
      </div>
    );
  };

  return render();
};

export default TagGenerator;
