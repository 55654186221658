import React from "react";
import { CHART_TYPE } from "../../../../../helper/constant";
import PredScatterChart from "../../../../common/charts/PredScatterChart";
import PredBarChart from "../../../../common/charts/PredBarChart";
import PredPieChart from "../../../../common/charts/PredPieChart";
import PredLineChart from "../../../../common/charts/PredLineChart";

const PredChartGroup = (props) => {
  const { chartList } = props;

  const renderDynamicCharts = (chart) => {
    switch (chart.type) {
      case CHART_TYPE.SCATTER:
        return <PredScatterChart config={chart.config} />;
      case CHART_TYPE.BAR:
        return <PredBarChart config={chart.config} />;
      case CHART_TYPE.PIE:
        return <PredPieChart config={chart.config} />;
      case CHART_TYPE.LINE:
        return <PredLineChart config={chart.config} />;
      default:
        return <div className="py-2">Coming Soon</div>;
    }
  };

  const renderChartList = () => {
    if (chartList.length)
      return chartList.map((chart, chartIndex) => {
        return <div key={chartIndex}>{renderDynamicCharts(chart)}</div>;
      });
  };

  const render = () => {
    return renderChartList();
  };

  return render();
};

export default PredChartGroup;
