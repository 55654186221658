import React, { useState } from "react";
import { getSocialMediaQuery } from "../../../../../service/ai-model.service";
import CustomLoader from "../../../../common/CustomLoader";
import { Link, useParams } from "react-router-dom";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const AISearchBox = () => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [queryResult, setQueryResult] = useState("");
  const params = useParams();
  const { selectedPredictionModel } = useAIModelContext();

  const onAISearchSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      templateId: params.id,
      taskId: selectedPredictionModel.taskId,
      query: searchText,
    };
    getSocialMediaQuery(payload)
      .then((r) => {
        if (r) setQueryResult(r);
      })
      .catch((ex) => {})
      .finally((e) => setLoading(false));
  };

  const clearSearchResults = () => {
    setQueryResult(null);
    setSearchText("");
  };
  const renderSearchBar = () => {
    return (
      <section className="searchbar py-3">
        <form onSubmit={onAISearchSubmit} noValidate>
          <div className="d-flex form-control py-0 align-items-md-center">
            <input
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="form-control border-0 py-0"
              placeholder="What is Customer intent Prediction"
            />
            <button className="btn p-2" type="submit">
              <svg width="38" height="38" viewBox="0 0 38 38" fill="none">
                <g opacity="0.2">
                  <path
                    d="M4.75 31.6666V22.1666L17.4167 19L4.75 15.8333V6.33331L34.8333 19L4.75 31.6666Z"
                    fill="black"
                  />
                </g>
              </svg>
            </button>
          </div>
        </form>
        {!loading && queryResult && (
          <div className="mt-2  form-control">
            <h4 className="py-2 d-flex justify-content-between">
              <div className="">Search Results</div>
              <Link onClick={() => clearSearchResults()}>
                <FontAwesomeIcon icon={faClose} />
              </Link>
            </h4>
            <p dangerouslySetInnerHTML={{ __html: queryResult }}></p>
          </div>
        )}
      </section>
    );
  };

  const render = () => {
    return (
      <div className="container">
        {renderSearchBar()}
        {loading && (
          <div className="text-center">
            <CustomLoader masked />
          </div>
        )}
      </div>
    );
  };

  return render();
};

export default AISearchBox;
