import React, { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "../../context/auth.context";
import useInput from "../../hooks/useInput";
import constant from "../../helper/constant";
import { formatNumberInMillions, formatToCurrency } from "../../helper/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowDown,
  faCartArrowDown,
} from "@fortawesome/free-solid-svg-icons";

const CurrencyType = {
  dollar: "USD",
  pound: "GBP",
  euro: "EUR",
};
const UnsignedForm = () => {
  const {
    industries,
    getIndustries,
    calculateUnsignedRevenue,
    setSelectedIndustry,
    updateRevenueReport,
  } = useAuthContext();
  const [industryId, bindIndustry, resetIndustry] = useInput("");
  const [revenue, bindRevenue, resetRevenue] = useInput(
    constant.UNSIGNED.REVENUE_RAGE.MIN,
  );
  const [revenueType, setRevenueType, resetRevenueType] = useState(
    CurrencyType.dollar,
  );
  const [inventory, bindInventory, resetInventory] = useInput(
    constant.UNSIGNED.INVENTORY_RAGE.MIN,
  );
  const [inventoryType, setInventoryType] = useState(CurrencyType.dollar);
  const [count, bindEmployeeCount, resetEmployeeCount] = useInput(
    constant.UNSIGNED.EMPLOYEE_RAGE.MIN,
  );
  const [form, setForm] = useState({ error: { valid: false } });

  useEffect(() => {
    getIndustries();
  }, []);

  useEffect(() => {
    if (industryId) {
      const payload = {
        industryId,
        revenue,
        inventory,
        count,
      };
      calculateUnsignedRevenue(payload);
    }
  }, [revenue, inventory, count]);

  useEffect(() => {
    resetRevenue();
    resetInventory();
    resetEmployeeCount();
  }, [industryId]);

  const handleValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!industryId) {
      formIsValid = false;
      error["industryId"] = "Industry required.";
    }

    if (!revenue) {
      formIsValid = false;
      error["revenue"] = "Revenue is required.";
    }

    if (!count) {
      formIsValid = false;
      error["count"] = "Employee Count required.";
    }

    setForm({ error });
    return formIsValid;
  };

  const onCalculateRevenueSubmited = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      setSelectedIndustry(industryId);
      const data = {
        industryId,
        revenue: Number(revenue) * constant.Million,
        count,
        inventory: Number(inventory) * constant.Million,
        capital_expenditure: 1000,
      };
      calculateUnsignedRevenue(data);
    }
  };

  const render = () => {
    return (
      <div className="calulatorbox py-4 px-5">
        <h5 className="mb-3">Input Your Data</h5>
        <form noValidate onSubmit={onCalculateRevenueSubmited}>
          <div className="form-group">
            <label className="text-blue d-block">
              Select Industry<span className="text-danger">*</span>
            </label>
            <div className="d-flex justify-align-content-start align-items-center">
              <select
                className={`form-control ${form.error["industryId"] ? "error-br" : ""}`}
                {...bindIndustry}
              >
                <option value="" disabled>
                  Select Industry...
                </option>
                {industries.map((opt) => (
                  <option key={opt.id} value={opt.id}>
                    {opt.industry_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div id="range-sliders" className="range-sliders">
            <div className="form-group mb-5">
              <label className="text-blue d-block">
                Revenue (in Millions)<span className="text-danger">*</span>
              </label>
              <div className="form-check-inline mb-2">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="revenueradio"
                    value={CurrencyType.dollar}
                    checked={revenueType === CurrencyType.dollar}
                    onChange={(e) => setRevenueType(e.target.value)}
                  />
                  $
                </label>
              </div>
              <div className="form-check-inline">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="revenueradio"
                    value={CurrencyType.pound}
                    checked={revenueType === CurrencyType.pound}
                    onChange={(e) => setRevenueType(e.target.value)}
                  />
                  £
                </label>
              </div>
              <div className="form-check-inline">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="revenueradio"
                    value={CurrencyType.euro}
                    checked={revenueType === CurrencyType.euro}
                    onChange={(e) => setRevenueType(e.target.value)}
                  />
                  €
                </label>
              </div>
              <div
                className="range-slider-group d-flex flex-column"
                data-min={`${constant.UNSIGNED.REVENUE_RAGE.MIN}M`}
                data-max={`${constant.UNSIGNED.REVENUE_RAGE.MAX}M`}
              >
                <input
                  type="range"
                  min={constant.UNSIGNED.REVENUE_RAGE.MIN}
                  max={constant.UNSIGNED.REVENUE_RAGE.MAX}
                  step={constant.UNSIGNED.REVENUE_RAGE.STEP}
                  data-color="#f00"
                  className="range-slider numbrings order-2 range-slider-red"
                  id="range-slider-red"
                  {...bindRevenue}
                />
                <input
                  type="number"
                  min={constant.UNSIGNED.REVENUE_RAGE.MIN}
                  max={constant.UNSIGNED.REVENUE_RAGE.MAX}
                  step={constant.UNSIGNED.REVENUE_RAGE.STEP}
                  className="input-slider form-control mb-4 order-1 input-slider-red"
                  id="input-slider-red"
                  style={{ borderColor: form.error["revenue"] ? "red" : "" }}
                  {...bindRevenue}
                />
              </div>
            </div>
            <div className="form-group mb-5">
              <label className="text-blue d-block">
                No. of Employee <span className="text-danger">*</span>
              </label>
              <div
                className="range-slider-group d-flex flex-column"
                data-min={constant.UNSIGNED.EMPLOYEE_RAGE.MIN}
                data-max={constant.UNSIGNED.EMPLOYEE_RAGE.MAX}
              >
                <input
                  type="range"
                  min={constant.UNSIGNED.EMPLOYEE_RAGE.MIN}
                  max={constant.UNSIGNED.EMPLOYEE_RAGE.MAX}
                  value="0"
                  data-color="#f00"
                  className="range-slider numbrings order-2 range-slider-red"
                  id="range-slider-red"
                  style={{ borderColor: form.error["count"] ? "red" : "" }}
                  {...bindEmployeeCount}
                />
                <input
                  type="number"
                  min={constant.UNSIGNED.EMPLOYEE_RAGE.MIN}
                  max={constant.UNSIGNED.EMPLOYEE_RAGE.MAX}
                  {...bindEmployeeCount}
                  className="input-slider form-control mb-4 order-1 input-slider-red"
                  id="input-slider-red"
                />
              </div>
            </div>
            <div className="form-group mb-5">
              <label className="text-blue d-block">
                Inventory (in Millions)
              </label>
              <div className="form-check-inline mb-2">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="inventoryradio"
                    value={CurrencyType.dollar}
                    checked={inventoryType === CurrencyType.dollar}
                    onChange={(e) => setInventoryType(e.target.value)}
                  />
                  $
                </label>
              </div>
              <div className="form-check-inline">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="inventoryradio"
                    value={CurrencyType.pound}
                    checked={inventoryType === CurrencyType.pound}
                    onChange={(e) => setInventoryType(e.target.value)}
                  />
                  £
                </label>
              </div>
              <div className="form-check-inline">
                <label className="form-check-label">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="inventoryradio"
                    value={CurrencyType.euro}
                    checked={inventoryType === CurrencyType.euro}
                    onChange={(e) => setInventoryType(e.target.value)}
                  />
                  €
                </label>
              </div>
              <div
                className="range-slider-group d-flex flex-column"
                data-min={`${constant.UNSIGNED.INVENTORY_RAGE.MIN}M`}
                data-max={`${constant.UNSIGNED.INVENTORY_RAGE.MAX}M`}
              >
                <input
                  type="range"
                  min={constant.UNSIGNED.INVENTORY_RAGE.MIN}
                  max={constant.UNSIGNED.INVENTORY_RAGE.MAX}
                  step={constant.UNSIGNED.INVENTORY_RAGE.STEP}
                  data-color="#f00"
                  className="range-slider numbrings order-2 range-slider-red"
                  id="range-slider-red"
                  {...bindInventory}
                />
                <input
                  type="number"
                  min={constant.UNSIGNED.INVENTORY_RAGE.MIN}
                  max={constant.UNSIGNED.INVENTORY_RAGE.MAX}
                  step={constant.UNSIGNED.INVENTORY_RAGE.STEP}
                  {...bindInventory}
                  className="input-slider form-control mb-4 order-1 input-slider-red"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  return render();
};

export default UnsignedForm;
