import {
  faEdit,
  faPencil,
  faRefresh,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import CustomLoader from "../../../../common/CustomLoader";
import { useAISocialMediaContext } from "../../../../../context/ai-social-media.context";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import { Link } from "react-router-dom";
import AdItem from "./components/AdItem";

const SocialAdContentWidget = () => {
  const { adContent, createSocialAdContent } = useAISocialMediaContext();
  const { selectedPredictionModel } = useAIModelContext();
  const [adPrompt, setAdPrompt] = useState("");

  useEffect(() => {
    if (selectedPredictionModel)
      createSocialAdContent("", selectedPredictionModel.taskId);
  }, [selectedPredictionModel]);

  const triggerCustomPrompt = (e) => {
    e.preventDefault();
    createSocialAdContent(adPrompt, selectedPredictionModel.taskId);
  };

  const renderAdList = () => {
    if (adContent && adContent.length) {
      return adContent.map((item, index) => (
        <AdItem key={index} item={item} rowIndex={index} editable />
      ));
    }
    return (
      <div className="d-flex justify-content-center">
        <CustomLoader masked />
      </div>
    );
  };

  const render = () => {
    return (
      <>
        <h3 className="mb-3 d-flex justify-content-between">
          <p className="m-0">AI Generated Content</p>
          <Link className="ml-2" onClick={triggerCustomPrompt}>
            Try new content <FontAwesomeIcon icon={faRefresh} />
          </Link>
        </h3>
        <form onSubmit={triggerCustomPrompt} noValidate>
          <div className="form-group mb-0 d-flex align-items-center form-control my-3">
            <input
              type="text"
              className="border-0 w-100 px-2"
              value={adPrompt}
              placeholder="Customise prompt for ad generation"
              onChange={(e) => setAdPrompt(e.target.value)}
            />
            <button type="submit" className="ai-btn-clear">
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </form>
        <div className="bg-white box-shadow rounded  px-md-5 py-2">
          {renderAdList()}
        </div>
      </>
    );
  };
  return render();
};

export default SocialAdContentWidget;
