import React, { useEffect, useState } from "react";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import { useAppContext } from "../../../../../context/app.context";
import { getUUID } from "../../../../../helper/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { Link, useParams } from "react-router-dom";
import constant, { ProviderList } from "../../../../../helper/constant";
import CustomDropdown from "../../../../common/CustomDropdown";
import { getSocialMediaModel } from "../../../../../service/ai-model.service";

const KnowledgeAIForm = () => {
  const { hideRightMenu, aiDataSelectedForm, saveSocialModelForm } =
    useAIModelContext();
  const { isLoading, updateLoader } = useAppContext();
  const params = useParams();
  const [urlList, setUrlList] = useState([]);
  const [provider, setProvider] = useState("");
  const [read, setRead] = useState(false);

  useEffect(() => {
    if (aiDataSelectedForm.id) {
      updateLoader(true);
      getSocialMediaModel(aiDataSelectedForm.id)
        .then((r) => {
          if (r && r.length) {
            console.log(r);
            const { input = {}, LLM } = r[0];
            setProvider(LLM);
            setUrlList(input.url);
            setRead(true);
          }
        })
        .catch((ex) => console.log(ex))
        .finally(() => updateLoader(false));
    }
  }, [aiDataSelectedForm]);

  const addMore = (e) => {
    e.preventDefault();
    const list = [...urlList];
    list.push({ id: getUUID(), url: "" });
    setUrlList(list);
  };

  const handleURLChange = (url, rowIndex) => {
    const list = urlList.slice();
    list[rowIndex].url = url;
    setUrlList(list);
  };

  const onRemove = (e, rowIndex) => {
    e.preventDefault();
    const list = urlList.slice();
    list.splice(rowIndex, 1);
    setUrlList(list);
  };

  const renderUrlForm = (item, rowIndex) => {
    return (
      <div className="form-group gap-2" key={rowIndex}>
        <div className="text-right">
          <Link className="task-remove" onClick={(e) => onRemove(e, rowIndex)}>
            <FontAwesomeIcon icon={faTrashCan} />
          </Link>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder="Please enter site url"
          value={item.url}
          onChange={(e) => handleURLChange(e.target.value, rowIndex)}
          name="url"
        />
      </div>
    );
  };

  const renderURLList = () => {
    if (urlList.length)
      return urlList.map((item, rowIndex) => {
        if (read) {
          return (
            <a
              key={rowIndex}
              className="d-flex flex-column"
              href={item.url}
              target="_blank"
              rel="noreferrer"
            >
              {item.url}
            </a>
          );
        }
        return renderUrlForm(item, rowIndex);
      });
  };

  const onFormSubmit = () => {
    if (provider && urlList.length && urlList[0].url) {
      // onSubmit({ provider, outlet, placeId });
      const data = {
        id: aiDataSelectedForm.id,
        templateId: params.id,
        provider,
        modelId: aiDataSelectedForm.modelId,
        input: { url: urlList },
      };
      console.log(data);
      saveSocialModelForm(data);
    }
  };

  const renderActionButtons = () => {
    if (read)
      return (
        <>
          <div className="form-group my-2">
            <button
              type="button"
              className="btn1 btn radious-0  w-100 mt-2 f-13 text-uppercase py-2 font15fontweight600"
              onClick={(e) => {
                e.preventDefault();
                setRead(false);
              }}
            >
              Change
            </button>
          </div>
          <div className="form-group">
            <button
              type="button"
              className="btn1 btn radious-0  w-100 mt-2 f-13 bg-light-grey  text-uppercase py-2 font15fontweight600"
              onClick={() => hideRightMenu()}
            >
              Close
            </button>
          </div>
        </>
      );

    return (
      <>
        <div className="form-group my-2">
          <button
            type="button"
            className="btn1 btn radious-0  w-100 mt-2 f-13 text-uppercase py-2 font15fontweight600"
            onClick={() => onFormSubmit()}
          >
            save
          </button>
        </div>
        <div className="form-group">
          <button
            type="button"
            className="btn1 btn radious-0  w-100 mt-2 f-13 bg-light-grey  text-uppercase py-2 font15fontweight600"
            onClick={() => hideRightMenu()}
          >
            Cancel
          </button>
        </div>
      </>
    );
  };

  const renderProvider = () => {
    console.log(provider);
    return (
      <div className="form-group row">
        {read && (
          <div className="h6 f-13 mx-3">
            <b>Provider</b> : {provider}
          </div>
        )}
        {!read && (
          <>
            <p className="m-0 p-0 h6 f-13 custom-dropdown-label">
              <b className="pb-1 custom-dropdown-label">Provider</b>
            </p>
            <CustomDropdown
              name=""
              options={ProviderList}
              field="value"
              value={provider}
              onChange={(e) => setProvider(e)}
            />
          </>
        )}
      </div>
    );
  };

  const render = () => {
    if (isLoading) return <p>Loading ...</p>;
    return (
      <>
        {renderProvider()}
        {renderURLList()}
        {!read && (
          <div className="py-3 text-center">
            <button
              className="btn1 btn radious-0 w-100 mt-2 f-13 bg-light-grey text-uppercase py-2 font15fontweight600"
              type="button"
              onClick={addMore}
            >
              <FontAwesomeIcon icon={faPlusSquare} /> Add url
            </button>
          </div>
        )}
        {renderActionButtons()}
      </>
    );
  };
  return render();
};

export default KnowledgeAIForm;
