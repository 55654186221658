import React from "react";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const PredBarChart = (props) => {
  // const { data } = props;
  let data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
  ];
  const render = () => {
    if (data && data.length)
      return (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <Legend verticalAlign="top" align="right" />
            <XAxis dataKey="name" />
            <YAxis domain={[0, (dataMax) => dataMax]} />
            <Tooltip />
            <Bar dataKey="uv" fill="#8884d8" stackId="a">
              {/* <LabelList dataKey="name" position="inside" stroke="#FFF" /> */}
            </Bar>
            <Bar dataKey="pv" fill="#82ca9d" stackId="a">
              {/* <LabelList dataKey="name" position="inside" stroke="#FFF" /> */}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      );
    return <p>No data found</p>;
  };
  return render();
};

export default PredBarChart;
