import React from "react";
import LoginForm from "../../components/auth/LoginForm";
import { BasePageContainer } from "../../components/common";
import images from "../../helper/images";

const LoginPage = () => {
  return (
    <div className="row mx-0 loginwrap">
      <BasePageContainer>
        <LoginForm />
      </BasePageContainer>
    </div>
  );
};

export default LoginPage;
