import React, { useRef, useState } from "react";
import { useAIModelContext } from "../../context/ai-model.context";
import constant from "../../helper/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCamera,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { useScreenSizeContext } from "../../context/mobile-layout.context";

const ImageUploadForm = (props) => {
  const [file, setFile] = useState(null);
  const { onSubmit, multiple, minFileCount = 1, fileType = "image/*" } = props;
  const fileInputRef = useRef(null);
  const { isMobileScreen } = useScreenSizeContext();

  const handleFileChange = (event) => {
    if (multiple) {
      const files = event.target.files;
      const fileArray = Array.from(files).map((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        return new Promise((resolve) => {
          reader.onload = () => {
            resolve({
              file,
              dataURL: reader.result,
            });
          };
        });
      });

      Promise.all(fileArray).then((fileResults) => {
        setFile(fileResults);
      });
    } else setFile(event.target.files[0]);
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // Handle the file here (e.g., upload it to the server)
    if (file) {
      onSubmit(file);
      setFile(null);
      fileInputRef.current.value = null;
      // Perform any additional logic or API calls here
    } else {
      console.log("No file selected.");
    }
  };

  const renderFileMessage = () => {
    if (file && multiple && minFileCount) {
      return (
        <>
          <div className="text-primary my-2">{file.length} files selected</div>
          {file.length < minFileCount && (
            <div className="text-danger my-2">
              Upload minimum {minFileCount} files
            </div>
          )}
        </>
      );
    }
    if (file && !multiple)
      return <div className="text-primary my-2">{file?.name}</div>;
  };
  const renderUploadForm = () => {
    const isSubmitDisble = file && file.length < minFileCount;
    return (
      <form noValidate onSubmit={onFormSubmit}>
        {!isMobileScreen && (
          <div
            className={`d-flex gap-2 justify-content-start align-item-center`}
          >
            <div className={`file-upload-wrapper2`} data-text="Select file">
              <input
                name="file-upload"
                ref={fileInputRef}
                accept={fileType}
                capture="environment"
                type="file"
                className="file-upload-field2"
                onChange={handleFileChange}
                multiple={multiple}
              />
            </div>
            <button
              className="btn1 btn radious-0 f-13 text-uppercase py-2 font15fontweight600 d-flex align-items-center gap-1"
              type="submit"
              disabled={isSubmitDisble}
            >
              next <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        )}
        {isMobileScreen && (
          <label className="custom-file-upload w-100 text-center">
            <input
              type="file"
              ref={fileInputRef}
              accept={fileType}
              capture="environment"
              className="file-upload-field2"
              onChange={handleFileChange}
              multiple={multiple}
            />
            <FontAwesomeIcon icon={faCamera} /> <span>Camera</span>
          </label>
        )}
        {renderFileMessage()}
      </form>
    );
  };

  const render = () => {
    return <>{renderUploadForm()}</>;
  };

  return render();
};

export default ImageUploadForm;
