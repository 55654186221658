import React, { useEffect, useState } from "react";
import images from "../../../../../helper/images";
import { Link, useParams } from "react-router-dom";
import constant from "../../../../../helper/constant";
import SocialReviewList from "../widgets/SocialReviewList";
import SocialKeywords from "../widgets/SocialKewords";
import SocialAdContentWidget from "../widgets/SocialAdContentWidget";
import SocialAdImageContentWidget from "../widgets/SocialAdImageContentWidget";
import AdWidget from "../widgets/components/AdWidget";
import SocialFinalAdtWidget from "../widgets/SocialFinalAdWidget";

export const SocialPositiveReviewStep1 = () => {
  const render = () => {
    return (
      <div className="container">
        <h3>
          <Link to=".." className="pr-2">
            <img
              className="pr-2"
              src={images.socialMediaResult.arrowLeft}
              alt="Negative Reviews"
            />
            Back
          </Link>
        </h3>
        <section className="py-3 py-md-3">
          <SocialReviewList />
        </section>
        <section className="py-3">
          <div className="stepbox stepboxpositive mb-3 p-2">
            Step <span className="step-number">1</span>
          </div>
          <SocialKeywords />
        </section>
        <section className="py-3 py-md-3">
          <div className="stepbox stepboxpositive mb-3 p-2">
            Step <span className="step-number">2</span>
          </div>
          <SocialAdContentWidget />
          <div className="mt-5 text-center">
            <Link
              to={`../${constant.PAGES.SOCIAL_MEDIA.POSITIVE_REVIEW_2}`}
              className="btn1"
            >
              NEXT &gt;&gt;
            </Link>
          </div>
        </section>
      </div>
    );
  };

  return render();
};

export const SocialPositiveReviewStep2 = () => {
  const render = () => {
    return (
      <div className="container">
        <h3>
          <Link to=".." className="pr-2">
            <img
              className="pr-2"
              src={images.socialMediaResult.arrowLeft}
              alt="Negative Reviews"
            />
            Back
          </Link>
        </h3>
        <section className="py-5">
          <div className="stepbox stepboxpositive mb-3 py-2">
            Step <span className="step-number">3</span>
          </div>
          <SocialAdImageContentWidget />
          <div className="mt-5 text-center">
            <Link
              to={`../${constant.PAGES.SOCIAL_MEDIA.POSITIVE_REVIEW_3}`}
              className="btn1"
            >
              NEXT &gt;&gt;
            </Link>
          </div>
        </section>
      </div>
    );
  };

  return render();
};

export const SocialPositiveReviewStep3 = () => {
  const render = () => {
    return (
      <>
        <section className="py-5">
          <div className="container">
            <div className="stepbox stepboxpositive mb-3">
              Step <span>4</span>
            </div>
            <Link to=".." className="pr-2 my-3">
              <img
                className="pr-2"
                src={images.socialMediaResult.arrowLeft}
                alt="Back"
              />
              Back
            </Link>
            <div className="">
              <SocialFinalAdtWidget />
            </div>
          </div>
          <div className="mt-5 text-center">
            <Link to={`..`} className="btn1">
              Done
            </Link>
          </div>
        </section>
      </>
    );
  };

  return render();
};
