import constant from "../../../../../helper/constant";
import SocialHome from "./SocialHome";
import SocialNegativeReview from "./SocialNegativeReview";
import {
  SocialPositiveReviewStep1,
  SocialPositiveReviewStep2,
  SocialPositiveReviewStep3,
} from "./SocialPositiveReview";

export const socialMediaRoutes = [
  {
    path: `/`,
    element: <SocialHome />,
  },
  {
    path: `/${constant.PAGES.SOCIAL_MEDIA.SOCIAL_HOME}`,
    element: <SocialHome />,
  },
  {
    path: `/${constant.PAGES.SOCIAL_MEDIA.NEGATIVE_REVIEW}`,
    element: <SocialNegativeReview />,
  },
  {
    path: `/${constant.PAGES.SOCIAL_MEDIA.POSITIVE_REVIEW_1}`,
    element: <SocialPositiveReviewStep1 />,
  },
  {
    path: `/${constant.PAGES.SOCIAL_MEDIA.POSITIVE_REVIEW_2}`,
    element: <SocialPositiveReviewStep2 />,
  },
  {
    path: `/${constant.PAGES.SOCIAL_MEDIA.POSITIVE_REVIEW_3}`,
    element: <SocialPositiveReviewStep3 />,
  },
];
